<template>
  <delete-modal
    :open="modalIsActive"
    :modalTitle="cancelModalTitle"
    :modalDescription="cancelModalDescription"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="cancelOrder"
    deleteButtonText="Cancel Order"
    closeButtonText="Back"
  />
  <slot-modal
    :open="emailModalIsActive"
    @close-modal="emailModalIsActive = false"
  >
    <template #content>
      <div class="">
        <span class="font bold text-lg">Customer:</span>
        {{ currentAgreement?.buyer_details?.name }}
      </div>
      <div class="text-base">
        <p class="text-lg font-bold">Ship to:</p>
        <p></p>
        {{ currentOrder.shipping_name }}
        <p>{{ currentOrder.shipping_company }}</p>
        <p></p>
        {{ currentOrder.shipping_address_1 }}
        <p></p>
        {{ currentOrder.shipping_address_2 }}
        <p></p>
        {{ currentOrder.shipping_city }},
        {{ currentOrder.shipping_state }}
        {{ currentOrder.shipping_zipcode }}
      </div>
      <div class="sm:col-span-3" v-if="currentOrder.shipping_memo">
        Shipping Memo {{ currentOrder.shipping_memo }}
      </div>
      <table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in lineItems" :key="item.id">
            <template v-if="item.input_unit">
              <tr>
                <td class="pr-4">
                  {{ getInputUnitFullName(item.input_unit) }}
                </td>
                <td>{{ item.quantity }}</td>
              </tr>
            </template>
            <template v-if="item.input_request && item.is_available !== false">
              <tr>
                <td class="pr-4">
                  {{ getInputRequest(item.input_request)?.product_name }} -
                  {{ item.package_request }}
                </td>
                <td>{{ item.quantity }}</td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      <BaseButton
        buttonText="Copy"
        :marginLeft="true"
        @buttonClick="copyToClipboard"
      />
      <BaseButton
        buttonText="Close"
        :marginLeft="true"
        @buttonClick="emailModalIsActive = false"
      />
    </template>
  </slot-modal>
  <slot-modal
    :open="addModalIsActive"
    modalTitle="Add Item to Order"
    modalDescription="Use if you need to add an item to the order, or substitute one line item for anther, by adding the new item here and setting the original request quantity to zero."
    @close-modal="addModalIsActive = false"
  >
    <template #content>
      <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
        <div class="sm:col-span-3">
          <select-search
            inputTitle="Listing"
            :wideItem="true"
            placeholder="Pick a Listing"
            :selectOptions="
              inputListings.map((l) => ({
                label: l.title,
                value: l.id,
              }))
            "
            v-model="newLineItem.id"
          />
        </div>
        <div class="sm:col-span-3">
          <select-search
            inputTitle="Package Size"
            :wideItem="true"
            placeholder="Pick a sku"
            :selectOptions="
              inputUnits
                .filter((u) => u.input_listing === newLineItem.id)
                .map((u) => ({
                  label: u.title,
                  value: u.id,
                }))
            "
            v-model="newLineItem.input_unit"
          />
        </div>
        <div class="sm:col-span-1">
          <label class="block text-sm font-medium text-gray-700">
            Quantity
          </label>
          <NumberInput
            :inputTitle="null"
            v-model="newLineItem.quantity"
            :wideItem="true"
            :step="1"
          />
        </div>
        <div class="sm:col-span-1">
          <label class="block text-sm font-medium text-gray-700">
            Unit Price
          </label>
          <CurrencyInput
            :inputTitle="null"
            v-model="newLineItem.unit_price"
            :wideItem="true"
          />
        </div>
        <!-- <div class="sm:col-span-1">
          <label class="block text-sm font-medium text-gray-700">
            Total Price
          </label>
          <CurrencyInput
            :inputTitle="null"
            :displayOnly="true"
            :model-value="quantity * unitPrice"
            :wideItem="true"
            :key="unitPrice * quantity"
          />
        </div> -->
        <div class="flex sm:col-span-3">
          <BaseButton
            buttonText="Add Item"
            :marginLeft="true"
            @buttonClick="addItem"
          />
          <BaseButton
            buttonText="Close"
            :marginLeft="true"
            @buttonClick="addModalIsActive = false"
          />
        </div>
      </div>
    </template>
  </slot-modal>
  <page-card headingTitle="Review Order">
    <template #buttonArea>
      <base-button
        buttonText="Shipping Label"
        :marginLeft="true"
        @buttonClick="emailModalIsActive = true"
        v-if="currentOrder.input_agreement"
      />
      <base-button
        buttonText="Update Quote"
        :marginLeft="true"
        @buttonClick="saveOrder"
        v-if="currentOrder.input_agreement"
      />
      <template v-if="currentOrder.status === 'buyer_submitted'">
        <base-button
          buttonText="Submit Quote"
          :marginLeft="true"
          @buttonClick="respondOrder"
        />
        <base-button
          buttonText="Reject Quote"
          :redButton="true"
          @buttonClick="cancelOrder"
        />
      </template>
      <template v-else>
        <base-button
          v-if="
            ![
              'buyer_closed',
              'seller_closed',
              'seller_canceled',
              'seller_completed',
            ].includes(currentOrder.status)
          "
          buttonText="Complete Order"
          :marginLeft="true"
          @buttonClick="completeOrder"
        />
        <base-button
          v-if="
            !['seller_canceled', 'seller_completed'].includes(
              currentOrder.status,
            )
          "
          buttonText="Cancel Order"
          :redButton="true"
          @buttonClick="intializeDeleteModal"
        />
      </template>
    </template>
    <template #cardDetails>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Customer
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentAgreement?.buyer_details?.name"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3"></div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Recipient Name
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.shipping_name"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Recipient Company
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.shipping_company"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Shipping Address
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.shipping_address_1"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Shipping Address Line 2
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.shipping_address_2"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700"> City </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.shipping_city"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700"> State </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.shipping_state"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700"> Zipcode </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.shipping_zipcode"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Shipping Memo
        </label>
        <TextArea
          :displayOnly="true"
          :displayOnlyValue="currentOrder.shipping_memo"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Requested Delivery Date
        </label>
        <date-input
          :displayOnly="true"
          :displayOnlyValue="currentOrder.requested_delivery_date"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Expected Delivery Date
        </label>
        <date-input
          v-model="currentOrder.expected_delivery_date"
          :wideItem="true"
        />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700">
          Purchase Order #
        </label>
        <variable-string-type-input
          v-model="currentOrder.purchase_order_number"
          :wideItem="true"
        />
      </div>
      <div v-if="orderClosed" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Closed', status: 'complete' }]"
        />
      </div>
      <div v-else-if="orderCanceled" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Canceled', status: 'complete' }]"
        />
      </div>
      <div v-else-if="orderCompleted" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Completed', status: 'complete' }]"
        />
      </div>
      <div v-else class="sm:col-span-6">
        <horizontal-panel-steps :steps="orderSteps" />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Order Details
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel class="text-sm text-gray-500">
            <template v-for="item in lineItems" :key="item.id">
              <template v-if="item.input_unit">
                <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">
                      Item
                    </label>
                    <variable-string-type-input
                      :displayOnly="true"
                      :displayOnlyValue="getInputUnitFullName(item.input_unit)"
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Quantity
                    </label>
                    <NumberInput
                      v-model="item.quantity"
                      :wideItem="true"
                      :step="1"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Unit Price
                    </label>
                    <CurrencyInput v-model="item.unit_price" :wideItem="true" />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Total Price
                    </label>
                    <CurrencyInput
                      :displayOnly="true"
                      :displayOnlyValue="item.quantity * item.unit_price"
                      :wideItem="true"
                      :key="item.unit_price * item.quantity"
                    />
                  </div>
                </div>
              </template>
            </template>
            <template v-for="item in lineItems" :key="item.id">
              <template v-if="item.input_request">
                <div
                  class="mt-6 grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-6"
                >
                  <div class="sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">
                      Item Request
                    </label>
                    <variable-string-type-input
                      :displayOnly="true"
                      :displayOnlyValue="
                        getInputRequest(item.input_request)?.product_name
                      "
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Quantity
                    </label>
                    <NumberInput
                      :display-only="item.is_available === false"
                      :display-only-value="0"
                      v-model="item.quantity"
                      :wideItem="true"
                      :step="1"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Unit Price
                    </label>
                    <CurrencyInput
                      :display-only="item.is_available === false"
                      :display-only-value="'$0.00'"
                      v-model="item.unit_price"
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Total Price
                    </label>
                    <CurrencyInput
                      :displayOnly="true"
                      :displayOnlyValue="item.quantity * item.unit_price"
                      :wideItem="true"
                      :key="item.unit_price * item.quantity"
                    />
                  </div>
                  <div class="sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">
                      Requested Package Size
                    </label>
                    <variable-string-type-input
                      :displayOnly="true"
                      :displayOnlyValue="item.package_request"
                      :wideItem="true"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <label class="block text-sm font-medium text-gray-700">
                      Available?
                    </label>
                    <radio-input
                      :wideItem="true"
                      v-model="item.is_available"
                      :selectOptions="[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ]"
                    />
                  </div>
                </div>
              </template>
            </template>

            <div class="mt-4">
              <base-button
                buttonText="Add Item"
                :marginLeft="true"
                @buttonClick="addModalIsActive = true"
              />
            </div>
            <div
              class="mt-8 grid grid-cols-1 gap-x-4 gap-y-8 border-t border-gray-200 sm:grid-cols-6"
            >
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Memo
                </label>
                <TextArea v-model="currentOrder.memo" :wideItem="true" />
              </div>
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Shipping
                </label>
                <CurrencyInput
                  v-model="currentOrder.shipping_cost"
                  :wideItem="true"
                />
              </div>
              <div class="sm:col-span-3"></div>
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Discount
                </label>
                <CurrencyInput
                  v-model="currentOrder.discount"
                  :wideItem="true"
                />
              </div>
              <div class="sm:col-span-3"></div>
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Total
                </label>
                <CurrencyInput
                  :displayOnly="true"
                  :displayOnlyValue="totalCost"
                  :wideItem="true"
                  :key="totalCost"
                />
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import HorizontalPanelSteps from "@/components/progress/HorizontalPanelSteps.vue";
import TextArea from "@/components/form/TextArea.vue";
import RadioInput from "@/components/form/RadioInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import { useToast } from "vue-toastification";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DeleteModal,
    SlotModal,
    SelectSearch,
    HorizontalPanelSteps,
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    CurrencyInput,
    NumberInput,
    TextArea,
    RadioInput,
    DateInput,
  },
  data() {
    const toast = useToast();
    return {
      modalIsActive: false,
      addModalIsActive: false,
      emailModalIsActive: false,
      pendingVendorOrderAttachments: [],
      toast,
      currentOrder: { line_items: [], shipping_cost: 0, discount: 0 },
      newLineItem: {
        newItem: true,
        id: null,
        input_unit: null,
        quantity: 0,
        unit_price: 0,
      },
      lineItems: [],
    };
  },
  computed: {
    inputAgreements() {
      return this.$store.state.vendorInputAgreements.agreements;
    },
    inputListings() {
      return this.$store.state.vendorInputListings.listings;
    },
    inputUnits() {
      return this.$store.state.vendorInputListings.units;
    },
    inputRequests() {
      return this.$store.state.growerInputListings.requests;
    },
    orders() {
      return this.$store.state.vendorInputOrders.orders;
    },
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    currentAgreement() {
      const result = this.inputAgreements.find(
        (a) => a.id === this.currentOrder?.input_agreement,
      );
      return result;
    },
    orderClosed() {
      if (
        this.currentOrder.status === "seller_closed" ||
        this.currentOrder.status === "buyer_closed"
      ) {
        return true;
      } else {
        return false;
      }
    },
    orderCanceled() {
      if (this.currentOrder.status === "seller_canceled") {
        return true;
      } else {
        return false;
      }
    },
    orderCompleted() {
      if (this.currentOrder.status === "seller_completed") {
        return true;
      } else {
        return false;
      }
    },
    orderSteps() {
      let steps = [
        { name: "Quote Submitted", status: "complete" },
        {
          name: "Order Completed",
          status:
            this.currentOrder.status === "buyer_confirmed"
              ? "complete"
              : "upcoming",
        },
      ];
      return steps;
    },
    cancelModalTitle() {
      if (this.currentOrder.status === "buyer_confirmed") {
        return "Cancel Order";
      } else {
        return "Close Order";
      }
    },
    cancelModalDescription() {
      if (this.currentOrder.status === "buyer_confirmed") {
        return "Are you sure you want to cancel this confirmed order?";
      } else {
        return "Are you sure you want to close this order inquiry?";
      }
    },
    totalCost() {
      return (
        this.lineItems.reduce(
          (total, item) => total + item.quantity * item.unit_price,
          0,
        ) +
        this.currentOrder.shipping_cost -
        this.currentOrder.discount
      );
    },
  },
  methods: {
    getInputAgreement(id) {
      return this.inputAgreements.find((a) => a.id == id);
    },
    getInputListing(id) {
      return this.inputListings.find((a) => a.id == id);
    },
    getInputUnit(id) {
      return this.inputUnits.find((a) => a.id == id);
    },
    getInputUnitFullName(id) {
      const unit = this.getInputUnit(id);
      if (!unit) return "";
      const listing = this.getInputListing(unit.input_listing);
      if (!listing) return "";
      return listing.title + " - " + unit.title;
    },
    getInputRequest(id) {
      return this.inputRequests.find((a) => a.id == id);
    },
    async saveOrder() {
      await this.$store.dispatch("updateVendorInputOrder", {
        id: this.currentOrder.id,
        ...this.currentOrder,
        total_cost: this.totalCost,
      });
      for (let item of this.lineItems) {
        if (item.newItem) {
          let newLine = await this.$store.dispatch(
            "addVendorInputOrderLineItem",
            {
              order: this.currentOrder.id,
              lineItem: {
                quantity: item.quantity,
                input_unit: item.input_unit,
              },
            },
          );
          if (newLine?.id) {
            await this.$store.dispatch("updateVendorInputOrderLineItem", {
              order: this.currentOrder.id,
              lineItem: {
                id: newLine.id,
                quantity: item.quantity,
                unit_price: item.unit_price,
                is_available: null,
              },
            });
          }
        } else {
          await this.$store.dispatch("updateVendorInputOrderLineItem", {
            order: this.currentOrder.id,
            lineItem: item,
          });
        }
      }
      if (!this.$store.getters.getApiError) {
        this.toast.success("Quote Updated", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
    },
    async respondOrder() {
      await this.$store.dispatch("updateVendorInputOrder", {
        ...this.currentOrder,
        total_cost: this.totalCost,
        status: "seller_responded",
      });
      for (let item of this.lineItems) {
        if (item.newItem) {
          let newLine = await this.$store.dispatch(
            "addVendorInputOrderLineItem",
            {
              order: this.currentOrder.id,
              lineItem: {
                quantity: item.quantity,
                input_unit: item.input_unit,
              },
            },
          );
          if (newLine?.id) {
            await this.$store.dispatch("updateVendorInputOrderLineItem", {
              order: this.currentOrder.id,
              lineItem: {
                id: newLine.id,
                quantity: item.quantity,
                unit_price: item.unit_price,
                is_available: null,
              },
            });
          }
        } else {
          await this.$store.dispatch("updateVendorInputOrderLineItem", {
            order: this.currentOrder.id,
            lineItem: item,
          });
        }
      }
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Response Sent", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "vendor-quotes-all" });
    },
    async completeOrder() {
      await this.$store.dispatch("updateVendorInputOrder", {
        id: this.currentOrder.id,
        ...this.currentOrder,
        total_cost: this.totalCost,
        status: "seller_completed",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Response Sent", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "vendor-orders-all" });
    },
    async cancelOrder() {
      this.currentOrder.total_price = 0;
      await this.$store.dispatch("updateVendorInputOrder", {
        id: this.currentOrder.id,
        ...this.currentOrder,
        total_cost: this.totalCost,
        status: "seller_canceled",
      });
      this.pendingVendorOrderAttachments = [];
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Concluded", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "vendor-orders-all" });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    addItem() {
      this.lineItems.push(this.newLineItem);
      this.newLineItem = {
        id: null,
        input_unit: null,
        quantity: 0,
        unitPrice: 0,
      };
      this.addModalIsActive = false;
    },
    copyToClipboard() {
      const currentOrder = this.currentOrder;
      let copyText = `${currentOrder.shipping_name}`;
      if (currentOrder.shipping_company)
        copyText += `
${currentOrder.shipping_company}`;
      copyText += `
${currentOrder.shipping_address_1}`;
      if (currentOrder.shipping_address_2)
        copyText += `
${currentOrder.shipping_address_2}`;
      copyText += `
${currentOrder.shipping_city}, ${currentOrder.shipping_state} ${currentOrder.shipping_zipcode}`;

      navigator.clipboard.writeText(copyText).then(() => {
        this.toast.success("Copied to Clipboard", {
          timeout: 1500,
          hideProgressBar: true,
        });
      });
    },
  },
  watch: {
    orderId: {
      async handler(orderId) {
        await this.$store.dispatch("getVendorInputOrders");
        this.currentOrder = this.orders.find((o) => o.id === orderId) || {};
        this.lineItems = this.currentOrder?.line_items || [];
        console.log("setting line items", this.lineItems);
        for (let lineItem of this.lineItems) {
          // if no price, set price to SKU default price
          if (!lineItem.unit_price) {
            lineItem.unit_price = this.getInputUnit(lineItem.input_unit)?.price;
          }
          // if user specific price is set, use that price
          if (lineItem.my_price) {
            lineItem.unit_price = lineItem.my_price;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
