<template>
  <page-card>
    <template #cardDetails>
      <text-input
        v-if="!hideContractSummary && currentOffer"
        :wideItem="true"
        inputTitle="Contract Name"
        inputHtmlType="text"
        :display-only="true"
        :display-only-value="currentOffer.contract_name"
      />
      <text-input
        v-if="!hideContractSummary && currentOffer"
        :wideItem="true"
        inputTitle="Crop"
        inputHtmlType="text"
        :display-only="true"
        :display-only-value="currentOffer.crop"
      />
      <number-input
        v-if="!hideContractSummary && currentOffer"
        :wideItem="true"
        inputTitle="Quantity"
        inputHtmlType="text"
        :display-only="true"
        :display-only-value="currentOffer.quantity"
      />
      <text-input
        v-if="!hideContractSummary && currentOffer"
        :wideItem="true"
        inputTitle="Quantity Units (Bushels, Bags, Tons, etc)"
        inputHtmlType="text"
        :display-only="true"
        :display-only-value="currentOffer.quantity_units"
      />
      <number-input
        v-if="currentOffer"
        :wideItem="true"
        inputTitle="Total Ordered Quantity"
        inputHtmlType="text"
        :display-only="true"
        :display-only-value="orderedQty"
      />
      <number-input
        v-if="currentOffer && orderedQty"
        :wideItem="true"
        inputTitle="Quantity Remaining to Fill"
        inputHtmlType="text"
        :display-only="true"
        :display-only-value="currentOffer.quantity - orderedQty"
      />
      <number-input
        v-if="currentOffer"
        :wideItem="true"
        inputTitle="Total Allocated Quantity (Est.)"
        inputHtmlType="text"
        :display-only="true"
        :display-only-value="
          estimatedAllocatedQty
            ? estimatedAllocatedQty?.toFixed(2)
            : 'No Yield Estimates Available'
        "
      />
      <number-input
        v-if="currentOffer"
        :wideItem="true"
        inputTitle="Remaining Allocated Quantity to Fill (Est.)"
        inputHtmlType="text"
        :display-only="true"
        :display-only-value="
          currentOffer.quantity - estimatedAllocatedQty > 0
            ? (currentOffer.quantity - estimatedAllocatedQty)?.toFixed(2)
            : 'Over Allocated: ' +
              (estimatedAllocatedQty - currentOffer.quantity)?.toFixed(2)
        "
      />
      <number-input
        v-if="currentOffer"
        :wideItem="true"
        inputTitle="Allocated Acres"
        inputHtmlType="text"
        :display-only="true"
        :display-only-value="
          currentOfferCropOrdersSummary.currentAllocation
            ? currentOfferCropOrdersSummary.currentAllocation
            : 0
        "
      />
    </template>
  </page-card>
</template>
<script>
import PageCard from "@/components/cards/PageCard.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import { tonsToBushels } from "@/components/composables/convertUnits";

export default {
  props: {
    offerId: {
      type: [Number, String],
      required: true,
    },
    hideContractSummary: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PageCard,
    TextInput,
    NumberInput,
  },
  computed: {
    // currentOrder() {
    //   return this.$store.getters.getBuyerOrderById(this.orderId);
    // },
    currentOffer() {
      // console.log("Current Order: ", this.currentOrder);
      let offer = this.$store.getters.getBuyerMarketOfferById(this.offerId);
      // console.log("Offer: ", offer);
      return offer;
    },

    currentOfferCropOrdersSummary() {
      let cropOrders = this.$store.getters.getBuyerCropOrdersByMarketOffer(
        this.offerId,
      );
      let summary = {
        quantity: this.currentOffer.quantity,
        units: this.currentOffer.quantity_units,
        // currentAllocation is the sum of each order's allocated_acres attributes
        currentAllocation: cropOrders.reduce(
          (acc, order) => acc + order.allocated_acres,
          0,
        ),
      };
      return summary;
    },
    orderedQty() {
      let orders = this.$store.getters
        .getBuyerCropOrdersByMarketOffer(this.offerId)
        ?.filter((order) => order.status == "In Progress");
      let totalQty = 0;
      for (let order of orders) {
        totalQty += order.quantity;
      }
      return totalQty;
    },
    estimatedAllocatedQty() {
      let totalQty = 0;
      //console.log("tpa");
      let tpaEstimate = 0;
      let tpaCount = 0;
      let orders = this.$store.getters.getBuyerCropOrdersByMarketOffer(
        this.offerId,
      );
      // lets get an average of the tpa if we need it later
      for (let order of orders) {
        for (let allocation of this.$store.getters.getBuyerAllocationsByOrder(
          order.id,
        )) {
          let crop = allocation?.shared_data?.crop_details;
          if (crop?.details?.tpa) {
            tpaEstimate = parseFloat(crop?.details?.tpa) + tpaEstimate;
            tpaCount++;
            // tpaEstimates.push(parseFloat(crop?.details?.tpa));
          }
        }
      }
      tpaEstimate = tpaEstimate / tpaCount;

      let cropType = "";
      let units = this.currentOffer.quantity_units;

      // Now lets get the total quantity using either reported tpa or the estimate

      //   console.log("Orders: ", orders);
      for (let order of orders) {
        let tons = 0;
        for (let allocation of this.$store.getters.getBuyerAllocationsByOrder(
          order.id,
        )) {
          //   console.log("Allocation: ", allocation);
          let crop = allocation?.shared_data?.crop_details;
          if (crop?.details?.tpa) {
            tons += allocation.acres * parseFloat(crop?.details?.tpa);
          } else {
            tons += allocation.acres * tpaEstimate;
          }
          if (!cropType.includes(crop?.crop_type)) {
            cropType += crop?.crop_type;
          }
        }

        let qty = tons; // default is estimate in tons
        if (units.toLowerCase() == "bushels" || units.toLowerCase() == "bu") {
          qty = tonsToBushels(cropType, tons);
        }
        totalQty += qty;
      }
      return totalQty;
    },
    currentAllocations() {
      return this.$store.getters.getBuyerAllocationsByOrder(
        this.currentOrder?.id,
      );
    },
  },
  methods: {},
};
</script>
