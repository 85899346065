<template>
  <styled-input
    type="number"
    :inputTitle="inputTitle"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :placeholder="placeholder"
    :classProp="classProp"
    :step="step"
    :helpText="helpText"
    :helpTitle="helpTitle"
    @blur="checkValidInput"
    v-model="inputValue"
  />
</template>

<script>
import StyledInput from "./StyledInput.vue";
Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
};
export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: [Number, String] },
    required: { type: Boolean, default: false },
    modelValue: { type: [Number, String] },
    placeholder: { type: String, default: null },
    classProp: { type: String },
    wideItem: { type: Boolean, default: false },
    step: { type: Number, default: 0.01 },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
    nullIfEmpty: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "blur", "OnBlur"],
  data() {
    let inputValue = null;
    // This line is just to be backward compatible when displayOnlyValue was how the value was passed in for read only.
    if (this.displayOnly) inputValue = this.displayOnlyValue;
    // now make sure the value is a number and either make it null or zero if it is not.
    if (typeof this.modelValue == "string" && !isNaN(this.modelValue)) {
      inputValue = parseFloat(this.modelValue);
    }
    if (
      typeof this.modelValue == "number" ||
      typeof this.modelValue == "bigint"
    )
      inputValue = this.modelValue;
    if (isNaN(inputValue)) inputValue = 0;
    if (!this.nullIfEmpty && inputValue == null) inputValue = 0;
    if (this.nullIfEmpty && inputValue == 0) inputValue = null;
    return {
      inputValue,
    };
  },
  methods: {
    checkValidInput(value) {
      // console.log("checkValidInput", value);
      if (isNaN(value)) {
        this.nullIfEmpty ? (value = null) : (value = 0);
        // console.log("NaN input", value);
      }
      if (value != null && value != undefined) {
        let stepDecimals = this.step.countDecimals();
        let valueDecimals = value.countDecimals();
        // console.log("check number input", value, stepDecimals, valueDecimals);
        if (stepDecimals < valueDecimals) {
          this.inputValue = value.toFixed(stepDecimals);
        }
      } else {
        this.nullIfEmpty ? (this.inputValue = null) : (this.inputValue = 0);
      }
      if (this.inputValue == null && this.Number) {
        this.$emit("update:modelValue", parseFloat(this.inputValue));
      } else if (typeof this.inputValue == "string") {
        this.inputValue = parseFloat(this.inputValue);
      } else {
        this.$emit("update:modelValue", this.inputValue);
      }
      // console.log("emitting input value", this.inputValue);
      this.$emit("blur");
      this.$emit("OnBlur"); // duplicate event for backward compatibility
    },
  },
  watch: {
    inputValue(value) {
      if (isNaN(value)) {
        this.nullIfEmpty ? (value = null) : (value = 0);
        this.inputValue = value;
      }
      if (value == null) {
        this.$emit("update:modelValue", null);
      } else {
        if (typeof value == "string") {
          this.$emit("update:modelValue", parseFloat(value));
        } else {
          this.$emit("update:modelValue", value);
        }
      }
    },
    modelValue(value) {
      this.inputValue = value;
    },
    displayOnly() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
  },
};
</script>
