<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Close Order"
    modalDescription="Are you sure you want to close this order inquiry?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="closeOrder"
    deleteButtonText="Cancel"
    closeButtonText="Back"
  />
  <page-card headingTitle="Review Order">
    <template #buttonArea>
      <base-button
        buttonText="Cancel Order"
        :redButton="true"
        :marginLeft="false"
        @buttonClick="intializeDeleteModal"
        v-if="
          order &&
          (order.status === 'buyer_submitted' ||
            order.status === 'seller_responded')
        "
      />
      <base-button
        buttonText="Reject"
        @buttonClick="rejectOrder"
        :marginLeft="true"
        v-if="order && order.status === 'seller_responded'"
      />
      <base-button
        buttonText="Accept"
        @buttonClick="acceptOrder"
        :marginLeft="true"
        v-if="order && order.status === 'seller_responded'"
      />
    </template>
    <template #cardDetails>
      <!-- <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="order.seller_details.name"
        :wideItem="true"
        inputTitle="Agreement"
      /> -->
      <div class="sm:col-span-3">
        <label for="territory" class="block text-sm font-medium text-gray-700">
          Vendor
          <router-link
            v-if="order"
            :to="{
              name: 'grower-portal-single-quote',
              params: { quoteId: order.agreement },
            }"
            class="text-twilight-600 hover:text-twilight-900"
            >(View Quote)</router-link
          >
        </label>
        <variable-string-type-input
          v-if="currentAgreement"
          :displayOnly="true"
          :displayOnlyValue="currentAgreement?.seller_details?.name"
          :wideItem="true"
        />
      </div>
      <variable-string-type-input
        v-if="order"
        :displayOnly="true"
        :displayOnlyValue="new Date(order.updated).toLocaleDateString()"
        :wideItem="true"
        inputTitle="Updated"
      />
      <!-- <currency-input
        :displayOnly="true"
        :displayOnlyValue="order.total_price"
        :wideItem="true"
        inputTitle="Total Price"
        v-model="order.total_price"
      /> -->
      <!-- <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="order.tracking_id"
        :wideItem="true"
        inputTitle="Tracking ID"
      /> -->
      <!-- <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="order.tracking_memo"
        :wideItem="true"
        inputTitle="Memo"
      /> -->

      <div v-if="orderClosed" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Closed', status: 'complete' }]"
        />
      </div>
      <div v-else-if="orderCanceled" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Canceled', status: 'complete' }]"
        />
      </div>
      <div v-else-if="orderCompleted" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Completed', status: 'complete' }]"
        />
      </div>
      <div v-else class="sm:col-span-6">
        <horizontal-panel-steps :steps="orderSteps" />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Order Details
              </button>
            </Tab>

            <!-- <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab> -->
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="order?.shared_assets?.fields?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Fields
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="order?.shared_assets?.equipment?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Equipment
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel class="text-sm text-gray-500">
            <!-- <number-input
              :display-only="true"
              :displayOnlyValue="order.quantity"
              :step="1"
              :wideItem="true"
              inputTitle="Quantity"
            />
            <text-area
              :displayOnly="true"
              :displayOnlyValue="order.delivery_address"
              :wideItem="true"
              inputTitle="Delivery Address"
              inputHtmlType="text"
            /> -->
            <!-- <div class="mt-2 sm:col-span-6">
              <component
                :is="productOrderCategory"
                :productId="order.agreement"
                :orderDetails="order?.details"
                :readOnly="true"
              />
            </div>
            <h2 class="mt-2" v-if="Object.keys(order.form_response).length > 0">
              Form Questions
            </h2>
            <variable-string-type-input
              v-for="(value, key) in order.form_response"
              :key="key"
              :wideItem="true"
              :inputTitle="key"
              :placeholder="value"
              :display-only="true"
            />
            <h2 class="mt-2" v-if="Object.keys(order.variants).length > 0">
              Variants
            </h2>
            <variable-string-type-input
              v-for="(value, key) in order.variants"
              :key="key"
              :inputTitle="key"
              :wideItem="true"
              :placeholder="value"
              :display-only="true"
            />
            <h2 class="mt-2" v-if="Object.keys(order.options).length > 0">
              Options
            </h2>
            <checkbox-input
              v-for="(value, key) in order.options"
              :key="key"
              :inputTitle="key"
              :modelValue="value"
              :wideItem="true"
              :display-only="true"
            /> -->
            <order-object-interface
              v-if="currentAgreement"
              :product="currentProduct"
              :active-agreement-id="currentAgreement.id"
              :order="order"
              :read-only="true"
            />
            <shared-fields
              v-if="order?.shared_assets?.fields.length === 1"
              :fields="order?.shared_assets?.fields"
              :locations="order?.shared_assets?.locations"
            />
            <shared-equipment
              v-if="order?.shared_assets?.equipment.length === 1"
              :equipment="order?.shared_assets?.equipment"
            />
          </TabPanel>

          <!-- <TabPanel>
            <ChatSingleView :thread-id="existingThread" />
          </TabPanel> -->
          <TabPanel
            class="text-sm text-gray-500"
            v-if="order?.shared_assets?.fields?.length > 0"
          >
            <shared-fields :fields="order?.shared_assets?.fields" />
          </TabPanel>
          <TabPanel
            class="text-sm text-gray-500"
            v-if="order?.shared_assets?.equipment?.length > 0"
          >
            <shared-equipment :equipment="order?.shared_assets?.equipment" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
// import client from "@/api/parcel/api";

import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
// import TextArea from "@/components/form/TextArea.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
// import DateTimeInput from "@/components/form/DateTimeInput.vue";
// import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
// import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
import HorizontalPanelSteps from "@/components/progress/HorizontalPanelSteps.vue";
// import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";
// import HaulingOrderDetails from "@/layouts/common/orders/order-details/HaulingOrderDetails.vue";
// import DefaultOrderDetails from "@/layouts/common/orders/order-details/DefaultOrderDetails.vue";

// import { ExternalLinkIcon } from "@heroicons/vue/outline";

// import CompleteProductListing from "@/layouts/common/listing/CompleteProductListing.vue";
// import CompleteOrderForm from "@/layouts/common/orders/CompleteOrderForm.vue";
import OrderObjectInterface from "@/layouts/common/orders/OrderObjectInterface.vue";

import SharedFields from "@/layouts/common/shared-assets/SharedFields.vue";
import SharedEquipment from "@/layouts/common/shared-assets/SharedEquipment.vue";

import { useToast } from "vue-toastification";

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DeleteModal,
    // DateTimeInput,
    // TextArea,
    // CurrencyInput,
    // NumberInput,
    // CheckboxInput,
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    // CompleteProductListing,
    HorizontalPanelSteps,
    // ViewAttachmentsTable,
    // ChatSingleView,
    // ExternalLinkIcon,
    // HaulingOrderDetails,
    // DefaultOrderDetails,
    // CompleteOrderForm,
    OrderObjectInterface,
    SharedFields,
    SharedEquipment,
  },
  data() {
    const toast = useToast();
    return {
      modalIsActive: false,
      toast,
      currentProduct: null,
      currentAgreement: null,
    };
  },
  computed: {
    orderId() {
      return this.$route.params.orderId;
    },
    order() {
      return this.$store.getters.getGrowerOrderById(this.orderId);
    },
    orderClosed() {
      if (
        this.order &&
        (this.order.status === "seller_closed" ||
          this.order.status === "buyer_closed")
      ) {
        return true;
      } else {
        return false;
      }
    },
    orderCanceled() {
      if (this.order && this.order.status === "seller_canceled") {
        return true;
      } else {
        return false;
      }
    },
    orderCompleted() {
      if (this.order && this.order.status === "seller_completed") {
        return true;
      } else {
        return false;
      }
    },
    orderSteps() {
      // let stepTwoStatuses = [
      //   "seller_responded",
      //   "buyer_confirmed",
      //   "seller_completed",
      // ];
      let steps = [
        { name: "Order Submitted", status: "complete" },
        // {
        //   name: "Order Viewed",
        //   status: stepTwoStatuses.includes(this.order.status)
        //     ? "complete"
        //     : "upcoming",
        // },
        {
          name: "Order Completed",
          status: this.order
            ? this.order.status === "seller_completed"
              ? "complete"
              : "upcoming"
            : "",
        },
      ];
      return steps;
    },
    // existingThread() {
    //   return this.$store.getters.getThreadByPartner(
    //     this.currentProduct?.seller_details?.id
    //   )?.id;
    // },
    productOrderCategory() {
      switch (this.currentProduct?.category) {
        case "Hauling":
          return "HaulingOrderDetails";
        case "Input":
          return "InputOrderDetails";
        default:
          return "DefaultOrderDetails";
      }
    },
  },
  methods: {
    async acceptOrder() {
      await this.$store.dispatch("updateGrowerOrder", {
        id: this.order.id,
        status: "buyer_confirmed",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Accepted!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "grower-portal-buy-orders-all" });
    },
    async rejectOrder() {
      await this.$store.dispatch("updateGrowerOrder", {
        id: this.order.id,
        status: "buyer_rejected",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Rejected", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "grower-portal-buy-orders-all" });
    },
    async closeOrder() {
      await this.$store.dispatch("updateGrowerOrder", {
        id: this.order.id,
        status: "buyer_closed",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Canceled", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "grower-portal-buy-orders-all" });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
    orderId: {
      // handler(value) or handler(newValue, oldValue)
      async handler() {
        if (this.orderId === undefined) return;
        let order = this.order;
        if (!order)
          order =
            (await this.$store.getters.getGrowerOrderById(this.orderId)) || {};
        console.log("order", order, this.orderId);
        let agreement =
          this.$store.getters.getGrowerQuoteById(order.agreement) || {};
        console.log("agreement", agreement);
        this.currentAgreement = agreement;
        let product = this.$store.getters.getGrowerProductById(
          agreement.service,
        );
        this.currentProduct = product;
        // let data = await client.grower.services.readOne(order);
        // this.currentProduct = data.data;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
