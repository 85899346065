import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      controllerDeliveries: [],
    };
  },
  mutations: {
    SET_CONTROLLER_CROP_DELIVERIES(state, deliveryData) {
      state.controllerDeliveries = deliveryData;
    },
    RESET_CONTROLLER_CROP_DELIVERIES(state) {
      state.controllerDeliveries = [];
    },
  },
  actions: {
    async getControllerDeliveries({ commit }) {
      const { data, errors } = await client.controller.cropDeliveries.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_CONTROLLER_CROP_DELIVERIES", data.crop_deliveries);
      }
    },
  },
  getters: {
    getAllControllerDeliveries: (state) => {
      return state.controllerDeliveries;
    },
    getControllerDeliveryById: (state) => (deliveryId) => {
      return state.controllerDeliveries.find(
        (delivery) => delivery.id === parseInt(deliveryId),
      );
    },
    getControllerDeliveriesByOrder: (state) => (orderId) => {
      return state.controllerDeliveries.filter(
        (delivery) => delivery.crop_order === parseInt(orderId),
      );
    },
  },
};
