<template>
  <form
    @submit.prevent="submitTaskForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <text-input
      :wideItem="true"
      inputTitle="Category"
      v-model="currentTask.category"
      :selectOptions="taskTypes"
      :noSort="true"
      :displayOnly="true"
      :displayOnlyValue="currentTask.category"
    />
    <text-input
      :wideItem="true"
      inputTitle="Equipment"
      :selectOptions="taskTypes"
      :noSort="true"
      :displayOnly="true"
      :displayOnlyValue="
        currentEquipment.alias +
        ' (' +
        currentEquipment.equipment_make +
        ' ' +
        currentEquipment.equipment_model +
        ')'
      "
      v-if="
        currentTask.category == 'Maintenance' ||
        currentTask.category == 'Repair' ||
        currentTask.category == 'Other equipment task'
      "
    />
    <!-- <select-search
      :wideItem="true"
      inputTitle="Equipment"
      v-model="currentTask.equipment"
      :selectOptions="equipmentList"
      v-if="
        currentTask.category == 'Maintenance' ||
        currentTask.category == 'Repair' ||
        currentTask.category == 'Other equipment task'
      "
    /> -->
    <select-search
      :wideItem="true"
      inputTitle="Field"
      v-model="currentTask.field"
      :selectOptions="fieldList"
      v-else
    />
    <select-dropdown
      :wideItem="true"
      inputTitle="Status"
      :selectOptions="statusOptions"
      v-model="currentTask.status"
    />
    <div class="sm:col-span-3">
      <label class="block text-sm font-medium text-gray-700">Assigned To</label>
      <div class="mt-1">
        <select
          v-model="currentTask.user"
          id="user"
          name="user"
          class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-twilight-500 focus:outline-none focus:ring-twilight-500 sm:text-sm"
        >
          <option v-for="u in allUsers" :key="u.id" :value="u.id">
            {{ u.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="sm:col-span-6">
      <text-area inputTitle="Instructions" v-model="currentTask.instructions" />
    </div>
    <div class="sm:col-span-3">
      <date-time-input
        inputTitle="Requested Completion Date"
        v-model="currentTask.deadline"
        :displayOnly="false"
      />
    </div>
  </form>
</template>

<script>
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import TextInput from "@/components/form/TextInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  props: {
    taskId: {
      type: Number,
      default: null,
    },
    // needed to allow a submit button outside the form tags to submit the form
    formId: {
      type: String,
      required: true,
    },
    prepopulatedStatus: {
      type: String,
      default: "Ready",
    },
  },
  components: {
    SelectDropdown,
    DateTimeInput,
    TextArea,
    TextInput,
    SelectSearch,
    // BaseButton,
  },
  data() {
    const statusOptions = [
      "Requested",
      "Recommended",
      "Ready",
      "Assigned",
      "Complete",
      "Canceled",
      "InProcess",
    ];
    let taskTypes = [
      "Planting",
      "Tilling",
      "Irrigation",
      "Spraying",
      "Manual / Hand labor",
      "Hauling",
      "Scouting",
      "Observation",
      "Other field task",
      "Harvesting",
      // equipment tasks
      "Maintenance",
      "Repair",
      "Other equipment task",
    ];
    return {
      statusOptions,
      taskTypes,
      currentTask: null,
      currentEquipment: null,
      allFields: this.$store.state.sharedFields.fields,
      allUsers: this.$store.state.users.users,
      allEquipment: this.$store.state.sharedEquipment.equipment,
    };
  },
  computed: {
    fieldList() {
      return this.allFields.map((field) => {
        return { label: field.properties.name, value: field.id };
      });
    },
    equipmentList() {
      return this.allEquipment.map((asset) => {
        return { label: asset.alias, value: asset.id };
      });
    },
    allPartners() {
      let partners = [];
      this.$store.state.orgs.orgs.forEach((partner) => {
        if (partner.feature_flags.find((ff) => ff === "vp-farm-intelligence")) {
          partners.push(partner);
        }
      });
      return partners;
    },
  },
  methods: {
    submitTaskForm() {
      if (this.taskId) {
        this.updateTask();
      } else {
        this.createTask();
      }
    },
    // Form Methods
    async readTask() {
      // console.log("readTask", this.taskId, this.$store.state.sharedTasks.tasks);
      this.currentTask = this.$store.getters.getSharedTaskById(this.taskId);
      if (!this.currentTask) {
        // not loaded yet, let's go get the latest first
        console.log("task not loaded yet, let's go get the latest first");
        await this.$store.getters.getSharedTasks;
        this.currentTask = this.$store.getters.getSharedTaskById(this.taskId);
      }
      this.currentTask.deadline = this.currentTask.deadline?.substring(0, 16);
      this.currentEquipment = this.$store.getters.getSharedEquipmentById(
        this.currentTask.equipment,
      );
      // console.log("currentTask", this.currentTask, this.currentEquipment);
    },
    createTask() {
      this.currentTask.source = this.$store.getters.getCurrentUser.id;
      if (this.currentTask.user === -1) {
        this.currentTask.user = null;
      }
      if (this.currentTask.user && this.currentTask.user[0] === "p") {
        this.currentTask.org = this.currentTask.user?.substring(1);
        this.currentTask.user = null;
        this.$store.dispatch("createSharedTask", this.currentTask);
      } else {
        this.$store.dispatch("createTask", this.currentTask);
      }
      this.$router.push({ name: "vendor-activities" });
    },
    updateTask() {
      this.$store.dispatch("updateSharedTask", {
        taskId: this.taskId,
        task: this.currentTask,
      });
      // console.log("saved vendor task, go to list");
      this.$router.push({ name: "vendor-activities" });
    },
    clearForm() {
      this.currentTask = {
        category: "", // string
        instructions: "", // string
        status: null, // string - enum
        user: null, // int
        source: null, // int
        field: null, // int
        deadline: null, // string - datetime
      };
      this.currentTask.status = this.prepopulatedStatus;
    },
  },
  watch: {
    taskId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        //console.log("the fieldId param changed to ", value); //, " from ", oldValue);
        if (value) {
          this.readTask();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
