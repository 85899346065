<template>
  <form
    @submit.prevent="submitForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div class="sm:col-span-6">
      Select the management plan source to use for this field:
    </div>
    <select-dropdown
      :wideItem="true"
      inputTitle="Crop Year"
      v-model="year"
      :selectOptions="crop_years"
      :noSort="false"
      :required="true"
    />
    <select-search
      inputTitle="Field"
      :wideItem="true"
      :required="true"
      placeholder="Pick a Field"
      :selectOptions="fieldList"
      v-model="field"
    />
    <div class="sm:col-span-6">
      <radio-input
        v-model="keepOrReplace"
        :selectOptions="[
          { label: 'Keep existing tasks', value: 'keep' },
          { label: 'Replace all tasks', value: 'replace' },
        ]"
      />
    </div>
    <div class="mt-8 sm:col-span-3">
      <submit-button buttonText="Save" :formId="formId" />
      <base-button
        buttonText="Cancel"
        :redButton="true"
        @buttonClick="cancelSave"
      />
    </div>
  </form>
</template>
<script>
import RadioInput from "@/components/form/RadioInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
export default {
  components: {
    RadioInput,
    SelectDropdown,
    SelectSearch,
    BaseButton,
    SubmitButton,
  },
  events: ["closeModal"],
  props: {
    currentFieldId: {
      type: Number,
      required: true,
    },
  },
  data() {
    let current_year = new Date().getFullYear();
    let allFields = this.$store.state.fields.fields;
    let fieldList = allFields.map((field) => {
      return { label: field.properties.name, value: field.id };
    });
    let crop_years = [];
    for (let i = 2022; i <= current_year; i++) {
      crop_years.push({
        label: i.toString(),
        value: i,
      });
    }
    return {
      keepOrReplace: "keep",
      year: current_year,
      crop_years: crop_years,
      field: null,
      fieldList: fieldList,
      formId: "plan-retrieve-form",
    };
  },
  methods: {
    submitForm() {
      // if keepOrReplace is "replace", then we need to remove the tasks from the field
      if (this.keepOrReplace === "replace") {
        // get the tasks for this field
        let currentTasks = this.$store.getters.getTasksByField(
          this.currentFieldId,
        );
        // remove them
        currentTasks.forEach((task) => {
          // console.log("removing task", task);
          this.$store.dispatch("deleteTask", task);
        });
      }
      // copy in tasks from chosen plan
      let plan = this.$store.getters.getTasksByField(this.field);
      plan.forEach((task) => {
        let newTask = { ...task };
        newTask.source = this.$store.getters.getCurrentUser?.id;
        newTask.status = "Ready";
        newTask.field = this.currentFieldId;
        // console.log("saving task", task, newTask, this.currentFieldId);
        this.$store.dispatch("createTask", newTask);
      });
      this.$emit("closeModal");
    },
    cancelSave() {
      console.log("cancelSave");
      this.$emit("closeModal");
    },
  },
};
</script>
