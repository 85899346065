<template>
  <h1 class="text-3xl font-extrabold tracking-tight text-gray-900">
    Acres Purchased
  </h1>
  <div
    id="chart"
    class="object-center"
    v-if="allocations && allocations.length > 0"
  >
    <apexchart
      :type="action.chartOptions.chart.type"
      :options="action.chartOptions"
      :series="action.series"
      class="w-4/6"
    ></apexchart>
  </div>
  <div v-else>
    <h1 class="pt-8 text-3xl font-extrabold tracking-tight text-gray-900">
      No orders yet...
    </h1>
    Want to find something in the crop market? Click the button below to check
    it out...
    <p />
    <router-link :to="{ name: 'buyer-listings-grape' }">
      <button
        class="ripple mt-4 rounded bg-green-500 px-4 py-2 text-sm font-medium uppercase text-white shadow transition hover:bg-green-600 hover:shadow-lg focus:outline-none"
      >
        Grape Marketplace
      </button>
    </router-link>
  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    // let allocations = this.$store.state.buyerAllocations.buyerAllocations;

    return {
      // action,
      // allocations,
    };
  },
  computed: {
    allocations() {
      return this.$store.state.buyerAllocations.buyerAllocations;
    },
    action() {
      let allocations = this.allocations;
      let crop_variety = {}; // variety:{ava:}
      //console.log("chart data", allocations);
      allocations.forEach((allocation) => {
        let key = allocation.shared_data.crop_details?.crop_type;
        if (allocation?.crop_details?.details?.variety)
          key =
            allocation.shared_data.crop_details.details.variety +
            " " +
            allocation.shared_data.crop_details?.crop_type;
        if (!crop_variety[key]) {
          crop_variety[key] = {};
          crop_variety[key]["acres"] = Math.round(allocation.acres);
        } else {
          crop_variety[key]["acres"] += Math.round(allocation.acres);
        }
      });
      let variety_sold = [];

      for (let variety in crop_variety) {
        variety_sold.push(crop_variety[variety].acres);
      }
      console.log("chart data", allocations, crop_variety, variety_sold);
      let action = {};
      if (allocations?.length > 0) {
        action = {
          series: [
            {
              name: "Acres Bought",
              data: variety_sold, //[44, 55, 41, 67, 22, 43],
            },
          ],
          chartOptions: {
            chart: {
              type: "bar",
              height: 200,
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                //borderRadius: 20,
              },
            },
            xaxis: {
              type: "category",
              categories: Object.keys(crop_variety),
              labels: { trim: true, offsetX: 2 },
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          },
        };
      }
      return action;
    },
  },
  methods: {},
};
</script>
