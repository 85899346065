<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'name', label: 'Name', filtered: false },
          { name: 'status', label: 'Status' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="vendors"
        @update:derived-collection="derivedVendors = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="Find Vendors"
              :usePlusIcon="true"
              @buttonClick="navigateDiscovery"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="vendor in derivedVendors" :key="vendor.id">
        <table-data td-label="Name">
          {{ vendor.name }}
        </table-data>
        <table-data td-label="Status">
          {{ vendor.status }}
        </table-data>
        <table-data class="text-center">
          <router-link
            v-if="!vendor.agreementBased"
            :to="{
              name: 'manage-vendor',
              params: { vendorId: vendor.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            {{ vendor.buyer_confirmed === null ? "Respond" : "Manage" }}
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'grower-portal-quotes-all',
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View Agreements
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import _ from "lodash";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  data() {
    return {
      derivedVendors: [],
    };
  },
  computed: {
    vendors() {
      let vendors = _.cloneDeep(this.$store.state.vendors.vendors);
      for (let vendor of vendors) {
        if (vendor.status) continue;
        vendor.status =
          vendor.buyer_confirmed === null
            ? "New Request"
            : vendor.seller_confirmed === null
              ? "Pending"
              : (vendor.seller_confirmed &&
                  vendor.buyer_confirmed &&
                  "Accepted") ||
                "Rejected";
      }
      if (!vendors) vendors = [];
      let quotes = this.$store.state.growerQuotes.growerQuotes;

      if (quotes.length > 0) {
        for (let quote of quotes) {
          if (!vendors.find((vendor) => vendor.id == quote.seller_details.id)) {
            let quote_status = "";
            if (quotes.find((quote) => quote.status == "buyer_confirmed")) {
              quote_status = "Active";
            } else if (
              quotes.find((quote) => quote.status == "seller_responded")
            ) {
              quote_status = "Pending";
            } else {
              quote_status = "Inactive";
            }
            console.log("pushing new vendor", quote.seller_details?.name);
            vendors.push({
              id: quote.seller_details?.id,
              name: quote.seller_details?.name,
              status: quote_status,
              agreementBased: true,
            });
          }
        }
      }
      return vendors;
    },
  },
  methods: {
    navigateDiscovery() {
      this.$router.push({ name: "grower-portal-discover" });
    },
  },
};
</script>
