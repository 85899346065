<template>
  <purchase-modal
    :open="isPurchaseModalOpen"
    :product="suggestedPurchaseToOpen"
    @clickDismiss="closePurchaseModal"
  />
  <div v-if="derivedProducts.length > 0">
    <base-table>
      <template #headerCells>
        <table-header-cell :isLeadingCell="true" headerText="Product" />
        <table-header-cell
          :hiddenOnMediumAndSmallScreens="true"
          headerText="Price"
        />
        <table-header-cell
          :hiddenOnMediumAndSmallScreens="true"
          headerText="Source"
        />
        <table-header-cell :isEmptyHeader="true" headerText="Product Details" />
      </template>
      <template #bodyRows>
        <tr v-for="product in derivedProducts" :key="product.rowid">
          <table-body-cell :cellData="product.name" :isLeadingCell="true" />
          <table-body-cell
            :hiddenOnSmallScreens="true"
            :cellData="product.price"
          />
          <table-body-cell
            :hiddenOnSmallScreens="true"
            :cellData="product.source"
          />
          <table-body-cell
            :isButtonCell="true"
            @buttonCellClick="openPurchaseModal(product.id)"
            buttonDisplayText="View Product"
          />
        </tr>
      </template>
    </base-table>
  </div>
  <div v-else>
    <table-body-cell
      :hiddenOnMediumAndSmallScreens="false"
      cellData="No Suggested Purchases"
    />
  </div>
</template>

<script>
import PurchaseModal from "@/components/modals/PurchaseModal.vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";

const exampleProducts = [
  {
    id: 1,
    name: "Air cleaner outer element",
    price: "$26.10",
    rating: 4.7,
    href: "#",
    source: "Kubota",
    description:
      "Keep your Kubota running like new with Kubota Genuine parts. Kubota parts are designed and engineered to original factory specifications to keep your equipment operating at peak performance. And, with our industry-leading parts availability, you can be confident your local Kubota dealer will be able to quickly supply the parts you need. For reliable, long-lasting performance of your Kubota equipment, choose Kubota Genuine Products.",
    imageSrc:
      "https://www.messicks.com/services/imageview.ashx?seq=0&ven=&img=&id=16260&thumbnail=False&w=800&hideIfEmpty=False",
    imageAlt: "Air cleaner outer element.",
    buy_link:
      "https://shop.kubotausa.com/oemcatalogs/p/kubota_economy_utility_tractors_m_m5700s_f2803_ea_pn_1g835_12001/kubota_frequently_used_parts-frequently_used_parts/6086618e736717bec46ec0a0/filter-outer-air",
  },
  {
    id: 2,
    name: "Air cleaner inner element",
    price: "$18.06",
    rating: 4.7,
    href: "#",
    source: "Kubota",
    description:
      "Keep your Kubota running like new with Kubota Genuine parts. Kubota parts are designed and engineered to original factory specifications to keep your equipment operating at peak performance. And, with our industry-leading parts availability, you can be confident your local Kubota dealer will be able to quickly supply the parts you need. For reliable, long-lasting performance of your Kubota equipment, choose Kubota Genuine Products.",
    imageSrc:
      "https://www.messicks.com/services/imageview.ashx?seq=0&ven=&img=&id=16196&thumbnail=False&w=800&hideIfEmpty=False",
    imageAlt: "Tractor in a field spraying fertilizer.",
    buy_link:
      "https://shop.kubotausa.com/oemcatalogs/p/kubota_economy_utility_tractors_m_m5700s_f2803_ea_pn_1g835_12001/kubota_frequently_used_parts-frequently_used_parts/6086618a736717bec46ec091/filter-inner-air",
  },
];

export default {
  components: {
    PurchaseModal,
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
  },
  props: {
    currentField: { type: Number, default: null },
    currentEquipment: { type: Number, default: null },
    currentTask: { type: Number, default: null },
  },
  data() {
    return {
      isPurchaseModalOpen: false,
      suggestedPurchaseToOpen: null,
    };
  },
  methods: {
    openPurchaseModal(suggestedPurchaseId) {
      this.suggestedPurchaseToOpen = this.derivedProducts.find(
        (product) => product.id === suggestedPurchaseId,
      );
      this.isPurchaseModalOpen = true;
    },
    closePurchaseModal() {
      this.isPurchaseModalOpen = false;
    },
  },
  computed: {
    derivedProducts() {
      let derivedProducts = [];
      let currentTask = this.$store.getters.getTaskById(this.currentTask);
      if (
        currentTask?.instructions?.includes(
          "Air cleaner outer element: replace",
        )
      )
        derivedProducts = exampleProducts;
      if (currentTask.details?.suggested_products) {
        derivedProducts = currentTask.details.suggested_products;
      }
      console.log("derivedProducts", currentTask, derivedProducts);
      return derivedProducts;
    },
  },
};
</script>

<style></style>
