<template>
  <div v-if="!nofields" class="mx-auto px-4 sm:px-6 md:px-8">
    <h1 class="text-2xl font-semibold text-gray-900">{{ title }}</h1>
  </div>
  <div class="mx-auto px-4 sm:px-6 md:px-8" v-if="layers?.length > 0">
    <PageCard
      v-if="nofields"
      headingTitle="No Fields"
      headingDescription="Please add a field before viewing the map."
    />
    <fields-map
      :layers="layers"
      :showTurnByTurn="true"
      height="h-screen-3/4"
      v-else
    />
  </div>
</template>

<script>
import FieldsMap from "@/components/map/MapViewer.vue";
import PageCard from "@/components/cards/PageCard.vue";

export default {
  props: {
    title: {
      type: String,
      default: "Growers Map",
    },
    fieldId: {
      type: [Number, String],
      default: null,
    },
    growerId: {
      type: [Number, String],
      default: null,
    },
    offerId: {
      type: [Number, String],
      default: null,
    },
    contractId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {};
  },
  components: {
    PageCard,
    FieldsMap,
  },
  methods: {
    navToFields() {
      this.$router.push({ name: "fields" });
    },
  },
  computed: {
    growers() {
      let orders = this.$store.getters.getDerivedBuyerCropOrders({});
      // iterate through orders and get the unique growers from order.seller_details.id and order.seller_details.name
      let growers = [];
      orders.forEach((order) => {
        let grower = {
          id: order.seller_details?.id,
          name: order.seller_details?.name,
        };
        if (!growers.some((g) => g.id === grower.id)) {
          growers.push(grower);
        }
      });
      return growers;
    },
    growerColors() {
      var colorArray = [
        "#FF6633",
        "#FFB399",
        "#FF33FF",
        "#FFFF99",
        "#00B3E6",
        "#E6B333",
        "#3366E6",
        "#999966",
        "#99FF99",
        "#B34D4D",
        "#80B300",
        "#809900",
        "#E6B3B3",
        "#6680B3",
        "#66991A",
        "#FF99E6",
        "#CCFF1A",
        "#FF1A66",
        "#E6331A",
        "#33FFCC",
        "#66994D",
        "#B366CC",
        "#4D8000",
        "#B33300",
        "#CC80CC",
        "#66664D",
        "#991AFF",
        "#E666FF",
        "#4DB3FF",
        "#1AB399",
        "#E666B3",
        "#33991A",
        "#CC9999",
        "#B3B31A",
        "#00E680",
        "#4D8066",
        "#809980",
        "#E6FF80",
        "#1AFF33",
        "#999933",
        "#FF3380",
        "#CCCC00",
        "#66E64D",
        "#4D80CC",
        "#9900B3",
        "#E64D66",
        "#4DB380",
        "#FF4D4D",
        "#99E6E6",
        "#6666FF",
      ];
      let colors = [];
      let growers = this.growers;
      let colorIndex = 0;
      //   console.log("growers", growers);
      for (let grower of growers) {
        if (colors.find((c) => c.id === grower.id)) {
          continue;
        }
        colors.push({
          id: grower.id,
          color: colorArray[colorIndex],
          name: grower.name,
        });
        colorIndex++;
      }
      colors = colors.sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return colors;
    },
    allocations() {
      return this.$store.getters.getDerivedBuyerAllocations({});
    },
    layers() {
      let layers = [];
      let allocations = this.allocations;
      let fields = [];
      for (let allocation of allocations) {
        let field = allocation.shared_data?.field_details;
        let order = this.$store.getters.getBuyerOrderById(
          allocation.crop_order,
        );
        // console.log("order", order);
        let filter = true;
        if (this.fieldId) {
          filter = field.id == this.fieldId;
        }
        if (this.growerId) {
          filter = order.seller_details.id == this.growerId;
        }
        if (this.offerId) {
          filter = order.offer == this.offerId;
        }
        // console.log("filter", filter, this.fieldId, this.offerId, order.offer);
        // TODO: add contract filter for GRP account
        // if (this.contractId) {
        //   filter = order.contract === this.contractId;
        // }
        field.properties.seller_details = order.seller_details;
        field.properties.allocated_acres = allocation.acres;
        if (field && filter) {
          fields.push(field);
        }
      }
      let fillColor = "";
      let fieldJSON = {
        type: "FeatureCollection",
        features: fields.map((field) => {
          let grower_name = field.properties?.seller_details?.name;
          let growerColor = this.growerColors.find(
            (c) => c.id === field.properties?.seller_details?.id,
          );
          if (growerColor) {
            fillColor = growerColor.color;
          }
          return {
            type: "Feature",
            id: field.id,
            geometry: field.geometry,
            properties: {
              name:
                field.properties?.allocated_acres +
                " acres sold by " +
                grower_name,
              fillColor: fillColor,
            },
          };
        }),
      };
      let legend = "<div class='font-bold bg-white'>Legend</div>";
      for (let grower of this.growerColors) {
        legend += `<div class="flex bg-white"><div style="color:${grower.color}">&#9632;</div>&nbsp;${grower.name}</div>`;
      }
      legend += "</div>";
      let fieldLayer = {
        name: "Fields",
        geojson: fieldJSON,
        // style: { fillColor: "red", opacity: 1, weight: 1, color: "green" },
        style: setStyle,
        key: legend,
      };

      //console.log("fields layer", fieldLayer);
      layers.push(fieldLayer);

      return layers; // [{"name": "tractor","geojson":this.geojson}];
    },
    nofields() {
      return this.layers[0]?.geojson?.features.length === 0;
    },
  },
};
function setStyle(feature) {
  return {
    fillColor: feature.properties.fillColor,
    fillOpacity: 0.9,
    // opacity: feature.properties.strokeOpacity,
    // color: feature.properties.strokeColor,
    // fillOpacity: feature.properties.fillOpacity,
    // opacity: feature.properties.strokeOpacity
  };
}
</script>
<!--
  <style>
   page {
      display:flex;
      flex-flow:row;
  }
  </style>
  -->
