import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      growerInvoices: [],
    };
  },
  mutations: {
    SET_GROWER_SERVICE_INVOICES(state, invoiceData) {
      state.growerInvoices = invoiceData;
    },
    RESET_GROWER_SERVICE_INVOICES(state) {
      state.growerInvoices = [];
    },
  },
  actions: {
    async getGrowerInvoices({ commit }) {
      const { data, errors } = await client.grower.serviceInvoices.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_GROWER_SERVICE_INVOICES", data.invoices);
      }
    },
  },
  getters: {
    getGrowerInvoiceById: (state) => (invoiceId) => {
      return state.growerInvoices.find(
        (invoice) => invoice.id === parseInt(invoiceId),
      );
    },
  },
};
