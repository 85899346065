//Working Assignments is an in-memory array of fields, and whether they are allocated to a given order.

export default {
  state() {
    return {
      allocations: [],
    };
  },
  mutations: {
    SET_WORKING_ALLOCATIONS(state, allocationData) {
      state.allocations = allocationData;
    },
    ADD_WORKING_ALLOCATION(state, allocationObject) {
      state.allocations.push(allocationObject);
    },
    UPDATE_WORKING_ALLOCATION(state, allocationObject) {
      state.allocations = state.allocations.filter(function (obj) {
        return obj.crop !== allocationObject.crop;
      });
      state.allocations.push(allocationObject);
    },
    DELETE_WORKING_ALLOCATION(state, allocationObject) {
      state.allocations = state.allocations.filter(function (obj) {
        return obj.id !== allocationObject.id;
      });
    },
    RESET_WORKING_ALLOCATIONS(state) {
      state.allocations = [];
    },
  },
  actions: {
    loadWorkingAllocations({ rootState, getters }, orderId) {
      let startTimer = Date.now();
      rootState.workingAllocations.allocations = []; //avoiding time penalty of commit("RESET_WORKING_ALLOCATIONS");
      let resetTimer = Date.now();
      let savedAllocations = null;

      if (orderId) {
        savedAllocations = getters.getGrowerAllocationsByOrderId(orderId);
      }
      //console.log("loadWorkingAllocations",orderId,rootState, savedAllocations.length, commit);
      for (let field of rootState.fields.fields) {
        let allocation = {
          id: null, // Int
          order: orderId ? parseInt(orderId) : null, // Int
          crop: field?.properties?.current_crop?.id, // Int id
          acres: 0, // Int
          notes: "", // string
          allocated: false, // Boolean
        };
        if (orderId) {
          let existingAllocation = savedAllocations.find(
            (a) => a.crop == allocation.crop,
          );
          if (existingAllocation) {
            //console.log("adding field to allocations", allocation);
            allocation.id = existingAllocation.id;
            allocation.acres = existingAllocation.acres;
            allocation.notes = existingAllocation.notes;
            allocation.allocated = true;
          }
        }
        rootState.workingAllocations.allocations.push(allocation); //updating directly because there was a huge time penalty using commit("ADD_WORKING_ALLOCATION", allocation);
        // console.log("find this allocation",crop, id, this.allocations[this.allocations.findIndex(a => a.crop == crop)].allocated);
      }
      let finishTimer = Date.now();
      console.log("timers", finishTimer - resetTimer, finishTimer - startTimer);
      //console.log("Allocations loaded",rootState.fields.fields.length,rootState.workingAllocations.allocations);
    },
    saveWorkingAllocations({ rootState, getters, dispatch }, orderId) {
      //console.log("save allocations", commit, orderId);
      let orderAllocations = getters.getGrowerAllocationsByOrderId(orderId);
      // first find any allocations that aren't already allocated and add them.
      let allocated = rootState.workingAllocations.allocations.filter(
        (a) => a.allocated == true,
      );
      //console.log("allocated", rootState.workingAllocations.allocations, allocated);

      let addedAllocations = allocated.filter(
        (a) => !orderAllocations.some((b) => a.crop === b.crop),
      );

      for (let allocation of addedAllocations) {
        let newAllocation = {
          crop_order: null, // Int id
          order: orderId,
          crop: allocation.crop, // Int id
          acres: allocation.acres, // Int
          notes: null, // string
        };
        //console.log("add", newAllocation);
        dispatch("createGrowerAllocation", newAllocation);
      }
      //TODO: go look up the order ID for addedAllocations once getGrowerAllocation has run and update the allocation array.

      // then find any that are allocated and should no longer be allocated because the user is asking to remove them.
      let removedAllocations = orderAllocations.filter(
        (a) => !allocated.some((b) => a.crop === b.crop),
      );

      for (let allocation of removedAllocations) {
        //console.log("remove", allocation);
        dispatch("deleteGrowerAllocation", allocation);
      }
      // finally, update any existing orders to have new notes or acres
      let updatedAllocations = allocated.filter((a) =>
        orderAllocations.some((b) => a.crop === b.crop),
      );
      for (let allocation of updatedAllocations) {
        console.log("update", allocation);
        dispatch("updateGrowerAllocation", allocation);
      }
      // console.log(
      //   "order allos: ",
      //   orderAllocations,
      //   allocated,
      //   addedAllocations,
      //   removedAllocations,
      //   updatedAllocations
      // );
    },
    async createWorkingAllocation({ commit }, allocation) {
      console.log("create working allocation", allocation);
      commit("ADD_WORKING_ALLOCATION", allocation);
    },
    async updateWorkingAllocation({ commit }, allocation) {
      console.log("update working allocation", allocation);
      commit("UPDATE_WORKING_ALLOCATION", allocation);
    },
    async deleteWorkingAllocation({ commit }, allocation) {
      commit("DELETE_WORKING_ALLOCATION", allocation);
    },
  },
  getters: {
    getWorkingAllocationById: (state) => (allocationId) => {
      return state.allocations.find(
        (allocation) => allocation.id === parseInt(allocationId),
      );
    },
    getWorkingAllocationByCropId: (state) => (cropId) => {
      return state.allocations.find(
        (allocation) => allocation.crop === parseInt(cropId),
      );
    },
    getWorkingAllocationsByOrderId: (state) => (orderId) => {
      let order = null;
      if (orderId) order = orderId;
      return state.allocations.filter(
        (allocation) => allocation.order === order,
      );
    },
    getSumWorkingAllocationAcresByOrderId: (state) => (orderId) => {
      let order = null;
      if (orderId) order = orderId;
      let orderAllocations = state.allocations.filter(
        (allocation) => allocation.order === order,
      );
      let sum = orderAllocations.reduce((accumulator, object) => {
        return accumulator + object.acres;
      }, 0);
      return sum;
    },
    getAssignedWorkingAllocationsByCropId: (state) => (cropId) => {
      let cropAllocations = state.allocations.filter(
        (allocation) =>
          allocation.crop === parseInt(cropId) && allocation.allocated,
      );
      let sum = "";
      // console.log("get working allocations", getters, cropAllocations);
      if (cropAllocations.length > 0) {
        sum = cropAllocations
          .reduce((accumulator, object) => {
            return accumulator + object.acres;
          }, 0)
          ?.toFixed(1);
      }
      // console.log("get assigned allocations", cropAllocations, sum);
      return sum;
    },
    getSumWorkingAllocationAcresByCropId: (state, getters) => (ids) => {
      //first get any allocations from other fields
      let currentAllocations = getters.getGrowerAllocationsByCropId(
        ids?.cropId,
      );
      let otherAllocations = currentAllocations.filter(
        (allocation) => allocation.order !== ids?.orderId,
      );
      let otherAcres = otherAllocations.reduce((accumulator, object) => {
        return accumulator + object.acres;
      }, 0);
      //let currentAcres = getters.getSumAllocationAcresByCropId(cropId);
      // then get any from this order that are in memory that aren't saved yet
      let cropAllocations = state.allocations.filter(
        (allocation) => allocation.crop === parseInt(ids?.cropId),
      );

      // console.log("get working allocations", getters, cropAllocations);
      let sum = cropAllocations.reduce((accumulator, object) => {
        return accumulator + object.acres;
      }, 0);
      // console.log("get working allocations", ids.cropId, currentAllocations, otherAllocations, otherAcres, sum);
      return sum + otherAcres;
    },
  },
};
