<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          {
            name: 'field_name',
            label: 'Field Name',
          },
          { name: 'grower_name', label: 'Grower Name' },
          {
            name: 'contract_name',
            label: 'Contract Name',
          },
          {
            name: 'type',
            label: 'Premium Type',
          },
          { name: 'actions', label: '', filtered: false },
          { name: 'actions', label: '', filtered: false },
          { name: 'actions', label: '', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="allocationPremiums"
        @update:derived-collection="derivedAllocationPremiums = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <div class="hidden sm:flex">
              <table-toolbar-button
                buttonText="Export"
                heroIcon="DownloadIcon"
                @buttonClick="exportXLSX"
              />
              <!-- <table-toolbar-button
                buttonText="Export Premiums"
                heroIcon="DownloadIcon"
                @buttonClick="exportAllocationPremiums"
              />
              <table-toolbar-button
                buttonText="Export Records"
                heroIcon="DownloadIcon"
                @buttonClick="exportRecords"
              />
              <table-toolbar-button
                buttonText="Export Deliveries"
                heroIcon="DownloadIcon"
                @buttonClick="exportDeliveries"
              /> -->
            </div>
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr
        v-for="premium in derivedAllocationPremiums"
        :key="premium.allocation_id"
      >
        <table-data td-label="Field Name">
          {{ premium.field_name }}
        </table-data>
        <table-data td-label="Grower Name">
          {{ premium.grower_name }}
        </table-data>
        <table-data td-label="Contract Name">
          {{ premium.contract_name }}
        </table-data>
        <table-data td-label="Premium Type">
          {{ premium.premium_type }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-market-offer-single',
              params: { offerId: premium.offer_id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View Offer
          </router-link>
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-view-order',
              params: { orderId: premium.order_id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View Order
          </router-link>
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-bp-affiliate-grower-report',
              params: { growerId: premium.grower_id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View Grower
          </router-link>
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-bp-affiliate-field-report',
              params: {
                fieldId: premium.field_id,
              },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View Field
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

// import { exportToCSV } from "@/components/composables/exportFile";
import { flattenObj } from "@/components/composables/scriptUtils";
import { writeFileXLSX, utils } from "xlsx";
import _ from "lodash";

const RecordTypeDefintions = require("@/layouts/grower-portal/records/RecordTypes.json");

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  props: {
    offerId: {
      type: [Number, String],
      default: null,
    },
    growerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      derivedAllocationPremiums: [],
    };
  },
  computed: {
    recordTypes() {
      let recordTypes = RecordTypeDefintions.filter(
        (recordType) => !recordType.readonly,
      );
      // console.log("recordTypes", recordTypes);
      recordTypes = recordTypes.map((recordType) => {
        return recordType.category;
      });
      // remove the recordType of category NDVI
      recordTypes = recordTypes.filter((recordType) => recordType !== "NDVI");
      return recordTypes;
    },
    offers() {
      return this.$store.getters.getBuyerMarketOffers();
    },
    orders() {
      return this.$store.getters.getAllBuyerOrders;
    },
    premiums() {
      return this.$store.getters.getBuyerCropOrderPremiums;
    },
    growers() {
      return this.$store.getters.getBuyerCropOrderGrowers;
    },
    allocations() {
      return this.$store.getters.getAllBuyerAllocations;
    },
    deliveries() {
      return this.$store.getters.getAllBuyerDeliveries;
    },
    allocationPremiums() {
      let allocationPremiums = [];
      for (let allocation of this.allocations) {
        // if allocation.shared_data doesn't exist skip the allocation
        if (!allocation.shared_data) {
          continue;
        }
        let allocationPremium = {};
        allocationPremium.allocation_id = allocation.id;
        allocationPremium.allocation_acres = allocation.acres;
        allocationPremium.field_id = allocation.shared_data.field_details.id;
        allocationPremium.field_name =
          allocation?.shared_data.field_details.properties.name;
        allocationPremium.farm_name =
          allocation?.shared_data.field_details.properties.farm;
        allocationPremium.field_acres =
          allocation?.shared_data.field_details.properties.acres;
        allocationPremium.crop_type =
          allocation?.shared_data.crop_details.crop_type;
        // allocationPremium.crop_details =
        //   allocation?.shared_data.crop_details.details;
        let order = this.orders.find(
          (order) => order.id == allocation.crop_order,
        );
        allocationPremium.order_id = order?.id;
        allocationPremium.order_quantity = order?.quantity;
        allocationPremium.order_units = order?.units;
        allocationPremium.grower_id = order?.seller_details?.id;
        allocationPremium.grower_name = order?.seller_details?.name;
        // if the order has an offer, get the offer details
        // if the order does not have an offer, set the offer_id, contract_id, and contract_name to null
        if (order.offer) {
          let offer = this.offers.find((offer) => offer.id == order.offer);
          allocationPremium.offer_id = offer.id;
          allocationPremium.assignment_quantity = offer.quantity;
          // allocationPremium.assignment_units = offer.units;
          allocationPremium.contract_crop = offer.crop;
          allocationPremium.contract_id = offer.buyer_contract;
          allocationPremium.contract_description = offer.contract_description;
          allocationPremium.contract_name = offer.contract_name;
        } else {
          allocationPremium.offer_id = null;
          allocationPremium.assignment_quantity = null;
          // allocationPremium.assignment_units = null;
          allocationPremium.contract_crop = null;
          allocationPremium.contract_id = null;
          allocationPremium.contract_description = null;
          allocationPremium.contract_name = null;
        }
        // for each premium where premium.order_id matches the allocation.crop_order
        // create a new object that combines the allocationPremium object with the premium object
        // add the new object to the allocationPremiums array
        let premiums = this.premiums.filter(
          (premium) => premium.order_id == allocation.crop_order,
        );
        for (let premium of premiums) {
          // reverse the order of the keys within allocationPremium so that the first key is assignment_units
          // this is the order that the keys are in the premium object
          let allocationPremiumKeys = Object.keys(allocationPremium).reverse();
          let allocationPremiumReversed = {};
          for (let key of allocationPremiumKeys) {
            allocationPremiumReversed[key] = allocationPremium[key];
          }
          let allocationPremiumWithPremium = {
            ...allocationPremiumReversed,
            premium_id: premium.id,
            premium_type: premium.type,
            premium_terms: premium.terms,
          };
          allocationPremiums.push(allocationPremiumWithPremium);
        }
      }

      return allocationPremiums;
    },
    recordsForExport() {
      // iterate through the allocations
      // for each allocation, get the records from allocation.shared_data.record_details
      // for each record...
      // add the allocation id as allocation_id to the record object
      // add the field id, field name, and field farm name from allocation.shared_data.field_details to the record object
      // add the crop_type, from allocation.shared_data.crop_type to the record object
      // add the order id to the record object
      // add the grower id and grower name to the record object
      // add the offer id to the record object
      // add the contract id and contract name to the record object
      let records = [];
      for (let allocation of this.allocations) {
        if (allocation.shared_data.record_details) {
          for (let record of allocation.shared_data.record_details) {
            // loop through the record payload and add the details to the record object at the top level
            record.allocation_id = allocation.id;
            record.field_id = allocation.shared_data.field_details.id;
            record.field_name =
              allocation.shared_data.field_details.properties.name;
            record.field_farm =
              allocation.shared_data.field_details.properties.farm;
            record.crop_type = allocation.shared_data.crop_details.crop_type;
            record.order_id = allocation.crop_order;
            let order = this.orders.find(
              (order) => order.id == record.order_id,
            );
            record.grower_id = order.seller_details.id;
            record.grower_name = order.seller_details.name;
            if (order.offer) {
              let offer = this.offers.find((offer) => offer.id == order.offer);
              record.offer_id = offer.id;
              record.contract_id = offer.buyer_contract;
              record.contract_name = offer.contract_name;
            } else {
              record.offer_id = null;
              record.contract_id = null;
              record.contract_name = null;
            }
            records.push(record);
          }
        }
      }
      // for each item in this.recordTypes, create an array for that recordType
      // each record in records, add the record to the array for the recordType == record.category
      // return the array of arrays
      let recordsForExport = [];
      for (let recordType of this.recordTypes) {
        let recordsOfType = records.filter(
          (record) => record.category == recordType,
        );
        recordsForExport.push(recordsOfType);
      }
      // remove any arrays that are length 0
      recordsForExport = recordsForExport.filter(
        (recordType) => recordType.length > 0,
      );
      return recordsForExport;
    },
    deliveriesForExport() {
      // iterate through the deliveries
      // for each delivery, get the order from this.orders where delivery.crop_order == order.id
      // set delivery.grower_id = order.seller_details.id;
      // set delivery.grower_name = order.seller_details.name;
      // if (order.offer) {
      //   let offer = this.offers.find((offer) => offer.id == order.offer);
      //   delivery.offer_id = offer.id;
      //   delivery.contract_id = offer.contract_id;
      //   delivery.contract_name = offer.contract_name;
      // } else {
      //   delivery.offer_id = null;
      //   delivery.contract_id = null;
      //   delivery.contract_name = null;
      // }
      // add the delivery to the deliveries array
      let deliveries = [];
      for (let delivery of this.deliveries) {
        let order = this.orders.find(
          (order) => order.id == delivery.crop_order,
        );
        delivery.grower_id = order.seller_details.id;
        delivery.grower_name = order.seller_details.name;
        if (order.offer) {
          let offer = this.offers.find((offer) => offer.id == order.offer);
          delivery.offer_id = offer.id;
          delivery.contract_id = offer.buyer_contract;
          delivery.contract_name = offer.contract_name;
        } else {
          delivery.offer_id = null;
          delivery.contract_id = null;
          delivery.contract_name = null;
        }
        deliveries.push(delivery);
      }
      return deliveries;
    },
  },
  methods: {
    // exportAllocationPremiums() {
    //   exportToCSV(this.allocationPremiums, "premiums.csv");
    // },
    // exportRecords() {
    //   // for each of the recordType arrays in this.recordsForExport's array of arrays
    //   // let the record equal the record object and ...record.payload
    //   // and exportToCsv(recordTypeArray, recordType + ".csv")
    //   for (let recordTypeArray of this.recordsForExport) {
    //     let recordType = "Parcel_Records_" + recordTypeArray[0].category;
    //     let records = recordTypeArray.map((record) => {
    //       let recordPayload = record.payload;
    //       delete record.payload;
    //       return { ...record, ...recordPayload };
    //     });
    //     exportToCSV(records, recordType + ".csv");
    //     // timeout to allow multiple file downloads in safari
    //     setTimeout(() => {}, 1000);
    //   }
    // },
    // exportDeliveries() {
    //   exportToCSV(this.deliveriesForExport, "deliveries.csv");
    // },
    exportRecordSheet() {
      // flatten the record and its payload to a single object
      let recordSet = [];
      for (let recordTypeArray of structuredClone(this.recordsForExport)) {
        let recordType = recordTypeArray[0].category;
        let records = [];
        for (let record of recordTypeArray) {
          let payload = flattenObj(record.payload, true);
          // console.log("flattened payload", payload);
          for (let key in payload) {
            if (Array.isArray(payload[key])) {
              for (let i = 0; i < payload[key].length; i++) {
                let repeatedSection = payload[key][i];
                // console.log("repeatedSection", repeatedSection);
                for (let attribute in repeatedSection) {
                  payload[key + "_" + i + "_" + attribute] =
                    repeatedSection[attribute];
                }
              }
              // let repeatedSection = payload[key];
              // for (let attribute of repeatedSection) {
              //   for (let mixKey in attribute) {
              //     payload[key + "_" + mixKey] = attribute[mixKey];
              //   }
              // }
              delete payload[key];
            }
          }
          delete record.payload;
          let recordFlat = flattenObj(record, false);
          if (Object.keys(payload).length > 0) _.merge(recordFlat, payload);
          records.push(recordFlat);
        }

        recordSet.push({ type: recordType, records: records });
      }
      // console.log("recordSet", recordSet);
      return recordSet;
    },
    exportXLSX() {
      console.log("exporting xlsx");
      let wb = utils.book_new();
      let ws = utils.json_to_sheet(this.allocationPremiums);
      utils.book_append_sheet(wb, ws, "Premiums");
      let recordSet = this.exportRecordSheet();
      for (let recordType of recordSet) {
        // console.log("recordType", recordType);
        ws = utils.json_to_sheet(recordType.records);
        utils.book_append_sheet(wb, ws, recordType.type);
      }
      ws = utils.json_to_sheet(this.deliveriesForExport);
      utils.book_append_sheet(wb, ws, "Deliveries");
      writeFileXLSX(wb, "premiums.xlsx");
    },
  },
};
</script>

<style></style>
