<template>
  <div
    class="form-check mt-1"
    :class="wideItem == true ? 'sm:col-span-3 ' : ''"
  >
    <label class="form-check-label inline-block text-sm text-gray-800">
      {{ inputTitle }}
      <span v-if="helpText"
        ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
      /></span>
      <input
        class="form-check-input float-left mr-2 mt-1 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
        type="checkbox"
        v-model="inputValue"
        :disabled="displayOnly"
        :placeholder="placeholder"
        :class="conditionalInputStyling"
        @blur="this.$emit('onBlur', inputValue)"
      />
    </label>
  </div>
</template>

<script>
import ToolTip from "@/components/modals/ToolTip.vue";
export default {
  props: {
    inputTitle: { type: String, default: "" },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: [String, Boolean], default: "" },
    placeholder: { type: String, default: "" },
    modelValue: { type: Boolean },
    wideItem: { type: Boolean, default: false },
    classProp: { type: String, defautl: null },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  components: {
    ToolTip,
  },
  emits: ["update:modelValue", "onBlur"],
  data() {
    let disabledStyle = "";
    if (this.displayOnly) disabledStyle = "bg-gray-200";
    return {
      disabledStyle,
      inputValue: this.modelValue,
    };
  },
  computed: {
    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) style += this.classProp;
      return style;
    },
  },
  watch: {
    inputValue(value) {
      // console.log("inputValue changed to: ", value);
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      // console.log("modelValue changed to: ", value);
      this.inputValue = value;
    },
  },
};
</script>
