<template>
  <slot-modal
    :open="showRequestModal"
    modalTitle="Request Product"
    modalDescription="Submit your request to verified product vendors. If the product is available, you will get a quote for the desired quantity/packaging."
    @closeModal="closeRequestModal"
  >
    <template #icon
      ><PaperAirplaneIcon class="text-black-600 h-6 w-6" aria-hidden="true"
    /></template>
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <text-input
          :wideItem="true"
          inputTitle="Package Size"
          inputHtmlType="text"
          v-model="modalPackage"
        />
        <number-input
          :wideItem="true"
          inputTitle="Quantity"
          inputHtmlType="text"
          v-model="modalQty"
          :step="1"
        />
      </div>
    </template>
    <template #buttons
      ><button
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
        @click="addRequestToCart"
      >
        Submit
      </button></template
    >
  </slot-modal>
  <div class="bg-gray-50">
    <div>
      <search-container>
        <template #navigation>
          <search-navigation current-navigation="companies" />
        </template>
        <template v-slot:categories>
          <!-- <search-lob-select /> -->
          <div class="flex">
            <!--<span class="mr-4 mt-0.5 text-sm font-bold">Filters: </span> -->
            <filter-menu
              :filters="filterOptions"
              @update:filters="updateFilters"
            />
          </div>
          <!-- Put filters in here to distinguish between buy now products and quoted products and services. See Ebay for example UI (auction, buy it now)
            <div class="justify start flex">
            <div
              :class="
                showCompanies ? 'bg-gray-200 text-blue-700 underline' : ''
              "
              class="m-2 rounded-lg border-2 pl-2 pr-2"
            >
              <button @click="showCompanies = !showCompanies">Companies</button>
            </div>
            <div
              :class="showProducts ? 'bg-gray-200 text-blue-700 underline' : ''"
              class="m-2 rounded-lg border-2 pl-2 pr-2"
              v-if="products?.length > 0"
            >
              <button @click="showProducts = !showProducts">Products</button>
            </div>
          </div>  -->
        </template>
        <template v-slot:input>
          <search-input v-model="search" @handleChange="handleSearchValue" />
        </template>
        <!-- <template v-slot:filters>
              <CommonSearchFilters
                :filters="filterOptions"
                @checkedToggle="toggleFilter"
              />
            </template> -->
        <!-- <template v-slot:activeFilters>
              <CommonSearchActiveFilters
                :activeFilters="activeFilters"
                @removeActiveFilter="toggleFilter"
              />
            </template> -->
      </search-container>
      <!-- <div
        class="mt-4 pl-4 text-lg font-bold text-blue-700"
        v-if="showProducts && activeProducts?.length > 0"
      >
        Products
      </div>
      <search-grid
        v-if="
          showProducts &&
          (activeProducts?.length > 0 || inputRequestSearchResults.length > 0)
        "
      >
        <template #gridItems>
          <product-search-grid-card
            v-for="product in products"
            :key="product.id"
            :product="product"
            @gridItemClicked="navigateToProduct"
          />
        </template>
      </search-grid> -->
      <div class="mt-4 pl-4 text-lg font-bold text-blue-700">Companies</div>
      <search-grid @gridItemClicked="navigateToCompany">
        <template #gridItems>
          <company-search-grid-card
            v-for="company in activeCompanies"
            :key="company.id"
            :company="company"
            @gridItemClicked="navigateToCompany"
          />
        </template>
      </search-grid>
    </div>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { FilterProducts } from "@/layouts/grower-portal/discover/search/FilterComposable.js";
import SearchContainer from "@/layouts/grower-portal/discover/search/SearchContainer.vue";
import SearchNavigation from "@/layouts/grower-portal/discover/search/SearchNavigation.vue";
import SearchInput from "@/layouts/grower-portal/discover/search/SearchInput.vue";
import SearchGrid from "@/layouts/grower-portal/discover/search/SearchGrid.vue";
// import SearchLobSelect from "@/layouts/grower-portal/discover/search/SearchLobSelect.vue";
// import ProductSearchGridCard from "@/layouts/grower-portal/discover/search/ProductSearchGridCard.vue";
import CompanySearchGridCard from "@/layouts/grower-portal/discover/search/CompanySearchGridCard.vue";
import FilterMenu from "@/components/dropdowns/FilterMenu.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import { PaperAirplaneIcon } from "@heroicons/vue/outline";
import api from "@/api/parcel/api";
import { useToast } from "vue-toastification";

export default {
  components: {
    SearchContainer,
    SearchNavigation,
    SearchInput,
    SearchGrid,
    // SearchLobSelect,
    // ProductSearchGridCard,
    CompanySearchGridCard,
    FilterMenu,
    SlotModal,
    TextInput,
    NumberInput,
    PaperAirplaneIcon,
  },
  data() {
    let filterOptions = {};
    filterOptions.category = {
      id: "category",
      name: "Category",
      filterColumn: "category",
      active: true,
      options: {},
    };
    let products = this.$store.state.growerProducts["growerProducts"];
    let inputs = this.$store.state.growerInputListings["listings"];
    products = products.concat(inputs);

    let productCategories = products.map((product) => product.category);
    // add category if at least one product of that category exists
    let categories = new Set(productCategories);

    for (let category of categories) {
      switch (category) {
        case "Input":
          filterOptions.category.options.input = {
            value: "Input",
            label: "Crop Protection",
            checked: false,
          };
          break;
        case "Product":
          filterOptions.category.options.product = {
            value: "Product",
            label: "Ag Products",
            checked: false,
          };
          break;
        case "Carbon":
          filterOptions.category.options.carbon = {
            value: "Carbon",
            label: "Sustainability",
            checked: false,
          };
          break;
        case "Hauling":
          filterOptions.category.options.hauling = {
            value: "Hauling",
            label: "Hauling",
            checked: false,
          };
          break;
        case "Service":
          filterOptions.category.options.service = {
            value: "Service",
            label: "Ag Services",
            checked: false,
          };
          break;
        case "Recommendation":
          filterOptions.category.options.recommendation = {
            value: "Recommendation",
            label: "Recommendation Services",
            checked: false,
          };
          break;
      }
    }

    let productTypes = products.map((product) => product.type);
    // remove undefined, null, and empty strings from product types. These will be from non-crop protection products
    productTypes = productTypes.filter(
      (type) => type !== undefined && type !== "" && type !== null,
    );
    // add type if at least one product of that type exists
    let types = new Set(productTypes);
    filterOptions.type = {
      id: "type",
      name: "Type",
      filterColumn: "type",
      active: false,
      options: {},
    };
    for (let type of types) {
      filterOptions.type.options[type] = {
        value: type,
        label: type,
        checked: false,
      };
    }
    filterOptions.ingredient = {
      id: "ingredient",
      name: "Active Ingredient",
      filterColumn: "active_ingredients",
      active: false,
      options: {},
    };
    let filters = this.$route.query.filter ? this.$route.query.filter : null;

    if (filters) {
      if (typeof filters === "string") {
        // single filter is a string
        filters = [filters];
      }
      // format is filter.option.checked
      for (let filter of filters) {
        filter = filter.split(":");
        let checked = filter[1] === "true" ? true : false;
        let filterString = filter[0].split(".");
        let filterType = filterString[0];
        let option = filterString[1];
        if (
          filterType &&
          option &&
          filterOptions[filterType]?.options[option]
        ) {
          filterOptions[filterType].active = true;
          filterOptions[filterType].options[option].checked = checked;
        }
      }
    }
    return {
      search: this.$route.query.search ? this.$route.query.search : "",

      showCompanies: true,
      filterOptions: filterOptions,
      inputRequestSearchResults: [],
      toast: useToast(),
      modalQty: 0,
      modalPackage: "",
      showRequestModal: false,
      focusedProduct: null,
      // companies: this.$store.state.companies["companies"],
      // seeds: this.$store.state.companies["seedCompanies"],
    };
  },
  computed: {
    companies() {
      let companies = this.$store.state.companies["companies"];
      companies = companies.map((company) => {
        return {
          ...company,
          tagline: company.tagline ? company.tagline : company.desc_rtf,
        };
      });
      return companies;
    },
    // seeds() {
    //   let seeds = this.$store.state.companies["seedCompanies"];
    //   return seeds;
    // },
    combinedCompanies() {
      // let companies = [...this.companies, ...this.seeds];
      let companies = this.companies;
      for (let company of companies) {
        let products = this.$store.state.growerProducts[
          "growerProducts"
        ]?.filter((product) => product.seller_details.id === company.org);
        let inputs = this.$store.state.growerInputListings["listings"]?.filter(
          (input) => input.seller_details.id === company.org,
        );
        products = products.concat(inputs);
        console.log("products", company.name, products);
        company.category = "";
        for (let product of products) {
          if (company.category.indexOf(product.category) == -1) {
            company["category"] += product.category + "; ";
          }
        }
      }
      return companies;
    },
    activeCompanies() {
      let activeCompanies = FilterProducts(
        this.combinedCompanies,
        this.search,
        this.filterOptions,
      );
      return activeCompanies;
      // if (this.search === "") return this.combinedCompanies;
      // const fuse = new Fuse(this.combinedCompanies, {
      //   useExtendedSearch: false,
      //   keys: ["name", "tagline", "desc_rtf", "details", "slug", "category"],
      //   threshold: 0.3,
      //   distance: 5000,
      // });
      // const company_results = fuse
      //   .search(this.search)
      //   .map((company) => company.item);
      // const all_results = this.activeProducts.reduce((results, product) => {
      //   if (
      //     !results.find(
      //       (company) => company?.org === product?.seller_details.id
      //     )
      //   ) {
      //     const company = this.companies.find(
      //       (company) => company.org === product?.seller_details.id
      //     );
      //     if (company) {
      //       results.push(company);
      //     }
      //   }
      //   return results;
      // }, company_results);
      // return all_results;
    },
    products() {
      let products = this.$store.state.growerProducts["growerProducts"];
      for (let product of products) {
        product["searchString"] = product.details;
      }
      let input_products = this.$store.state.growerInputListings["listings"];
      for (let input_product of input_products) {
        input_product["searchString"] =
          input_product.active_ingredients +
          " " +
          input_product.pests +
          " " +
          input_product.states +
          " " +
          input_product.crops +
          " " +
          input_product.epa_number +
          " " +
          input_product.type;
        " " + input_product.manufacturer;
      }
      let allProducts = products.concat(input_products).sort((a, b) => {
        return a.title.localeCompare(b.title); //TODO: Sort by relevance or number of orders
      });
      return allProducts;
    },
    activeProducts() {
      let activeProducts = this.products;
      // console.log("activeProducts", activeProducts);
      // filter product list by selected filters
      for (let filter in this.filterOptions) {
        if (this.filterOptions[filter].active) {
          let activeOptions = [];
          // console.log("filter ", filter, this.filterOptions[filter]);
          for (let option in this.filterOptions[filter].options) {
            if (this.filterOptions[filter].options[option].checked) {
              activeOptions.push(
                this.filterOptions[filter].options[option].value,
              );
            }
          }
          if (activeOptions.length > 0) {
            // console.log("activeOptions", activeOptions);
            for (let option of activeOptions) {
              let filterColumn = this.filterOptions[filter].filterColumn;
              activeProducts = activeProducts.filter((product) => {
                return product[filterColumn]?.includes(option);
              });
            }
          }
        }
      }

      // search for products in filtered list
      if (this.search.length > 0) {
        const fuse = new Fuse(activeProducts, {
          useExtendedSearch: false,
          keys: ["title", "keywords", "description", "searchString"],
          threshold: 0.3,
          distance: 5000,
        });
        activeProducts = fuse
          .search(this.search)
          .map((product) => product.item);
      }
      return activeProducts;
    },
  },
  methods: {
    openRequestModal(product) {
      this.showRequestModal = true;
      this.focusedProduct = product;
    },
    closeRequestModal() {
      this.showRequestModal = false;
      this.focusedProduct = null;
    },
    async handleSearchValue(value) {
      console.log("VALUE", value);
      const { data } = await api.grower.input_requests.searchRequests(value);
      console.log("search results", data);
      if (data) this.inputRequestSearchResults = data.input_requests;
    },
    async addRequestToCart() {
      const product = this.focusedProduct;
      console.log("Requested product", product);
      await this.$store.dispatch("addCartItem", {
        product: product.id,
        sku: { id: product.id },
        qty: this.modalQty,
        package: this.modalPackage,
        order: null,
        productType: "request",
        productDetails: product,
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Added to Cart!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.closeRequestModal();
    },
    navigateToCompany(company) {
      this.$router.push({
        name: "grower-portal-discover-company",
        params: { companySlug: company.slug },
      });
    },
    navigateToProduct(product) {
      if (product.category === "Input") {
        this.$router.push({
          name: "grower-portal-discover-single-input",
          params: { productId: product.id },
        });
      } else {
        this.$router.push({
          name: "grower-portal-discover-single-product",
          params: { productId: product.id },
        });
      }
    },

    updateFilters(filters) {
      this.filterOptions = filters;
    },
  },
};
</script>
