import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      vendorTerritories: [],
    };
  },
  mutations: {
    SET_VENDOR_SERVICE_TERRITORIES(state, territoryObjects) {
      state.vendorTerritories = territoryObjects;
    },
    ADD_VENDOR_SERVICE_TERRITORY(state, territoryObject) {
      state.vendorTerritories.push(territoryObject);
    },
    UPDATE_VENDOR_SERVICE_TERRITORY(state, territoryObject) {
      state.vendorTerritories = state.vendorTerritories.filter(function (obj) {
        return obj.id !== territoryObject.id;
      });
      state.vendorTerritories.push(territoryObject);
    },
    DELETE_VENDOR_SERVICE_TERRITORY(state, territoryObject) {
      state.vendorTerritories = state.vendorTerritories.filter(function (obj) {
        return obj.id !== territoryObject.id;
      });
    },
    RESET_VENDOR_SERVICE_TERRITORIES(state) {
      state.vendorTerritories = [];
    },
  },
  actions: {
    async getVendorTerritories({ commit }) {
      const { data, errors } = await client.vendor.serviceTerritories.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_VENDOR_SERVICE_TERRITORIES", data.territories);
      }
    },
    async createVendorTerritory({ commit, dispatch }, territory) {
      const { data, errors } = await client.vendor.serviceTerritories.create(
        null,
        territory,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("ADD_VENDOR_SERVICE_TERRITORY", data);
        this.dispatch("getVendorTerritories");
      }
    },
    async updateVendorTerritory({ commit, dispatch }, territory) {
      const { data, errors } = await client.vendor.serviceTerritories.update(
        territory.id,
        territory,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        // console.log(data);
        commit("UPDATE_VENDOR_SERVICE_TERRITORY", data);
        this.dispatch("getVendorTerritories");
      }
    },
    async deleteVendorTerritory({ commit, dispatch }, territory) {
      const { data, errors } = await client.vendor.serviceTerritories.delete(
        territory.id,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        // console.log(data);
        commit("DELETE_VENDOR_SERVICE_TERRITORY", territory);
        this.dispatch("getVendorTerritories");
      }
    },
  },
  getters: {
    getVendorTerritoryById: (state) => (territoryId) => {
      return state.vendorTerritories.find(
        (l) => l.id === parseInt(territoryId),
      );
    },

    getDerivedVendorTerritories:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedVendorTerritories = state.vendorTerritories;
        let filteredVendorTerritories = _.isEmpty(filter)
          ? expandedVendorTerritories
          : _.filter(expandedVendorTerritories, filter);
        let matchedVendorTerritories = _.isEmpty(match)
          ? filteredVendorTerritories
          : _.filter(filteredVendorTerritories, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedVendorTerritories = _.isEmpty(sort)
          ? matchedVendorTerritories
          : _.orderBy(matchedVendorTerritories, sortColumns, sortOrders);
        let paginatedVendorTerritories =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedVendorTerritories,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedVendorTerritories;
        return paginatedVendorTerritories;
      },
  },
};
