import client from "@/api/parcel/api.js";

export default {
  state() {
    return { agreements: [] };
  },
  mutations: {
    SET_GROWER_INPUT_AGREEMENTS(state, data) {
      state.agreements = data;
    },
    RESET_GROWER_INPUT_AGREEMENTS(state) {
      state.agreements = [];
    },
    ADD_GROWER_INPUT_AGREEMENT(state, agreement) {
      state.agreements.push(agreement);
    },
    UPDATE_GROWER_INPUT_AGREEMENT(state, agreement) {
      state.agreements = state.agreements.filter((a) => a.id !== agreement.id);
      state.agreements.push(agreement);
    },
  },
  actions: {
    async getGrowerInputAgreements({ commit, dispatch }) {
      const { data, errors } = await client.grower.input_agreements.read();
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("SET_GROWER_INPUT_AGREEMENTS", data.input_agreements);
      }
    },
    async createGrowerInputAgreement({ commit, dispatch }, agreement) {
      const { data, errors } = await client.grower.input_agreements.create(
        null,
        agreement,
      );
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("ADD_GROWER_INPUT_AGREEMENT", data);
        return data;
      }
    },
    async updateGrowerInputAgreement({ commit, dispatch }, agreement) {
      const { data, errors } = await client.grower.input_agreements.update(
        agreement.id,
        agreement,
      );
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_GROWER_INPUT_AGREEMENT", data);
        return data;
      }
    },
  },
  getters: {
    getGrowerInputAgreements: (state) => {
      return state.agreements;
    },
    getGrowerInputAgreementBySellerId: (state) => (sellerId) => {
      return state.agreements.find((a) => a.seller_details.id === sellerId);
    },
  },
};
