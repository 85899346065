import client from "@/api/parcel/api.js";

export default {
  state() {
    return { externalOrgs: [], availableOrgs: [] };
  },
  mutations: {
    SET_EXTERNAL_ORGS(state, data) {
      state.externalOrgs = data.externalOrgs;
      state.availableOrgs = data.availableOrgs;
    },
    RESET_EXTERNAL_ORGS(state) {
      state.externalOrgs = [];
      state.availableOrgs = [];
    },
    ADD_EXTERNAL_ORG(state, externalOrg) {
      state.externalOrgs.push(externalOrg);
    },
    UPDATE_EXTERNAL_ORG(state, externalOrg) {
      state.externalOrgs = state.externalOrgs.filter(
        (a) => a.id !== externalOrg.id,
      );
      state.externalOrgs.push(externalOrg);
    },
    REMOVE_EXTERNAL_ORG(state, externalOrg) {
      state.externalOrgs = state.externalOrgs.filter(
        (a) => a.id !== externalOrg,
      );
    },
  },
  actions: {
    async getExternalOrgs({ commit, dispatch }) {
      const { data, errors } = await client.orgs.readExternalOrgs();
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("SET_EXTERNAL_ORGS", {
          externalOrgs: data.external_orgs,
          availableOrgs: data.available_orgs,
        });
      }
    },
    async createExternalOrg({ commit, dispatch }, externalOrg) {
      const { data, errors } = await client.orgs.createExternalOrg(
        null,
        externalOrg,
      );
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("ADD_EXTERNAL_ORG", data);
        return data;
      }
    },
    async updateExternalOrg({ commit, dispatch }, externalOrg) {
      const { data, errors } = await client.orgs.updateExternalOrg(
        externalOrg.id,
        externalOrg,
      );
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_EXTERNAL_ORG", data);
        return data;
      }
    },
    async deleteExternalOrg({ commit, dispatch }, externalOrg) {
      const { data, errors } = await client.orgs.deleteExternalOrg(
        externalOrg.id,
      );
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("REMOVE_EXTERNAL_ORG", data);
        return data;
      }
    },
  },
  getters: {
    getExternalOrgById: (state) => (orgId) => {
      return state.externalOrgs.find((org) => org.id === parseInt(orgId));
    },
  },
};
