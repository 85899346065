<template>
  <div class="flex">
    <div class="mt-4 font-bold">
      SKU Information
      <ToolTip
        helpText="Add questions you need the grower to answer."
        helpTitle=""
      />
    </div>
    <button
      @click="addSku"
      class="ml-8 mt-3 h-8 w-12 rounded border bg-twilight-700 text-white"
    >
      + Add
    </button>
  </div>
  <div
    class="mb-4 mt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div
      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      v-for="(l, index) in skuList"
      :key="l"
    >
      <div
        v-if="!l.markedToDeleted"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <!-- "`Set ${index + 1} Options`" -->
        <!-- <text-area
        :inputTitle="`Question ${index + 1}`"
        :wideItem="true"
        :model-value="l.question"
        @update:model-value="
          (newVal) => {
            skuList[index].question = newVal;
          }
        "
      /> -->
        <TextInput
          inputTitle="Package"
          :wideItem="true"
          :display-only="l.id ? true : false"
          :display-only-value="l.title"
          v-model="l.title"
          v-if="l.id ? true : false"
        />
        <NumberInput
          inputTitle="Size"
          :wideItem="false"
          v-model="l.size"
          v-if="l.id ? false : true"
        />
        <SelectSearch
          inputTitle="Unit"
          :wideItem="false"
          :narrow-item="true"
          :display-only="l.id ? true : false"
          :display-only-value="l.title"
          v-model="l.unit"
          :selectOptions="[
            { value: 'g', label: 'grams' },
            { value: 'kg', label: 'kilograms' },
            { value: 'oz', label: 'ounces' },
            { value: 'lb', label: 'pounds' },
            { value: 'ton', label: 'tons' },
            { value: 'mg', label: 'milligrams' },
            { value: 'ml', label: 'milliliters' },
            { value: 'l', label: 'liters' },
            { value: 'gal', label: 'gallons' },
            { value: 'fl-oz', label: 'fluid ounces' },
            { value: 'pt', label: 'pints' },
            { value: 'qt', label: 'quarts' },
            { value: 'cup', label: 'cups' },
            { value: 'tbsp', label: 'tablespoons' },
            { value: 'tsp', label: 'teaspoons' },
            { value: 'each', label: 'each' },
          ]"
          v-if="l.id ? false : true"
        />
        <TextInput
          inputTitle="Container"
          :wideItem="false"
          v-model="l.packageDescription"
          v-if="l.id ? false : true"
          help-text="Bag, Packaged, Box, Jug, Container, etc. May be blank if n/a"
        />
        <NumberInput
          inputTitle="Case Size"
          :wideItem="false"
          v-model="l.caseCount"
          help-text="Set to number of containers per case. Set to 0 if not applicable"
          v-if="l.id ? false : true"
        />
        <CurrencyInput inputTitle="Price" :wideItem="false" v-model="l.price" />
        <TextInput
          inputTitle="External ID"
          :wideItem="false"
          :display-only="l.id ? true : false"
          :display-only-value="l.external_id"
          :model-value="l.external_id"
          @update:model-value="
            (newVal) => {
              skuList[index].external_id = newVal;
            }
          "
        />
        <CheckboxInput
          inputTitle="Available"
          :wideItem="true"
          v-model="l.available"
        />
        <button @click="rmSku(index)" class="flex text-blue-600">
          <trash-icon class="mr-2 h-6 w-6" /> Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import TextInput from "@/components/form/TextInput.vue";
import { TrashIcon } from "@heroicons/vue/outline";
import ToolTip from "@/components/modals/ToolTip.vue";
import TextInput from "@/components/form/TextInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";

export default {
  components: {
    TextInput,
    CheckboxInput,
    CurrencyInput,
    TrashIcon,
    ToolTip,
    NumberInput,
    SelectSearch,
  },
  props: {},
  data() {
    return {
      skuList: [],
    };
  },
  computed: {
    currentInputListing() {
      return this.$route.params?.productId;
    },
  },
  methods: {
    addSku() {
      this.skuList.push({
        available: true,
        title: null,
        package: null,
        price: null,
        external_id: null,
        input_listing: this.currentInputListing,
        size: null,
        unit: null,
        packageDescription: null,
        caseCount: null,
      });
    },
    rmSku(index) {
      // console.log("rmSku: ", index, this.skuList[index]);
      this.skuList[index].markedToDeleted = true;
    },
    async saveSkus(listingId) {
      // console.log("saveSkus: ", listingId, this.skuList);
      for (let index = 0; index < this.skuList.length; index++) {
        let sku = this.skuList[index];
        if (!sku.input_listing && !sku.markedToDeleted) {
          sku.input_listing = listingId;
        }
        if (sku.markedToDeleted) {
          if (sku.id) await this.deleteSku(sku, index);
        } else {
          if (sku.id) {
            await this.updateSku(sku, index);
          } else {
            if (sku.packageDescription === null) sku.packageDescription = "";
            sku.title = `${sku.size} ${sku.unit} ${sku.packageDescription}`;
            if (sku.caseCount > 0) sku.title += ` (${sku.caseCount} / Case)`;
            await this.createSku(sku, index, listingId);
          }
        }
      }
    },
    async createSku(sku, index, listingId = this.currentInputListing) {
      //   console.log("create sku: ", sku);
      let result = await this.$store.dispatch("createVendorInputUnit", {
        listing: listingId,
        unit: sku,
      });
      this.skuList[index].id = result?.id;
    },
    async updateSku(sku, index) {
      // console.log("update sku: ", sku, index);
      let result = await this.$store.dispatch("updateVendorInputUnit", {
        listing: this.currentInputListing,
        unit: { id: sku.id, available: sku.available, price: sku.price },
      });
      this.skuList[index].available = result.available;
    },
    async deleteSku(sku) {
      await this.$store.dispatch("deleteVendorInputUnit", sku.id);
    },
  },
  watch: {
    currentInputListing: {
      async handler(value) {
        console.log("currentInputListing: ", value);
        if (value === undefined) return;
        let skus = await this.$store.dispatch("getVendorInputUnits", value);
        if (skus.length > 0) {
          this.skuList = skus;
        } else {
          this.skuList = [
            {
              available: true,
              title: null,
              package: null,
              price: null,
              external_id: null,
              input_listing: this.currentInputListing,
            },
          ];
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
