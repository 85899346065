import client from "@/api/parcel/api.js";

// Don't use the state object, it is not used consistently below.
// Current pattern is to return the data via the get action and act on it in the component via a promise
export default {
  state() {
    return {
      settings: null,
    };
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = settings;
    },
    SET_USER_SETTINGS(state, settings) {
      state[settings.user] = settings;
    },
    RESET_SETTINGS(state) {
      state.settings = null;
    },
  },
  actions: {
    async getSettings({ commit }) {
      const { data, errors } = await client.settings.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_SETTINGS", data);
        return data;
      }
    },
    async updateSettings({ commit, dispatch }, settings) {
      const { data, errors } = await client.settings.update(null, settings);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("SET_SETTINGS", data);
        this.dispatch("getSettings");
      }
    },
    async adminGetSettings({ commit }, user) {
      const { data, errors } = await client.settings.admin_read(null, { user });
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_USER_SETTINGS", { user, ...data });
        return data;
      }
    },
    async adminUpdateSettings({ commit, dispatch }, settings) {
      const { data, errors } = await client.settings.admin_update(
        null,
        settings,
        { user: settings.user },
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("SET_USER_SETTINGS", { user: settings.user, ...data });
      }
    },
  },
  getters: {},
};
