<template>
  <div
    class="text-center text-xs"
    :class="vendorProducts.length > 0 ? 'text-gray-500' : 'text-red-500'"
  >
    By creating product listings, you agree to the
    <a class="text-blue-500 underline" href="https://parcel.ag/tos/vendor"
      >Parcel Vendor Terms of Service</a
    >, and you acknowledge that you are responsible for payment of any
    commissions generated from sales of your products or services.
  </div>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'title', label: 'Title', filtered: false },
          { name: 'active', label: 'Published' },
          { name: 'updated', label: 'Updated', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="vendorProducts"
        @update:derived-collection="derivedVendorProducts = $event"
        :defaultSort="{ title: 'asc' }"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewProductPage"
            />
            <feature-flagged requires="vp-inputs">
              <table-toolbar-button
                buttonText="Import"
                :usePlusIcon="false"
                heroIcon="UploadIcon"
                @buttonClick="goToImportProductPage"
              />
            </feature-flagged>
            <feature-flagged requires="vp-inputs">
              <table-toolbar-button
                buttonText="Export"
                :usePlusIcon="false"
                heroIcon="DownloadIcon"
                @buttonClick="exportProducts"
              />
            </feature-flagged>
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="p in derivedVendorProducts" :key="p.id">
        <table-data td-label="Title">
          {{ p.title }}
        </table-data>
        <table-data td-label="Active">
          {{ p.active }}
        </table-data>
        <table-data td-label="Updated">
          {{ new Date(p?.updated)?.toLocaleString("en", dateFormat) }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'vendor-single-product',
              params: { productId: p.id, productCategory: p?.category },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
import FeatureFlagged from "@/components/containers/FeatureFlagged.vue";
import { exportToCSV } from "@/components/composables/exportFile";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
    FeatureFlagged,
  },
  data() {
    return {
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
      },
      derivedVendorProducts: [],
    };
  },
  computed: {
    vendorProducts() {
      let derivedProducts = this.$store.getters.getDerivedVendorProducts({});
      let derivedInputListings =
        this.$store.getters.getDerivedVendorInputListings({});
      // derivedlistings.forEach((p) => {
      //   // if equipment type is older record style (just a string), don't try to convert from array to string
      //   if (p.equipment_type && typeof p.equipment_type === "object") {
      //     p.equipment_type = p.equipment_type.join(", ");
      //   }
      // });
      //console.log(derivedReservations);
      let allListings = derivedProducts.concat(derivedInputListings);
      return allListings;
    },
  },
  methods: {
    goToNewProductPage() {
      this.$router.push({ name: "vendor-new-product" });
    },
    goToImportProductPage() {
      this.$router.push({ name: "vendor-import-products" });
    },
    async exportProducts() {
      let inputListings = this.$store.getters.getDerivedVendorInputListings({});
      console.log(
        "inputListings",
        inputListings,
        this.$store.state.vendorInputListings,
      );
      for (let listing of inputListings) {
        let skus = this.$store.getters.getVendorInputListingUnitsByListingId(
          listing.id,
        );
        let skuString = "";
        console.log("skus for listing ID", listing.id, skus);
        for (let sku of skus) {
          if (skuString.length > 0) skuString += "\n";
          skuString += sku.title;
          if (sku.external_id) skuString += sku.external_id;
          skuString += ";";
          if (sku.available) skuString += sku.available;
          skuString += ";";
          if (sku.package) skuString += sku.package;
          skuString += ";";
          if (sku.price) skuString += sku.price;
        }
        listing.skus = skuString;
      }
      exportToCSV(inputListings, "inputs.csv");
    },
  },
};
</script>
