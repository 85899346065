import client from "@/api/parcel/api.js";

export default {
  state() {
    return { sharedOrgs: [] };
  },
  mutations: {
    SET_SHARED_ORGS(state, data) {
      state.sharedOrgs = data;
    },
    RESET_SHARED_ORGS(state) {
      state.sharedOrgs = [];
    },
  },
  actions: {
    async getSharedOrgs({ commit, dispatch }) {
      const { data, errors } = await client.orgs.readSharedOrgs();
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("SET_SHARED_ORGS", data.shared_orgs);
      }
    },
  },
  getters: {
    currentOrgIsFirstParty: (state, getters, rootState, rootGetters) => {
      // if the sharedOrgs array is empty, return true to prevent the banner from showing prior to loading the shared orgs
      if (state.sharedOrgs.length === 0) {
        return true;
      }
      return getters.getSharedOrgById(rootGetters.getCurrentOrg?.id)?.internal;
    },
    getSharedOrgById: (state) => (id) => {
      return state.sharedOrgs.find((org) => org.id === id);
    },
  },
};
