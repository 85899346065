<template>
  <div class="mt-2"></div>
  <form
    @submit.prevent="() => submitNewGrowerQuoteForm()"
    id="newGrowerQuoteForm"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6"
  >
    <div
      v-if="
        (currentProduct.agreement_layout.data_sharing.fields == true ||
          currentProduct.agreement_layout.data_sharing.equipment == true) &&
        !readOnly
      "
      class="col-span-full grid grid-cols-2 md:grid-cols-10"
    >
      <div
        class="text-left text-xs font-medium uppercase tracking-wider text-gray-500"
      >
        Share Assets
      </div>
      <!-- These two selects should only be shown on the quote form when a grower is filling it out. The quote page / layout will use Shared Assets to display info when readonly. -->
      <!-- Add a map viewer when selecting fields / equipment to ensure the grower is selecting the correct ones. -->
      <list-select
        class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
        inputTitle="Fields to be included for the quote"
        :listArray="fieldList"
        :rows="5"
        v-if="
          currentProduct.agreement_layout.data_sharing.fields == true &&
          !readOnly
        "
      />
      <!-- <shared-fields
        class="col-span-full"
        v-if="
          currentProduct.agreement_layout.data_sharing.fields == true && !readOnly
        "
        :fields="selectedFields"
      /> -->
      <list-select
        class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
        inputTitle="Equipment to be included for the quote"
        :listArray="equipmentList"
        :rows="5"
        v-if="
          currentProduct.agreement_layout.data_sharing.equipment == true &&
          !readOnly
        "
      />
      <!-- <shared-equipment
        v-if="
          currentProduct.agreement_layout.data_sharing.equipment == true &&
          !readOnly
        "
        :equipment="selectedEquipment"
      /> -->
    </div>
    <div class="mt-2 sm:col-span-6">
      <component
        :is="productQuoteCategory"
        @updateQuoteDetails="updateQuoteDetails"
        :readOnly="readOnly"
      />
    </div>
    <text-area
      v-for="question in currentProduct.agreement_layout.form"
      :key="question.question"
      :wideItem="true"
      :inputTitle="question.question"
      v-model="currentQuote.form_response[question.question]"
      required
      :displayOnly="readOnly"
      :displayOnlyValue="currentQuote.form_response[question.question]"
    />
    <select-dropdown
      v-for="variant in currentProduct.agreement_layout.variants"
      :key="variant.label"
      :inputTitle="variant.label"
      :wideItem="true"
      required
      :selectOptions="variant.choices.map((choice) => choice.name)"
      v-model="currentQuote.variants[variant.label]"
      :displayOnly="readOnly"
      :displayOnlyValue="currentQuote.variants[variant.label]"
    />
    <checkbox-input
      v-for="option in currentProduct.agreement_layout.options"
      :key="option.name"
      :inputTitle="option.name"
      :wideItem="true"
      v-model="currentQuote.options[option.name]"
      :displayOnly="readOnly"
      :displayOnlyValue="currentQuote.options[option.name]"
    />

    <div class="ml-1 sm:col-span-6" v-if="!readOnly">
      <submit-button
        :disabled="vendorPerspective || readOnly"
        buttonText="Submit Quote Request"
        formId="newGrowerQuoteForm"
      />
    </div>
  </form>
</template>

<script>
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import TextArea from "@/components/form/TextArea.vue";
// import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
import ListSelect from "@/components/form/ListSelect.vue";

// import FileSelect from "@/components/form/FileSelect.vue";
// import ListInput from "@/components/form/ListInput.vue";
// import ProductFormInput from "@/layouts/vendor-portal/products/product-form-inputs/ProductFormInput.vue";
// import ProductOptionsInput from "@/layouts/vendor-portal/products/product-form-inputs/ProductOptionsInput.vue";
// import ProductVariantsInput from "@/layouts/vendor-portal/products/product-form-inputs/ProductVariantsInput.vue";
// import RichTextInput from "@/components/form/RichTextInput.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";

import DefaultQuoteDetails from "@/layouts/common/quotes/quote-details/DefaultQuoteDetails.vue";
import HaulingQuoteDetails from "@/layouts/common/quotes/quote-details/HaulingQuoteDetails.vue";

import { useToast } from "vue-toastification";

// import moment from "moment";
export default {
  components: {
    SubmitButton,
    TextArea,
    // VariableStringTypeInput,
    SelectDropdown,
    CheckboxInput,
    // FileSelect,
    // ProductFormInput,
    // ProductOptionsInput,
    // ProductVariantsInput,
    // RichTextInput,
    // ListInput,
    // CheckboxInput,
    // NumberInput,
    ListSelect,
    DefaultQuoteDetails,
    HaulingQuoteDetails,
  },
  props: {
    // use this if form is used on a listing page
    vendorPerspective: {
      type: Boolean,
      required: true,
    },
    oneSharedAssetOnly: {
      type: Boolean,
      default: false,
    },
  },
  // if reading existing currentQuote:{
  //     "id": 0,
  // "updated": "2019-08-24T14:15:22Z",
  // "created": "2019-08-24T14:15:22Z",
  // "product": 0,
  // "quantity": 0,
  // "delivery_address": "string",
  // "form_response": { },
  // "variants": { },
  // "options": { },
  // "seller": 0,
  // "buyer": 0,
  // "status": "buyer_submitted",
  // "total_price": 0
  //     }
  data() {
    let fields = this.$store.state.fields.fields;
    let fieldList = fields.map((field) => {
      return {
        id: field.id,
        label: field.properties.farm + " " + field.properties.name,
        selected: false,
      };
    });
    let equipment = this.$store.state.equipment.equipment;
    let equipmentList = equipment.map((equipment) => {
      return {
        id: equipment.id,
        label: equipment.alias,
        selected: false,
      };
    });
    const toast = useToast();
    return {
      currentQuote: null,
      fieldList: fieldList,
      equipmentList: equipmentList,
      quoteDetails: {},
      toast,
    };
  },
  computed: {
    quoteId() {
      return this.$route.params.quoteId;
    },
    readOnly() {
      if (this.quoteId) {
        return true;
      } else {
        return false;
      }
    },
    productId() {
      if (this.quoteId) {
        return this.currentQuote.service;
      } else {
        return this.$route.params.productId;
      }
    },
    currentProduct() {
      if (this.vendorPerspective) {
        return this.$store.getters.getVendorProductById(this.productId);
      } else {
        return this.$store.getters.getGrowerProductById(this.productId);
      }
    },
    productQuoteCategory() {
      switch (this.currentProduct.category) {
        case "Hauling":
          return "HaulingQuoteDetails";
        // case "Input":
        //   return "InputQuoteDetails";
        default:
          return "DefaultQuoteDetails";
      }
    },
    fields() {
      return this.$store.state.fields.fields;
    },
    selectedFields() {
      let selectedFields = this.fieldList.filter((field) => field.selected);
      const fieldsFiltered = this.fields.filter((el) => {
        return selectedFields.some((f) => {
          return f.id === el.id;
        });
      });
      return fieldsFiltered;
    },
    equipment() {
      return this.$store.state.equipment.equipment;
    },
    selectedEquipment() {
      let selectedEquipment = this.equipmentList.filter(
        (equipment) => equipment.selected,
      );
      const equipmentFiltered = this.equipment.filter((el) => {
        return selectedEquipment.some((f) => {
          return f.id === el.id;
        });
      });
      return equipmentFiltered;
    },
  },
  methods: {
    readQuote() {
      if (this.vendorPerspective) {
        this.currentQuote = this.$store.getters.getVendorQuoteById(
          this.quoteId,
        );
      } else {
        this.currentQuote = this.$store.getters.getGrowerQuoteById(
          this.quoteId,
        );
      }
    },
    updateQuoteDetails(e) {
      console.log("update quote details", e);
      this.quoteDetails = e;
    },
    // Submit Form
    async submitNewGrowerQuoteForm() {
      if (this.oneSharedAssetOnly) {
        if (
          this.selectedFields.length > 1 ||
          this.selectedEquipment.length > 1
        ) {
          this.toast.error(
            "You can only select one asset of each type for this quote",
          );
          return;
        }
      }
      this.currentQuote.shared_assets = {
        fields: this.selectedFields,
        equipment: this.selectedEquipment,
      };
      this.currentQuote.details = this.quoteDetails;
      console.log("create quote", this.currentQuote);
      await this.$store.dispatch("createGrowerQuote", this.currentQuote);
      const existingThread = this.$store.getters.getThreadByPartner(
        this.currentProduct.seller_details.id,
      );
      if (!existingThread)
        this.$store.dispatch("createThread", {
          partner: this.currentProduct.seller_details.id,
          subject:
            this.currentProduct.seller_details.name +
            " & " +
            this.$store.getters.getCurrentOrg.name,
        });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Quote Created!", {
          timeout: 1500,
          hideProgressBar: true,
        });
        this.$router.push({ name: "grower-portal-quotes-all" });
      }
    },
    clearForm() {
      this.currentQuote = {
        product: null, // Int
        quantity: null, // Int
        delivery_address: null, // String
        form_response: {}, // [{name: "", description: ""}]
        variants: {},
        options: {},
      };
      this.currentQuote.service = this.productId;
      let agreement_start_date = new Date(Date.now());
      let agreement_end_date = new Date();
      agreement_end_date.setDate(
        agreement_start_date.getDate() + this.currentProduct.default_duration,
      );
      this.currentQuote.start_date = agreement_start_date
        .toISOString()
        .split("T")[0];

      this.currentQuote.end_date = agreement_end_date
        .toISOString()
        .split("T")[0];
      this.currentQuote.options =
        this.currentProduct.agreement_layout.options.reduce(
          (allOptions, option) => ({
            ...allOptions,
            [option.name]: false,
          }),
          {},
        );
      this.currentQuote.variants =
        this.currentProduct.agreement_layout.variants.reduce(
          (allVariants, variant) => ({
            ...allVariants,
            [variant.label]: null,
          }),
          {},
        );
      this.currentQuote.form_response =
        this.currentProduct.agreement_layout.form.reduce(
          (allQuestions, question) => ({
            ...allQuestions,
            [question.question]: null,
          }),
          {},
        );
    },
  },
  watch: {
    quoteId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readQuote();
        } else {
          if (this.currentProduct) {
            this.clearForm();
          }
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
