import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      profile: {},
    };
  },
  mutations: {
    SET_PROFILE(state, profileData) {
      state.profile = profileData;
    },
    RESET_PROFILE(state) {
      state.profile = {};
    },
  },
  actions: {
    async getProfile({ commit }) {
      const { data, errors } = await client.profile.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_PROFILE", data);
      }
    },
    async updateProfile({ commit, dispatch }, profile) {
      const { data, errors } = await client.profile.update(null, profile);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("SET_PROFILE", data);
      }
    },
    async uploadProfileAvatar({ dispatch }, profileAvatar) {
      const { data, errors } = await client.profile.createProfileLogo(
        null,
        profileAvatar,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        console.log(data);
        return data;
        // await this.dispatch("getProfile");
      }
    },
  },
  getters: {
    getCurrentUser(state) {
      return state.profile;
    },
  },
};
