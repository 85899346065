<template>
  <!-- HEADER SECTION -->
  <div class="flow-root">
    <div class="float-left text-xs font-bold">
      this form is available electronically
    </div>

    <div class="float-right -mt-3 print:hidden">
      <button
        class="mr-2 rounded-md bg-blue-500 px-4 py-1 text-white"
        @click="$router.back"
      >
        Back
      </button>
      <button
        class="rounded-md bg-blue-500 px-4 py-1 text-white"
        @click="printReport"
      >
        Print
      </button>
    </div>
  </div>
  <div class="overflow-clip border">
    <div class="flow-root bg-white">
      <div class="float-left">
        <span class="font-bold">FSA-578 Manual</span> <br /><span
          class="text-xs"
          >(08-22-19)</span
        >
      </div>
      <div class="float-right flex">
        <div>
          <span class="font-bold">U.S. Department of Agriculture </span><br />
          <span class="text-xs">Farm Service Agency</span>
        </div>
        <div class="border pb-8">
          <div class="mb-4 text-xs">PAGE</div>
          <div class="text-center">
            <span class="mt-4 bg-blue-100 px-4 py-3 text-lg font-bold">1</span>
            OF
            <span class="bg-blue-100 px-4 py-3 text-lg font-bold">2</span>
          </div>
        </div>
      </div>
    </div>
    <div class="-mt-14 bg-white text-center">REPORT OF ACREAGE</div>
    <div class="bg-white text-left text-xs italic">
      See Page 2 for Privacy Act and Paperwork Reduction Act Statements
    </div>
  </div>
  <!-- SECTION 1 PRODUCER DETAILS -->
  <div class="flex">
    <div>
      <table>
        <thead class="bg-white text-center">
          <tr>
            <td class="">1. <br />FARM NO.</td>
            <td class="">2. <br />FARMLAND</td>
            <td class="">3. <br />CROPLAND</td>
            <td class="">4. <br />PROGRAM YR.</td>
          </tr>
        </thead>
        <tbody class="bg-blue-100">
          <tr>
            <td class="">
              <input type="text" class="w-36" />
            </td>
            <td>
              <input type="text" class="w-36" />
            </td>
            <td>
              <input type="text" class="w-36" />
            </td>
            <td>
              <input type="text" class="w-36" />
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead class="bg-white text-center">
          <tr>
            <td>KEY</td>
            <td>5. OPERATOR NAME AND ADDRESS</td>
            <td>6. OTHER FARMS</td>
          </tr>
        </thead>
        <tbody class="bg-blue-100">
          <tr>
            <td class="border bg-white px-4 text-4xl">1</td>
            <td>
              <textarea class="w-96" rows="3" />
            </td>
            <td>
              <textarea class="w-36" rows="3" />
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead class="bg-white text-left">
          <tr>
            <td>11. PHOTO NO. - LEGAL DESCRIPTION</td>
          </tr>
        </thead>
        <tbody class="bg-blue-100">
          <tr>
            <td>
              See Tract Map on following page <br />
              <textarea class="w-[580px]" rows="2" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <table class="">
      <thead class="bg-white text-center">
        <tr>
          <td>
            7. <br />
            KEY
          </td>
          <td>8. <br />NAMES OF OTHER PRODUCERS</td>
          <td>
            9. <br />
            ID NUMBER
          </td>
          <td>
            10. <br />
            OTHER FARMS
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="producer in 6" :key="producer">
          <td class="w-12">
            <input type="text" class="producer-input w-12" />
          </td>
          <td>
            <input type="text" class="producer-input w-72" />
          </td>
          <td>
            <input type="text" class="producer-input w-36" />
          </td>
          <td>
            <input type="text" class="producer-input w-36" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <table>
    <thead class="bg-white">
      <tr>
        <th class="w-6">12.</th>
        <th class="w-6">13.</th>
        <th class="">14.</th>
        <th class="w-6">15.</th>
        <th class="w-6">16.</th>
        <th class="w-6" colspan="10">
          17. CROP OR LAND USE SUMMARY (Maple trees, after number enter "T";
          Honey, after number enter "H")
        </th>
        <th class="w-6">18.</th>
        <th class="w-6">19.</th>
      </tr>
      <tr>
        <th class="">TRACT NO.</th>
        <th class="">FIELD NO.</th>
        <th class="">CROP OR LAND USE</th>
        <th class="">PRACTICE</th>
        <th class="">CROP STATUS</th>
        <th class="crop-cell">{{ cropRows[0] }}</th>
        <th class="crop-cell">{{ cropRows[1] }}</th>
        <th class="crop-cell">{{ cropRows[2] }}</th>
        <th class="crop-cell">{{ cropRows[3] }}</th>
        <th class="crop-cell">{{ cropRows[4] }}</th>
        <th class="crop-cell">{{ cropRows[5] }}</th>
        <th class="crop-cell">{{ cropRows[6] }}</th>
        <th class="crop-cell">{{ cropRows[7] }}</th>
        <th class="crop-cell">{{ cropRows[8] }}</th>
        <th class="crop-cell">{{ cropRows[9] }}</th>
        <th class="crop-cell">KEY</th>
        <th class="crop-cell">SHARE</th>
      </tr>
    </thead>
    <tbody class="bg-blue-100">
      <template v-for="(field, fieldNdx) in derivedFields" :key="field.id">
        <tr v-for="idx in 2" :key="idx" class="font-serif">
          <!-- index is{{
            idx
          }} -->
          <td class="w-12 border" rowspan="2" v-if="idx == 1">
            {{ field?.properties?.farm }}
          </td>
          <td class="w-12 border" rowspan="2" v-if="idx == 1">
            {{ fieldNdx + 1 }}
          </td>

          <td class="w-48 border" rowspan="2" v-if="idx == 1">
            <!-- {{ fieldNdx }} -->
            {{ field?.properties?.crop }} -
            {{ field?.properties?.details?.variety }}
          </td>
          <td class="border" rowspan="2" v-if="idx == 1">
            {{ field?.properties?.details?.irrigated ? "I" : "N" }}
          </td>
          <td class="border" rowspan="2" v-if="idx == 1">
            {{ field?.properties?.details?.planting_status }}
          </td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 0) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 1) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 2) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 3) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 4) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 5) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 6) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 7) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 8) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td class="crop-cell" v-if="idx == 1">
            {{ getAcres(fieldNdx, 9) }}
          </td>
          <td v-else><input type="text" class="w-12" /></td>
          <td v-if="idx == 1" rowspan="2">
            <input type="text" class="w-12" />
          </td>
          <td v-if="idx == 1" rowspan="2">
            <input type="text" class="w-12" />
          </td>
        </tr>
      </template>
      <!-- operator totals-->
      <tr>
        <td colspan="5" class="bg-white">20. TOTAL OPERATOR REPORT</td>
        <td class="crop-cell">{{ getTotalAcres(0) }}</td>
        <td class="crop-cell">{{ getTotalAcres(1) }}</td>
        <td class="crop-cell">{{ getTotalAcres(2) }}</td>
        <td class="crop-cell">{{ getTotalAcres(3) }}</td>
        <td class="crop-cell">{{ getTotalAcres(4) }}</td>
        <td class="crop-cell">{{ getTotalAcres(5) }}</td>
        <td class="crop-cell">{{ getTotalAcres(6) }}</td>
        <td class="crop-cell">{{ getTotalAcres(7) }}</td>
        <td class="crop-cell">{{ getTotalAcres(8) }}</td>
        <td class="crop-cell">{{ getTotalAcres(9) }}</td>
        <td class="w-12 bg-black"></td>
        <td class="w-12 bg-black"></td>
      </tr>
      <!-- determined totals-->
      <tr>
        <td colspan="5" class="bg-white">
          21. TOTAL DETERMINED ACREAGE REPORT
        </td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="crop-cell"><input type="text" class="w-12" /></td>
        <td class="w-12 bg-black"></td>
        <td class="w-12 bg-black"></td>
      </tr>
    </tbody>
  </table>
  <div class="flex">
    <div class="w-10/12 border text-xs">
      22. CERTIFICATION -
      <span class="italic" style="font-size: 10px">
        I certify to the best of my knowledge and belief that the acreage of
        crops/commodities and land uses listed herein are true and correct and
        that all required crops/commodities and land uses have been reported for
        the farms as applicable. Absent any different or contrary prior
        subsequent certification filed by any producer for any crop for which
        NAP coverage has been purchased, I certify that the applicable crop,
        type, practice, and intended use is not planted if it is not included on
        the Report of Commodities for this crop year. The signing of this form
        gives FSA representatives authorization to enter and inspect
        crops/commodities and land uses on the above identified land. A
        signature date (the date the producer signs the FSA-578) will also be
        captured.</span
      >
    </div>
    <div class="border text-xs">
      1/ I = Irrigated N = Nonirrigated <br />
      O = Other (Honey or Maple Sap)
    </div>
  </div>
  <div class="flex">
    <div class="w-48 border text-xs">A. CERTIFIERS SIGNATURE</div>
    <div class="w-24 border text-xs">
      B. DATE
      <br /><span style="font-size: 10px">(MM/DD/YYYY)</span>
      <input type="text" class="w-20" />
    </div>
    <div class="w-48 border text-xs">A. CERTIFIERS SIGNATURE (BY)</div>
    <div class="w-24 border text-xs">
      B. DATE
      <br /><span style="font-size: 10px">(MM/DD/YYYY)</span>
      <input type="text" class="w-20" />
    </div>
    <div class="w-48 border text-xs">A. CERTIFIERS SIGNATURE (BY)</div>
    <div class="w-24 border text-xs">
      B. DATE
      <br /><span style="font-size: 10px">(MM/DD/YYYY)</span>
      <input type="text" class="w-20" />
    </div>
    <div class="grid w-64 grid-cols-2 text-xs">
      <div>
        2/ I = Initial<br />&nbsp;&nbsp; P = Prevented<br />&nbsp;&nbsp; F =
        Failed <br />&nbsp;&nbsp; S = Subsequent Crop<br />&nbsp;&nbsp; D =
        Double Crop <br />&nbsp;&nbsp; R = Repeat <br />&nbsp;&nbsp; V =
        Volunteer
      </div>
      <div>
        E = Experimental <br />IF = Initial Failed <br />IP = Initial Prevented
        <br />SF = Subsequent Failed <br />DF = Double-cropped Failed <br />DP =
        Double-cropped Prevented
      </div>
    </div>
  </div>
  <div class="print:break-before-page">
    Tract Map:
    <fields-map
      :layers="layers"
      :showLayerSelector="false"
      :showTurnByTurn="false"
      :showGoToFeature="false"
      :showLabels="true"
      height="h-screen-3/4"
    />
  </div>
</template>

<script>
import { filter, flattenObj } from "@/components/composables/scriptUtils.js";
import FieldsMap from "@/components/map/MapViewer.vue";
import _ from "lodash";

export default {
  props: {
    filter: { type: Object, default: null },
  },
  components: {
    FieldsMap,
  },
  data() {
    return {
      producerRows: 6,
      searchValue: "",
      filterObject: {},
      pageFixedFilters: {},
      pageSorts: { "properties.farm": "asc", "properties.name": "asc" },
    };
  },
  computed: {
    cropRows() {
      let crops = [];
      // console.log("computing crops. derivedFields", this.derivedFields);
      for (let i = 0; i < this.derivedFields.length; i++) {
        let crop = this.derivedFields[i].properties.crop;
        // console.log("crop", crop);
        if (!crops.includes(crop)) crops.push(crop);
      }
      // console.log("crops", crops);
      return crops;
    },
    totalAcres() {
      return this.derivedFields.reduce((acc, field) => {
        return acc + field.properties.acres;
      }, 0);
    },
    defaultFilter() {
      return this.$store.getters.getFieldsFilter;
    },
    combinedFilters() {
      return {
        ...this.filterObject,
        ...this.pageFixedFilters,
      };
    },
    derivedFields() {
      let derivedFields = this.$store.state.fields.fields;
      let flatCollection = [];
      for (let i = 0; i < derivedFields.length; i++) {
        flatCollection.push(flattenObj(derivedFields[i], true));
      }

      let newCollection = filter(flatCollection, this.defaultFilter);
      newCollection = _.isEmpty(this.searchValue)
        ? newCollection
        : _.filter(newCollection, (item) =>
            _.some(item, (val) =>
              _.includes(_.lowerCase(val), _.lowerCase(this.searchValue)),
            ),
          );
      derivedFields = newCollection;
      derivedFields = _.orderBy(
        derivedFields,
        Object.keys(this.pageSorts),
        Object.values(this.pageSorts),
      );

      return derivedFields;
    },
    layers() {
      let layers = [];

      let fields = this.derivedFields;
      let fieldJSON = {
        type: "FeatureCollection",
        features: fields.map((field, index) => ({
          type: "Feature",
          id: field.id,
          geometry: field.geometry,
          properties: {
            name: (index + 1).toString(),
            link: "/app/fields/" + field.id,
          },
        })),
      };
      let fieldLayer = {
        name: "Fields",
        geojson: fieldJSON,
        key: null,
        //style:{fillColor:"red", opacity: 1, weight: 1, color: "green",}
      };
      //console.log("fields layer", fieldLayer);
      layers.push(fieldLayer);
      return layers; // [{"name": "tractor","geojson":this.geojson}];
    },
  },
  methods: {
    submitForm() {
      // Handle form submission here
      console.log(this.farmerName, this.farmNumber, this.cropType);
    },
    printReport() {
      var tempTitle = document.title;
      document.title = "fsa578.pdf";
      window.print();
      document.title = tempTitle;
    },
    getAcres(row, col) {
      let acres = " ";
      if (this.derivedFields[row]?.properties?.crop == this.cropRows[col])
        acres = this.derivedFields[row]?.properties?.acres;
      // console.log(
      //   "row",
      //   row,
      //   "col",
      //   col,
      //   "crop",

      //   this.cropRows[col],
      //   this.derivedFields[row]?.properties?.crop,
      //   "acres",
      //   acres,
      // );
      acres = Math.round(acres * 100) / 100;
      if (acres == 0) acres = " "; // show blank if 0
      return acres;
    },
    getTotalAcres(col) {
      let total = 0;
      for (let i = 0; i < this.derivedFields.length; i++) {
        if (this.derivedFields[i]?.properties?.crop == this.cropRows[col])
          total += this.derivedFields[i]?.properties?.acres;
      }
      total = Math.round(total * 100) / 100;
      return total;
    },
  },
};
</script>

<style lang="postcss" scoped>
/* Add your styles here */
table {
  @apply table-auto border-collapse text-xs;
}
.crop-cell {
  @apply w-20 border border-gray-500 bg-blue-100;
}
input,
textarea {
  @apply border-none bg-blue-100 text-xs;
}
input {
  @apply h-5 text-xs;
}
.producer-input {
  @apply h-7;
}
td,
th {
  @apply border border-gray-500;
}
</style>
