<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'category', label: 'Category' },
          { name: 'instructions', label: 'Recommendation' },
          { name: 'fieldOrEquipment', label: 'On' },
          { name: 'source.name', label: 'Assigned By' },
          { name: 'updated', label: 'Updated', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="tasks"
        @update:derived-collection="derivedTasks = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewTaskPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="task in derivedTasks" :key="task.id">
        <table-data td-label="Category">
          {{ task.category }}
        </table-data>
        <table-data td-label="Recommendation">
          {{ task.instructions }}
        </table-data>
        <table-data td-label="On">
          {{ task.fieldOrEquipment }}
        </table-data>
        <table-data td-label="Assigned By">
          {{ task.source?.name }}
        </table-data>
        <table-data td-label="Created">
          {{ new Date(task.updated).toLocaleString("en", dateFormat) }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'single-activity',
              params: { activityId: task.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  data() {
    return {
      derivedTasks: [],
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
      },
    };
  },
  computed: {
    tasks() {
      let derivedTasks = this.$store.getters.getDerivedTasks({
        filter: {
          userid: this.$store.getters.getCurrentUser.id,
        }, // {category: "Irrigation"} - return elements where category is exactly "Irrigation"
        match: this.searchValue, // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort: this.pageSorts,
      });
      derivedTasks = derivedTasks.map((task) => {
        if (task.assigned_org) {
          // console.log(
          //   "task.assigned_org",
          //   task.assigned_org,
          //   this.$store.getters.getOrgById(task.assigned_org),
          //   this.$store.state.vendors.vendors
          // );
          task.user.name = this.$store.getters.getOrgById(
            task.assigned_org,
          )?.name;
        }

        return task;
      });
      derivedTasks = derivedTasks.filter((task) => {
        var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        console.log(
          task.category,
          task.status,
          task.instructions,
          "yesterday",
          yesterday,
          task.updated,
          new Date(task.updated),
          new Date(task.updated) > yesterday,
        );
        return (
          task.status === "Assigned" ||
          (task.status === "Complete" && new Date(task.updated) > yesterday)
        );
      });
      return derivedTasks;
    },
    tasksFromState() {
      return this.$store.state.tasks.tasks;
    },
    users() {
      return this.$store.state.users.users;
    },
    fields() {
      return this.$store.state.fields.fields;
    },
  },
  methods: {
    complete(taskId) {
      console.log("complete");
      let task = this.$store.getters.getTaskById(taskId);
      task.status != "Complete"
        ? (task.status = "Complete")
        : (task.status = "Assigned");
      this.$store.dispatch("updateTask", {
        taskId: taskId,
        task: task,
      });
    },
    goToNewTaskPage() {
      // console.log("go to new activity");
      this.$router.push({ name: "new-activity" });
    },
    userNameById(userId) {
      if (userId != null) {
        return this.$store.getters.getUserById(userId).name;
      } else {
        return "";
      }
    },
    equipmentAliasById(equipmentId) {
      if (equipmentId != null) {
        return this.$store.getters.getEquipmentById(equipmentId).alias;
      } else {
        return "";
      }
    },
    fieldById(fieldId) {
      if (fieldId != null) {
        return this.$store.getters.getFieldById(fieldId)?.properties?.name;
      } else {
        return "";
      }
    },
  },
};
</script>
