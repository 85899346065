<template>
  <template v-if="!required || firstParty">
    <slot></slot>
  </template>
</template>

<script>
export default {
  props: {
    required: { type: Boolean, default: true },
  },
  data() {
    return { firstParty: this.$store.getters.currentOrgIsFirstParty };
  },
};
</script>
