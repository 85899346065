<template>Redirecting to Stripe...</template>

<script>
import client from "@/api/parcel/api.js";

export default {
  data() {
    return {};
  },
  methods: {
    async navigateStripe() {
      const { refresh } = this.$route.query;
      console.log("REFRESH", refresh && { refresh: 1 });
      const { data } = await client.stripe.setup(
        null,
        refresh && { refresh: 1 },
      );
      console.log(data);
      window.location = data.url;
    },
  },
  mounted() {
    this.navigateStripe();
  },
};
</script>
