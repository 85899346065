<template>
  <div class="text-gray-800" v-if="talkingWith">{{ talkingWith?.name }}</div>
  <chat-window
    @add-room="showNewThreadModal = true"
    :room-id="selectedRoom"
    :rooms="formattedRooms"
    :messages="formattedMessages"
    :current-user-id="currentUserId"
    :loading-rooms="false"
    :rooms-loaded="true"
    :messages-loaded="true"
    @send-message="sendMessage"
    @fetch-messages="(e) => $emit('fetch-messages', e)"
    :show-audio="false"
    :show-files="false"
    :show-reaction-emojis="false"
    :username-options="{ minUsers: 1, currentUser: false }"
    :message-actions="[]"
    :menu-actions="[]"
    :rooms-list-opened="showRooms"
    :load-first-room="false"
  />
  <chat-thread-layout :open="showNewThreadModal" @subject="addThread" />
</template>

<script>
import ChatThreadLayout from "./ChatThreadLayout.vue";
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import moment from "moment";
import { io } from "socket.io-client";

export default {
  props: {
    threadId: String,
    isOpen: { type: Boolean, default: true },
  },
  emits: ["fetch-messages"],
  components: {
    ChatWindow,
    ChatThreadLayout,
  },
  data() {
    return {
      users: this.$store.state.messages.users,
      currentUser: this.$store.getters.getCurrentUser,
      currentUserId: this.$store.getters.getCurrentUser.id,
      currentComment: "",
      shouldNavigate: false,
      showNewThreadModal: false,
      showRooms: true,
    };
  },
  computed: {
    selectedRoom() {
      if (this.threadId) {
        var result = this.formattedRooms.find((obj) => {
          return obj.roomId.toString() === this.threadId;
        });
        if (result) {
          return result.roomId;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    talkingWith() {
      if (this.currentThread) {
        if (this.currentThread.org != this.currentUser.org) {
          return this.$store.getters.getOrgById(this.currentThread.org);
        } else {
          return this.$store.getters.getOrgById(this.currentThread.partner);
        }
      }
      return null;
    },
    currentThread() {
      if (!this.threadId) {
        return null;
      }
      let thread = this.threadsFromState.find(
        (thread) => thread.id == this.threadId,
      );
      return thread;
    },
    threadsFromState() {
      return this.$store.getters.getDerivedThreads({
        sort: { latest_sent: "desc" },
      });
    },
    formattedRooms() {
      let result = [];
      this.threadsFromState.forEach((thread) => {
        result.push({
          roomId: thread.id,
          roomName: thread.subject,
          lastMessage: thread.latest
            ? {
                content: thread.latest.content,
                senderId: thread.latest.user,
                timestamp: moment(thread.latest.sent).fromNow(),
              }
            : undefined,
          users: this.$store.state.messages.users.map((user) => {
            return {
              _id: user.id,
              username: user.name || "Unknown",
            };
          }),
        });
      });
      return result;
    },
    formattedMessages() {
      let result = [];
      if (this.threadId) {
        this.$store.getters
          .getDerivedMessages({
            filter: { thread: Number(this.threadId) },
            sort: { sent: "asc" },
          })
          .forEach((message, index) => {
            result.push({
              _id: message.id,
              indexId: index,
              content: message.content,
              date: moment(message.sent).format("MMMM Do, YYYY"),
              timestamp: moment(message.sent).format("h:mm A"),
              senderId: message.user,
              username: this.$store.state.messages.users.filter(
                (user) => user.id == message.user,
              )[0]?.name,
            });
          });
      }
      return result;
    },
  },
  methods: {
    sendMessage(e) {
      let message = {
        content: e.content,
      };
      this.$store.dispatch("createMessage", {
        threadId: this.threadId,
        message,
      });
    },
    async addThread(subject) {
      this.showNewThreadModal = false;
      if (subject) {
        let newThread = {
          subject: subject,
          task: null,
          partner: null,
        };
        await this.$store.dispatch("createThread", newThread);
        await this.$store.dispatch("getThreads");
        this.refreshKey = !this.refreshKey;
      }
    },
  },
  mounted() {
    // this is a hack due to a bug in vue-advanced-chat, which ignores the prop unless it changes after mount
    if (this.isOpen != this.showRooms) {
      this.showRooms = this.isOpen;
    }

    const socket = io(process.env.VUE_APP_API_URL, {
      path: "/stream/socket.io",
      withCredentials: true,
    });

    socket.on("connect", () => {
      console.log("STREAM CONNECTED");
    });

    socket.on("connect_error", (message) => {
      console.log("STREAM ERROR", message);
    });

    socket.on("new-message", (message) => {
      if (message.user !== this.currentUserId) {
        this.$store.commit("ADD_MESSAGE", message);
      }
    });

    this.socket = socket;
  },
  unmounted() {
    this.socket.close();
  },
};
</script>
