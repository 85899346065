export function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  let monday = new Date(d.setDate(diff));
  monday.setHours(0, 0, 0, 0);
  return monday;
}

export function getDayofMonth(date, offset) {
  let d = new Date(date);
  d.setDate(d.getDate() + offset);
  return d.getDate();
}

// getUTCString(new Date(this.date + " " + this.time)
export function getUTCString(date) {
  var utc = new Date(date);
  utc.setMinutes(utc.getMinutes() - utc.getTimezoneOffset());

  // console.log(new Date(utc), new Date(utc).toISOString());
  return new Date(utc).toISOString();
}

export function getDateString(date) {
  var d = new Date(date);
  return d.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
