import client from "@/api/parcel/api.js";
import _ from "lodash";

let unblock;
let blockUntilLoaded;
function setPromise() {
  blockUntilLoaded = new Promise((resolve) => {
    unblock = resolve;
  });
}
setPromise();

export default {
  state() {
    return {
      orgs: [],
      org_profile: {},
    };
  },
  mutations: {
    SET_ORGS(state, orgData) {
      state.orgs = orgData;
    },
    SET_ORG_PROFILE(state, orgData) {
      state.org_profile = orgData;
    },
    ADD_ORG(state, orgObject) {
      state.orgs.push(orgObject);
    },
    UPDATE_ORG(state, orgObject) {
      state.orgs = state.orgs.filter(function (obj) {
        return obj.id !== orgObject.id;
      });
      state.orgs.push(orgObject);
    },
    RESET_ORGS(state) {
      state.orgs = [];
      state.org_profile = {};
    },
    RESET_ORGS_PROMISE() {
      setPromise();
    },
  },
  actions: {
    async getOrgs({ commit }) {
      const { data, errors } = await client.orgs.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_ORGS", data.orgs);
        unblock();
      }
    },
    async createOrg({ commit, dispatch }, org) {
      const { data, errors } = await client.orgs.create(null, org);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        console.log(data);
        commit("ADD_ORG", org);
        commit("RESET_USER_PROMISE");
        this.dispatch("getLoginStatus");
        await this.dispatch("getOrgs");
      }
    },
    async updateOrg({ commit, dispatch }, org) {
      const { data, errors } = await client.orgs.update(null, org);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_ORG", data);
        this.dispatch("getOrgs");
      }
    },
    async uploadOrgLogo({ dispatch }, orgLogo) {
      console.log(orgLogo);

      const { data, errors } = await client.orgs.createLogo(null, orgLogo);
      console.log(data, errors);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        return data;
        // await this.dispatch("getOrgProfile");
      }
    },
    // Org Profile
    async getOrgProfile({ commit }) {
      const { data, errors } = await client.orgs.readProfile();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_ORG_PROFILE", data);
      }
    },
    async createOrgProfile({ commit, dispatch }, orgProfile) {
      const { data, errors } = await client.orgs.createProfile(
        null,
        orgProfile,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        // console.log(data);
        commit("SET_ORG_PROFILE", orgProfile);
        await this.dispatch("getOrgProfile");
      }
    },
    async uploadOrgProfileLogo({ dispatch }, orgProfileLogo) {
      console.log(orgProfileLogo);

      const { data, errors } = await client.orgs.createProfileLogo(
        null,
        orgProfileLogo,
      );
      console.log(data, errors);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        return data;
        // await this.dispatch("getOrgProfile");
      }
    },
    async uploadOrgProfileBanner({ dispatch }, orgProfileBanner) {
      const { data, errors } = await client.orgs.createProfileBanner(
        null,
        orgProfileBanner,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        return data;
        // await this.dispatch("getOrgProfile");
      }
    },
    async uploadOrgProfileResourceImage(
      { dispatch },
      orgProfileResourceImageObject,
    ) {
      let image = orgProfileResourceImageObject.image;
      let index = orgProfileResourceImageObject.index;
      console.log("ImageObject", orgProfileResourceImageObject, index, image);
      //console.log("index", imageIndex);

      const { data, errors } = await client.orgs.createResourceImage(
        index,
        image,
      );
      if (errors) {
        console.log("error uploading resource image", errors);
        dispatch("catchParcelApiError", errors);
      } else {
        return data;
        // await this.dispatch("getOrgProfile");
      }
    },
    async deleteOrgProfileResourceImage({ dispatch }, { index }) {
      const { errors } = await client.orgs.deleteResourceImage(index);
      if (errors) {
        console.log("error deleting resource image", errors);
        dispatch("catchParcelApiError", errors);
      }
    },
    async getCurrentOrgBlocking({ getters }) {
      await blockUntilLoaded;
      return getters.getCurrentOrg;
    },
  },
  getters: {
    getCurrentOrg: (state) => {
      return state.orgs.find((org) => org.self === true);
    },
    getCurrentOrgType: (state) => {
      let currentOrg = state.orgs.find((org) => org.self === true);
      if (currentOrg)
        if (currentOrg.purchases_flag) {
          return "buyer";
        } else if (currentOrg.revenue_flag) {
          return "vendor";
        } else {
          return "grower";
        }
      else return "";
    },
    getOrgById: (state) => (orgId) => {
      return state.orgs.find((org) => org.id === parseInt(orgId));
    },
    getDerivedOrgs:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedOrgs = state.orgs;
        let filteredOrgs = _.isEmpty(filter)
          ? expandedOrgs
          : _.filter(expandedOrgs, filter);
        let matchedOrgs = _.isEmpty(match)
          ? filteredOrgs
          : _.filter(filteredOrgs, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedOrgs = _.isEmpty(sort)
          ? matchedOrgs
          : _.orderBy(matchedOrgs, sortColumns, sortOrders);
        let paginatedOrgs =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedOrgs,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedOrgs;
        return paginatedOrgs;
      },
  },
};
