<template>
  <page-card headingTitle="New Vendor">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="vendorFormId"
      />
    </template>
    <template #cardDetails>
      <form
        @submit.prevent="createVendor"
        :id="vendorFormId"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <text-input
          :wideItem="true"
          inputTitle="Name"
          :required="true"
          inputHtmlType="text"
          v-model="currentVendor.placeholder_name"
        />
        <email-input
          :wideItem="true"
          inputTitle="Contact Email"
          inputHtmlType="text"
          v-model="currentVendor.recipient_email"
        />
        <checkbox-input
          :wideItem="true"
          inputTitle="Share details with buyer"
          inputHtmlType="text"
          v-model="currentVendor.seller_confirmed"
          class="pt-6"
        />
      </form>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";

export default {
  components: {
    TextInput,
    CheckboxInput,
    EmailInput,
    PageCard,
    SubmitButton,
  },
  data() {
    return {
      vendorFormId: "new-vendor-form",
      currentVendor: null,
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  methods: {
    async createVendor() {
      await this.$store.dispatch("createBuyerCropVendor", this.currentVendor);
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "buyer-vendors" });
      }
    },
    clearForm() {
      this.currentVendor = {
        placeholder_name: null, // "string",
        recipient_email: null, // "user@example.com"
      };
    },
  },
  watch: {
    vendorId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          console.log("vendorId", value);
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
