import client from "@/api/parcel/api.js";
import magic from "@/api/magic.js";

let unblock;
let blockUntilLoaded;
function setPromise() {
  blockUntilLoaded = new Promise((resolve) => {
    unblock = resolve;
  });
}
setPromise();

export default {
  state() {
    return {
      user: null,
    };
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      unblock();
    },
    CLEAR_USER_DATA(state) {
      state.user = null;
    },
    RESET_USER_PROMISE() {
      setPromise();
    },
  },
  actions: {
    async loginViaEmail({ dispatch }, email) {
      const token = await magic.getEmailToken(email);
      await dispatch("completeLogin", token);
    },
    async loginViaPhone({ dispatch }, phone) {
      const token = await magic.getSMSToken(phone);
      await dispatch("completeLogin", token);
    },
    async loginSwitchContext({ commit, dispatch }, org) {
      dispatch("clearAppData");
      const { data } = await client.auth.switch(null, org);
      commit("SET_USER_DATA", data);
      commit("RESET_ORGS_PROMISE");
      dispatch("preloadAppData");
    },
    async logout({ dispatch }) {
      await client.auth.logout();
      dispatch("clearAppData");
    },
    async completeLogin({ commit, dispatch }, token) {
      const { data } = await client.auth.login(null, { token });
      commit("SET_USER_DATA", data);
      commit("RESET_ORGS_PROMISE");
      dispatch("preloadAppData");
    },
    async getLoginStatus({ commit, dispatch }) {
      const { data } = await client.auth.check();
      if (data.user) {
        commit("SET_USER_DATA", data);
      } else {
        dispatch("clearAppData");
        unblock();
      }
      return data.user;
    },
    async getUserDataBlocking({ getters }) {
      await blockUntilLoaded;
      return getters.getUserData;
    },
  },
  getters: {
    getUserData: (state) => {
      return state.user;
    },
    getUserAdminStatus(state) {
      return state.user?.admin;
    },
  },
};
