import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      tasks: [],
      filterObject: {},
    };
  },
  mutations: {
    SET_TASKS(state, taskData) {
      state.tasks = taskData;
    },
    ADD_TASK(state, taskObject) {
      state.tasks.push(taskObject);
    },
    UPDATE_TASK(state, taskObject) {
      state.tasks = state.tasks.filter(function (obj) {
        return obj.id !== taskObject.id;
      });
      state.tasks.push(taskObject);
    },
    DELETE_TASK(state, taskObject) {
      state.tasks = state.tasks.filter(function (obj) {
        return obj.id !== taskObject.id;
      });
    },
    RESET_TASKS(state) {
      state.tasks = [];
      state.filterObject = {};
    },
  },
  actions: {
    async getTasks({ commit }) {
      const { data, errors } = await client.tasks.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_TASKS", data.tasks);
      }
    },
    async createTask({ commit, dispatch }, task) {
      const { data, errors } = await client.tasks.create(null, task);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("ADD_TASK", data);
        this.dispatch("getTasks");
      }
    },
    // Object payload
    async updateTask({ commit, dispatch }, { taskId, task }) {
      // console.log("TASK IN STORE", task);
      const { data, errors } = await client.tasks.update(taskId, task);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_TASK", data);
        this.dispatch("getTasks");
      }
    },
    async deleteTask({ commit, dispatch }, task) {
      const { data, errors } = await client.tasks.delete(task.id);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        // console.log(data);
        commit("DELETE_TASK", task);
        this.dispatch("getTasks");
      }
    },
    setTasksFilter({ commit }, filterObject) {
      commit("SET_FILTER", filterObject);
    },
  },
  getters: {
    getTaskById: (state) => (taskId) => {
      return state.tasks.find((task) => task.id === parseInt(taskId));
    },
    getTasksByField: (state) => (fieldId) => {
      return state.tasks.filter((task) => task.field == fieldId);
    },
    getTasksByEquipment: (state) => (equipmentId) => {
      return state.tasks.filter((task) => task.equipment == equipmentId);
    },
    getRecommendations: (state) => () => {
      return state.tasks.filter((task) => task.status === "Recommended");
    },
    getActivities: (state) => () => {
      return state.tasks.filter((task) => task.status !== "Recommended");
    },
    getAllActivities(state) {
      return state.tasks;
    },
    getDerivedTasks:
      (state, getters) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        // add the user and source user objects to the task so we can search / filter on them.
        let expandedTasks = state.tasks.map((task) => {
          let user = getters.getUserById(task.user) || {};
          let source = getters.getUserById(task.source) || {};
          if (task.source_org !== null && task.source_org !== task.org) {
            let source_org = getters.getOrgById(task.source_org);
            let my_org = getters.getCurrentOrg;
            if (source_org && source.org != my_org.id) {
              source = { name: source_org.name, email: source_org.email };
            }
          }

          let field = getters.getFieldById(task.field) || {};
          let equipment = getters.getEquipmentById(task.equipment) || {};

          let converted_user = Object.entries(user).reduce(
            (obj, [key, val]) => {
              obj[`user.${key}`] = val;
              return obj;
            },
            {},
          );

          let converted_source = Object.entries(source).reduce(
            (obj, [key, val]) => {
              obj[`source.${key}`] = val;
              return obj;
            },
            {},
          );

          let converted_field = Object.entries(field?.properties || {}).reduce(
            (obj, [key, val]) => {
              obj[`field.${key}`] = val;
              return obj;
            },
            {},
          );

          let converted_equipment = Object.entries(equipment).reduce(
            (obj, [key, val]) => {
              obj[`equipment.${key}`] = val;
              return obj;
            },
            {},
          );

          return {
            fieldOrEquipment: field?.properties?.name || equipment?.alias,
            ...task,
            user,
            userid: user.id,
            ...converted_user,
            source,
            ...converted_source,
            field,
            ...converted_field,
            equipment,
            ...converted_equipment,
          };
        });

        // This section checks if a filter or set of filters exists. If it does, it loops through and checks if any have nested objects to filter,
        // like "user.name". If it does, it substitutes the "user.name:value" key for an actual object {user{name:value}}
        // NOTE: only currently used in tasks, if it becomes generally needed, we can move to TableHeaderCellWithFilterButton to make it take effect
        // globally.
        if (
          filter &&
          Object.keys(filter).length > 0 &&
          Object.getPrototypeOf(filter) === Object.prototype
        ) {
          //console.log("break up", filter);
          let newFilter = {};
          // loop through each key in the filter to see if we need to parse it.
          for (let key in filter) {
            //console.log("filterClause", `${key}:${filter[key]}`);
            if (key && key.split(".").length > 1) {
              var schema = newFilter; // a moving reference to internal objects within obj
              var pList = key.split(".");
              var len = pList.length;

              for (var i = 0; i < len - 1; i++) {
                var elem = pList[i];
                if (!schema[elem]) schema[elem] = {};
                schema = schema[elem];
              }
              schema[pList[len - 1]] = filter[key];
              //console.log("cleaned",newFilter);
            } else {
              // Not one we need to break up, just copy it to our new object.
              newFilter[key] = filter[key];
            }
          }
          filter = newFilter;
        }
        //console.log("filter", filter);

        let filteredTasks = _.isEmpty(filter)
          ? expandedTasks
          : _.filter(expandedTasks, filter);
        let matchedTasks = _.isEmpty(match)
          ? filteredTasks
          : _.filter(filteredTasks, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedTasks = _.isEmpty(sort)
          ? matchedTasks
          : _.orderBy(matchedTasks, sortColumns, sortOrders);
        let paginatedTasks =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedTasks,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedTasks;
        return paginatedTasks;
      },
    getTasksFilter(state) {
      if (state.filterObject == null) {
        return {};
      }
      return state.filterObject;
    },
  },
};
