<template>
  <div class="flex justify-center pt-10">
    <ExclamationCircleIcon class="h-32 w-32 text-red-600" />
  </div>
  <div class="flex justify-center">
    <h1 class="text-3xl">Oops! Something went wrong.</h1>
  </div>

  <div class="mt-8 flex justify-center">
    {{ error }}
  </div>
  <a
    href="/"
    class="mt-10 flex justify-center text-blue-600 hover:text-blue-800"
    >Click here to try to return to the home page</a
  >
  <div class="mt-10 flex justify-center">OR</div>
  <div>
    <a
      @click="showContactForm = true"
      class="mt-10 flex justify-center text-green-600 hover:bg-gray-100 hover:text-green-800"
    >
      Contact Us for Help
    </a>
  </div>
  <div class="flex justify-center">
    <img src="/img/pictures/spill.jpg" alt="spill" />
  </div>
  <!-- Contact Us Form -->
  <Dialog
    :open="showContactForm"
    @close="showContactForm = false"
    class="absolute z-50 -mt-8 border"
  >
    <!-- The backdrop, rendered as a fixed sibling to the panel container -->
    <div class="fixed inset-0 bg-black/30" aria-hidden="true" />

    <!-- Full-screen container to center the panel -->
    <div class="fixed inset-0 flex items-center justify-center p-4">
      <DialogPanel>
        <contact-form
          blurb="Have a problem, or a suggestion for the product? Please let us know!"
          routeName="_SELF"
          @close="showContactForm = false"
          :message_text="
            'The application reported an error: ' +
            error +
            ', can you help me resolve this?'
          "
        />
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/outline";
import { Dialog, DialogPanel } from "@headlessui/vue";
import ContactForm from "@/layouts/static/ContactForm.vue";
export default {
  components: {
    ExclamationCircleIcon,
    Dialog,
    DialogPanel,
    ContactForm,
  },

  name: "ErrorPage",
  data() {
    return {
      showContactForm: false,
    };
  },
  computed: {
    error() {
      console.log(this.$route);
      return this.$route.query.error;
    },
  },
};
</script>
