import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      users: [],
    };
  },
  mutations: {
    SET_USERS(state, usersData) {
      state.users = usersData;
    },
    ADD_USER(state, userObject) {
      state.users.push(userObject);
    },
    UPDATE_USER(state, userObject) {
      state.users = state.users.filter(function (obj) {
        return obj.id !== userObject.id;
      });
      state.users.push(userObject);
    },
    DELETE_USER(state, userObject) {
      state.users = state.users.filter(function (obj) {
        return obj.id !== userObject.id;
      });
    },
    RESET_USERS(state) {
      state.users = [];
    },
  },
  actions: {
    async getUsers({ commit }) {
      const { data, errors } = await client.users.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_USERS", data.users);
      }
    },
    async createUser({ commit, dispatch }, user) {
      if (user.phone == "") user.phone = null;
      if (user.email == "") user.email = null;
      const { data, errors } = await client.users.create(null, user);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
        return null;
      } else {
        // console.log("createUser worked", data);
        commit("ADD_USER", data);
        this.dispatch("getUsers");
        return data;
      }
    },
    async updateUser({ commit, dispatch }, user) {
      if (user.phone == "") user.phone = null;
      if (user.email == "") user.email = null;
      const { data, errors } = await client.users.update(user.id, user);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_USER", data);
        this.dispatch("getUsers");
      }
    },
    async saveUserImage({ dispatch }, id, image) {
      const { data, errors } = await client.users.image.save(id, image);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        console.log(data);
      }
    },
    async deleteUser({ commit, dispatch }, user) {
      const { data, errors } = await client.users.delete(user.id);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        // console.log(data);
        commit("DELETE_USER", user);
        this.dispatch("getUsers");
      }
    },
  },
  getters: {
    getAllUsers(state) {
      return state.users;
    },
    // rootState is specifically the third argument, so you need to pass getters regardless of if you use it or not
    // getCurrentUser(state, getters, rootState) {
    //   if (rootState.magicAuth.user != null) {
    //     if (rootState.magicAuth.user.email) {
    //       return (
    //         state.users.find(
    //           (user) => user.email === rootState.magicAuth.user.email
    //         ) || {}
    //       );
    //     } else {
    //       return (
    //         state.users.find(
    //           (user) => user.phone === rootState.magicAuth.user.phoneNumber
    //         ) || {}
    //       );
    //     }
    //   } else {
    //     return {
    //       name: "",
    //       title: "",
    //       company: "",
    //       phone: "",
    //       email: "",
    //       admin: false,
    //     }
    //   }
    // },
    getUserById: (state) => (userId) => {
      return (
        state.users.find((user) => user.id === parseInt(userId)) || {
          name: "",
          title: "",
          company: "",
          phone: "",
          email: "",
          admin: false,
        }
      );
    },
    getUserByEmail: (state) => (userEmail) => {
      return state.users.find((user) => user.email === userEmail);
    },
    getUserByPhone: (state) => (userPhone) => {
      return state.users.find((user) => user.phone === userPhone);
    },
    getUserOrgDetails: (state, getters) => (userId) => {
      let currentUserOrgId = getters.getUserById(userId);
      return getters.getOrgById(currentUserOrgId);
    },

    getDerivedUsers:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedUsers = state.users;
        let filteredUsers = _.isEmpty(filter)
          ? expandedUsers
          : _.filter(expandedUsers, filter);
        let matchedUsers = _.isEmpty(match)
          ? filteredUsers
          : _.filter(filteredUsers, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedUsers = _.isEmpty(sort)
          ? matchedUsers
          : _.orderBy(matchedUsers, sortColumns, sortOrders);
        let paginatedUsers =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedUsers,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedUsers;
        return paginatedUsers;
      },
  },
};
