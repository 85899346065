import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      integrations: [],
    };
  },
  mutations: {
    SET_INTEGRATIONS(state, integrationData) {
      state.integrations = integrationData;
    },
    ADD_INTEGRATION(state, integrationObject) {
      state.integrations.push(integrationObject);
    },
    UPDATE_INTEGRATION(state, integrationObject) {
      state.integrations = state.integrations.filter(function (obj) {
        return obj.id !== integrationObject.id;
      });
      state.integrations.push(integrationObject);
    },
    RESET_INTEGRATIONS(state) {
      state.integrations = [];
    },
  },
  actions: {
    async getIntegrations({ commit }) {
      const { data, errors } = await client.integrations.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_INTEGRATIONS", data.integrations);
      }
    },
    async createIntegration({ commit, dispatch }, integration) {
      const { data, errors } = await client.integrations.create(
        null,
        integration,
      );
      if (errors) {
        console.log("create integration", errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("ADD_INTEGRATION", integration);
        this.dispatch("getIntegrations");
      }
    },
    async updateIntegration({ commit, dispatch }, integration) {
      const { data, errors } = await client.integrations.update(
        integration.id,
        integration,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_INTEGRATION", data);
        this.dispatch("getIntegrations");
      }
    },
    async deleteIntegration({ commit, dispatch }, integrationId) {
      const { data, errors } = await client.integrations.delete(integrationId);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_INTEGRATION", data);
        this.dispatch("getIntegrations");
      }
    },
  },
  getters: {
    getIntegrationById: (state) => (integrationId) => {
      return state.integrations.find(
        (integration) => integration.id === integrationId,
      );
    },
    getIntegrationByType: (state) => (type) => {
      return state.integrations.find(
        (integration) => integration.type === type,
      );
    },
  },
};
