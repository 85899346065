import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      controllerAllocations: [],
    };
  },
  mutations: {
    SET_CONTROLLER_CROP_ALLOCATIONS(state, allocationData) {
      state.controllerAllocations = allocationData;
    },
    RESET_CONTROLLER_CROP_ALLOCATIONS(state) {
      state.controllerAllocations = [];
    },
  },
  actions: {
    async getControllerAllocations({ commit }) {
      const { data, errors } = await client.controller.cropAllocations.read();
      if (errors) {
        console.log(errors);
      } else {
        // let sharedAllocations = data.allocations?.filter(
        //   (allocation) =>
        //     getters.getVendorById(
        //       getters.getControllerOrderById(allocation.crop_order).seller_details.id
        //     )?.seller_confirmed
        // );
        // commit("SET_CONTROLLER_CROP_ALLOCATIONS", sharedAllocations);
        // console.log("controller allocations", data.allocations);
        commit("SET_CONTROLLER_CROP_ALLOCATIONS", data.allocations);
      }
    },
  },
  getters: {
    getAllControllerAllocations: (state) => {
      return state.controllerAllocations;
    },
    getControllerAllocationById: (state) => (allocationId) => {
      return state.controllerAllocations.find(
        (allocation) => allocation.id === parseInt(allocationId),
      );
    },
    getControllerAllocationsByOrder: (state) => (orderId) => {
      return state.controllerAllocations.filter(
        (allocation) => allocation.crop_order === parseInt(orderId),
      );
    },
    getControllerAllocationsByField: (state) => (fieldId) => {
      return state.controllerAllocations.filter(
        (allocation) => allocation.field === parseInt(fieldId),
      );
    },
    getControllerAllocationsBySeller: (state, getters) => (sellerId) => {
      return state.controllerAllocations.filter((allocation) => {
        return (
          getters.getControllerOrderById(allocation.crop_order).seller_details
            .id === parseInt(sellerId)
        );
      });
    },
    getControllerAllocationFieldsBySeller: (state, getters) => (sellerId) => {
      return state.controllerAllocations
        .filter((allocation) => {
          return (
            getters.getControllerOrderById(allocation.crop_order).seller_details
              .id === parseInt(sellerId)
          );
        })
        .map((allocation) => ({
          type: "Feature",
          id: allocation.field,
          field_allocated_acres: allocation.shared_data.field_allocated_acres,
          field_details: allocation.shared_data.field_details,
          crop_details: allocation.shared_data.crop_details,
          record_details: allocation.shared_data.record_details,
        }));
    },
    getControllerSumAllocatedAcresByFieldId: (state) => (fieldId) => {
      return _.sumBy(
        state.controllerAllocations.filter(
          (allocation) =>
            allocation.shared_data.field_details.id === parseInt(fieldId),
        ),
        "acres",
      );
    },
    getControllerAllocationsByFieldId: (state) => (fieldId) => {
      return state.controllerAllocations.filter(
        (allocation) =>
          allocation.shared_data.field_details.id === parseInt(fieldId),
      );
    },
    getControllerAllocationFieldByFieldId: (state) => (fieldId) => {
      // aggregate the allocated acres from all the allocations for the field
      // return a single field object containing the aggregated acres in addition to field details
      let fieldAllocations = state.controllerAllocations.filter(
        (allocation) =>
          allocation.shared_data.field_details.id === parseInt(fieldId),
      );
      let field_allocated_acres =
        fieldAllocations[0].shared_data.field_allocated_acres;
      let fieldDetails = fieldAllocations[0].shared_data.field_details;
      let cropDetails = fieldAllocations[0].shared_data.crop_details;
      let recordDetails = fieldAllocations[0].shared_data.record_details;
      let totalAllocatedAcres = _.sumBy(fieldAllocations, "acres");
      return {
        type: "Feature",
        id: fieldId,
        field_details: fieldDetails,
        crop_details: cropDetails,
        record_details: recordDetails,
        field_allocated_acres: field_allocated_acres,
        total_allocated_acres: totalAllocatedAcres,
      };
    },
    getDerivedControllerAllocations:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedControllerAllocations = state.controllerAllocations;
        let filteredControllerAllocations = _.isEmpty(filter)
          ? expandedControllerAllocations
          : _.filter(expandedControllerAllocations, filter);
        let matchedControllerAllocations = _.isEmpty(match)
          ? filteredControllerAllocations
          : _.filter(filteredControllerAllocations, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortAllocations = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortAllocations);
        let sortedControllerAllocations = _.isEmpty(sort)
          ? matchedControllerAllocations
          : _.allocationBy(
              matchedControllerAllocations,
              sortColumns,
              sortAllocations,
            );
        let paginatedControllerAllocations =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedControllerAllocations,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedControllerAllocations;
        return paginatedControllerAllocations;
      },
  },
};
