<template>
  <div class="rounded-t border-b border-gray-200">
    <div class="bg-gray-50 px-4 py-4 sm:p-4">
      <div>
        <div class="mt-1 flex rounded-md">
          <div
            class="relative flex flex-grow items-stretch justify-between focus-within:z-10"
          >
            <slot name="toolbarComponents"></slot>
          </div>
        </div>
        <div
          class="mt-1 flex rounded-md"
          v-if="this.$slots.activeFilters != null"
        >
          <!-- Maybe add a divider here? -->
          <div
            class="relative flex flex-grow items-stretch justify-between focus-within:z-10"
          >
            <slot name="activeFilters"></slot>
            <button @click="$store.dispatch('toggleView')" class="sm:hidden">
              <OutlineHeroIcon
                name="AdjustmentsIcon"
                classProp="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OutlineHeroIcon from "../icons/OutlineHeroIcon.vue";
export default {
  components: { OutlineHeroIcon },
  props: {},
  emits: [],
  methods: {},
};
</script>

<style></style>
