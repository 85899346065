import _ from "lodash";
import client from "@/api/parcel/api.js";
import { prettyOrderStatusGrower } from "@/components/composables/formatStringUtils.js";

export default {
  state() {
    return {
      growerServiceOrders: [],
    };
  },
  mutations: {
    SET_GROWER_ORDERS(state, growerOrderData) {
      state.growerServiceOrders = growerOrderData;
    },
    ADD_GROWER_ORDER(state, growerOrderObject) {
      const result = state.growerServiceOrders.find(
        (growerOrder) => growerOrder.id === parseInt(growerOrderObject.id),
      );
      if (!result) state.growerServiceOrders.push(growerOrderObject);
    },
    UPDATE_GROWER_ORDER(state, growerOrderObject) {
      state.growerServiceOrders = state.growerServiceOrders.filter(
        function (obj) {
          return obj.id !== growerOrderObject.id;
        },
      );
      state.growerServiceOrders.push(growerOrderObject);
    },
    RESET_GROWER_ORDERS(state) {
      state.growerServiceOrders = [];
    },
  },
  actions: {
    async getGrowerOrders({ commit }) {
      const { data, errors } = await client.grower.serviceOrders.read();
      if (errors) {
        console.log(errors);
      } else {
        // console.log("growerServiceOrders", data);
        commit("SET_GROWER_ORDERS", data.orders);
      }
    },
    async createGrowerOrder({ commit, dispatch }, growerOrder) {
      const { data, errors } = await client.grower.serviceOrders.create(
        null,
        growerOrder,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("ADD_GROWER_ORDER", data);
        this.dispatch("getGrowerOrders");
        return data;
      }
    },
    async updateGrowerOrder({ commit, dispatch }, growerOrder) {
      const { data, errors } = await client.grower.serviceOrders.update(
        growerOrder.id,
        growerOrder,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_GROWER_ORDER", data);
        this.dispatch("getGrowerOrders");
      }
    },
  },
  getters: {
    getGrowerOrderById: (state) => (growerOrderId) => {
      return state.growerServiceOrders.find(
        (growerOrder) => growerOrder.id === parseInt(growerOrderId),
      );
    },
    getGrowerOrdersByAgreementId: (state) => (growerAgreementId) => {
      return state.growerServiceOrders.filter(
        (growerOrder) => growerOrder.agreement === parseInt(growerAgreementId),
      );
    },
    getDerivedGrowerOrders:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedGrowerOrders = state.growerServiceOrders.map((order) => ({
          ...order,
          // seller_name: order.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterGrowerOrders = filterFunction
          ? _.filter(expandedGrowerOrders, filterFunction)
          : expandedGrowerOrders;
        let filteredGrowerOrders = _.isEmpty(filter)
          ? functionFilterGrowerOrders
          : _.filter(functionFilterGrowerOrders, filter);
        let matchedGrowerOrders = _.isEmpty(match)
          ? filteredGrowerOrders
          : _.filter(filteredGrowerOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedGrowerOrders = _.isEmpty(sort)
          ? matchedGrowerOrders
          : _.sortBy(matchedGrowerOrders, sort);
        let paginatedGrowerOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerOrders;
        return paginatedGrowerOrders;
      },
    // Always prefer getDerivedOrders for component data. These are helpers to expose data for specific pages that only needs some data
    getEnrichedGrowerOrders:
      (state, rootGetters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let growerQuotes = rootGetters.getAllGrowerQuotes;
        let initalGrowerOrders = state.growerServiceOrders;
        initalGrowerOrders.forEach((growerOrder) => {
          let growerQuote = growerQuotes.find(
            (growerQuote) => growerQuote.id === parseInt(growerOrder.agreement),
          );
          growerOrder.agreement_object = growerQuote;
        });
        let expandedGrowerOrders = initalGrowerOrders.map((order) => ({
          ...order,
          prettyStatus: prettyOrderStatusGrower(order.status),
          service_title: order.agreement_object?.service_title,
          service_category: order.agreement_object?.service_category,
          agreement_status: order.agreement_object?.status,
          buyer_name: order.agreement_object?.buyer_details.name,
          seller_name: order.agreement_object?.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterGrowerOrders = filterFunction
          ? _.filter(expandedGrowerOrders, filterFunction)
          : expandedGrowerOrders;
        let filteredGrowerOrders = _.isEmpty(filter)
          ? functionFilterGrowerOrders
          : _.filter(functionFilterGrowerOrders, filter);
        let matchedGrowerOrders = _.isEmpty(match)
          ? filteredGrowerOrders
          : _.filter(filteredGrowerOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedGrowerOrders = _.isEmpty(sort)
          ? matchedGrowerOrders
          : _.sortBy(matchedGrowerOrders, sort);
        let paginatedGrowerOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerOrders;
        return paginatedGrowerOrders;
      },
    getAwaitingResponseGrowerOrders: (state) => {
      return state.growerServiceOrders.filter(
        (growerOrder) => growerOrder.status === "buyer_submitted",
      );
    },
    getClosedGrowerOrders: (state) => {
      return state.growerServiceOrders.filter(
        (growerOrder) =>
          growerOrder.status === "buyer_closed" ||
          growerOrder.status === "seller_closed" ||
          growerOrder.status === "seller_canceled" ||
          growerOrder.status === "buyer_rejected",
      );
    },
    getCompletedGrowerOrders: (state) => {
      return state.growerServiceOrders.filter(
        (growerOrder) => growerOrder.status === "seller_completed",
      );
    },
  },
};
