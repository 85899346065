<template>
  <page-card :headingTitle="currentCustomer?.buyer_details?.name || 'Customer'">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="customerFormId"
      />
    </template>
    <template #cardDetails>
      <form
        @submit.prevent="updateCustomer"
        :id="customerFormId"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <text-input
          v-if="currentCustomer"
          :wideItem="true"
          inputTitle="Name"
          :required="true"
          inputHtmlType="text"
          v-model="currentCustomer.buyer_details.name"
        />
        <email-input
          v-if="currentCustomer"
          :wideItem="true"
          inputTitle="Contact Email"
          inputHtmlType="text"
          v-model="currentCustomer.buyer_details.contact_email"
        />
        <checkbox-input
          v-if="currentCustomer"
          :wideItem="true"
          inputTitle="Share details with buyer"
          inputHtmlType="text"
          v-model="currentCustomer.seller_confirmed"
          class="pt-6"
        />
      </form>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";

export default {
  components: {
    TextInput,
    CheckboxInput,
    EmailInput,
    PageCard,
    SubmitButton,
  },
  data() {
    return {
      customerFormId: "edit-customer-form",
      currentCustomer: null,
    };
  },
  computed: {
    customerId() {
      return this.$route.params.customerId;
    },
  },
  methods: {
    readCustomer() {
      this.currentCustomer = this.$store.getters.getGrowerCropCustomerById(
        this.customerId,
      );
    },
    updateCustomer() {
      // this.$store.dispatch("updateGrowerCropCustomer", this.currentCustomer);
      this.$store.dispatch("updateGrowerCropCustomer", {
        id: this.currentCustomer.id,
        seller_confirmed: this.currentCustomer.seller_confirmed,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-customers" });
      }
    },
  },
  watch: {
    customerId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readCustomer();
        } else {
          console.log("no customer id");
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
