<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Close Quote Inquiry"
    modalDescription="Are you sure you want to close this quote inquiry?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="closeQuote"
    deleteButtonText="Cancel"
    closeButtonText="Back"
  />
  <confirm-modal
    :open="showOrderModal"
    modalTitle="Order Now?"
    modalDescription="Would you like to place an order now based on this quote now?"
    @clickCancel="orderLater"
    @clickConfirm="quickOrder"
  />
  <page-card headingTitle="Review Quote">
    <template #buttonArea>
      <base-button
        buttonText="Close Inquiry"
        :redButton="true"
        :marginLeft="false"
        @buttonClick="intializeDeleteModal"
        v-if="
          quote.status === 'buyer_submitted' ||
          quote.status === 'seller_responded'
        "
      />
      <base-button
        buttonText="Reject"
        @buttonClick="rejectQuote"
        :marginLeft="true"
        v-if="quote.status === 'seller_responded'"
      />
      <base-button
        buttonText="Accept"
        @buttonClick="acceptQuote"
        :marginLeft="true"
        v-if="quote.status === 'seller_responded'"
      />
    </template>
    <template #cardDetails>
      <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="quote.seller_details.name"
        :wideItem="true"
        inputTitle="Vendor"
      />
      <div class="sm:col-span-3">
        <label for="territory" class="block text-sm font-medium text-gray-700">
          Product
          <router-link
            :to="{
              name: 'grower-portal-discover-single-product',
              params: { productId: quote.service },
            }"
            class="text-twilight-600 hover:text-twilight-900"
            >(View Product)</router-link
          >
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="quote.service_title"
          :wideItem="true"
        />
      </div>
      <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="quote.start_date.substring(0, 16)"
        :wideItem="true"
        inputTitle="Agreement Start Date"
      />
      <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="quote.end_date.substring(0, 16)"
        :wideItem="true"
        inputTitle="Agreement End Date"
      />
      <!-- <currency-input
        :displayOnly="true"
        :displayOnlyValue="quote.total_price"
        :wideItem="true"
        inputTitle="Total Price"
        v-model="quote.total_price"
      /> -->
      <!-- <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="quote.tracking_id"
        :wideItem="true"
        inputTitle="Tracking ID"
      /> -->
      <text-area
        :displayOnly="true"
        :displayOnlyValue="quote.tracking_memo"
        :wideItem="true"
        inputTitle="Memo"
      />

      <div v-if="quoteClosed" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Quote Closed', status: 'complete' }]"
        />
      </div>
      <div v-else-if="quoteCanceled" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Quote Canceled', status: 'complete' }]"
        />
      </div>
      <div v-else-if="quoteCompleted" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Quote Completed', status: 'complete' }]"
        />
      </div>
      <div v-else class="sm:col-span-6">
        <horizontal-panel-steps :steps="quoteSteps" />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Request Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
                <span
                  v-if="quote.attachments"
                  :class="[
                    selected
                      ? 'bg-indigo-100 text-indigo-600'
                      : 'bg-gray-200 text-gray-900',
                    'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
                  ]"
                  >{{
                    quote.attachments != null ? quote.attachments.length : ""
                  }}</span
                >
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="quote.shared_assets?.fields?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Fields
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="quote.shared_assets?.equipment?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Equipment
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="quote.status === 'buyer_confirmed'"
            >
              <router-link
                :to="{
                  name: 'grower-portal-discover-single-product',
                  params: { productId: quote.service },
                }"
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'flex whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                New Order
                <ExternalLinkIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </router-link>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel class="text-sm text-gray-500">
            <!-- <number-input
              :display-only="true"
              :displayOnlyValue="quote.quantity"
              :step="1"
              :wideItem="true"
              inputTitle="Quantity"
            />
            <text-area
              :displayOnly="true"
              :displayOnlyValue="quote.delivery_address"
              :wideItem="true"
              inputTitle="Delivery Address"
              inputHtmlType="text"
            /> -->
            <!-- <h2 class="mt-2" v-if="Object.keys(quote.form_response).length > 0">
              Form Questions
            </h2>
            <variable-string-type-input
              v-for="(value, key) in quote.form_response"
              :key="key"
              :wideItem="true"
              :inputTitle="key"
              :placeholder="value"
              :display-only="true"
            />
            <h2 class="mt-2" v-if="Object.keys(quote.variants).length > 0">
              Variants
            </h2>
            <variable-string-type-input
              v-for="(value, key) in quote.variants"
              :key="key"
              :inputTitle="key"
              :wideItem="true"
              :placeholder="value"
              :display-only="true"
            />
            <h2 class="mt-2" v-if="Object.keys(quote.options).length > 0">
              Options
            </h2>
            <checkbox-input
              v-for="(value, key) in quote.options"
              :key="key"
              :inputTitle="key"
              :modelValue="value"
              :wideItem="true"
              :display-only="true"
            /> -->
            <complete-quote-form :vendor-perspective="false" />
          </TabPanel>
          <TabPanel class="text-sm text-gray-500">
            <view-attachments-table :attachments="quote.attachments" />
          </TabPanel>
          <TabPanel>
            <ChatSingleView :thread-id="existingThread" />
          </TabPanel>
          <TabPanel
            class="text-sm text-gray-500"
            v-if="quote.shared_assets?.fields?.length > 0"
          >
            <shared-fields
              :fields="quote.shared_assets?.fields"
              :locations="quote?.shared_assets?.locations"
            />
          </TabPanel>
          <TabPanel
            class="text-sm text-gray-500"
            v-if="quote.shared_assets?.equipment?.length > 0"
          >
            <shared-equipment :equipment="quote.shared_assets?.equipment" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import client from "@/api/parcel/api";

import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
// import DateTimeInput from "@/components/form/DateTimeInput.vue";
// import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
import HorizontalPanelSteps from "@/components/progress/HorizontalPanelSteps.vue";
import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";

import { ExternalLinkIcon } from "@heroicons/vue/outline";

import CompleteQuoteForm from "@/layouts/common/quotes/CompleteQuoteForm.vue";

import SharedEquipment from "@/layouts/common/shared-assets/SharedEquipment.vue";
import SharedFields from "@/layouts/common/shared-assets/SharedFields.vue";

import { useToast } from "vue-toastification";

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DeleteModal,
    ConfirmModal,
    // DateTimeInput,
    TextArea,
    // CurrencyInput,
    // NumberInput,
    // CheckboxInput,
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    HorizontalPanelSteps,
    ViewAttachmentsTable,
    ChatSingleView,
    ExternalLinkIcon,
    CompleteQuoteForm,
    SharedEquipment,
    SharedFields,
  },
  data() {
    const toast = useToast();
    return {
      modalIsActive: false,
      toast,
      currentProduct: null,
      showOrderModal: false,
    };
  },
  computed: {
    quoteId() {
      return this.$route.params.quoteId;
    },
    quote() {
      return this.$store.getters.getGrowerQuoteById(this.quoteId);
    },
    quoteClosed() {
      if (
        this.quote.status === "seller_closed" ||
        this.quote.status === "buyer_closed"
      ) {
        return true;
      } else {
        return false;
      }
    },
    quoteCanceled() {
      if (this.quote.status === "seller_canceled") {
        return true;
      } else {
        return false;
      }
    },
    quoteCompleted() {
      if (this.quote.status === "seller_completed") {
        return true;
      } else {
        return false;
      }
    },
    quoteSteps() {
      let stepTwoStatuses = [
        "seller_responded",
        "buyer_confirmed",
        "seller_completed",
      ];
      let steps = [
        { name: "Quote Requested", status: "complete" },
        {
          name: "Price Received",
          status: stepTwoStatuses.includes(this.quote.status)
            ? "complete"
            : "upcoming",
        },
        {
          name: "Quote Accepted",
          status:
            this.quote.status === "buyer_confirmed" ? "complete" : "upcoming",
        },
      ];
      return steps;
    },
    existingThread() {
      return this.$store.getters.getThreadByPartner(
        this.currentProduct?.seller_details?.id,
      )?.id;
    },
  },
  methods: {
    async acceptQuote() {
      await this.$store.dispatch("updateGrowerQuote", {
        id: this.quote.id,
        status: "buyer_confirmed",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Quote Accepted!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.showOrderModal = true;
    },
    quickOrder() {
      console.log("quick order", this.quote.id);
      this.$router.push({
        name: "grower-portal-discover-single-product",
        params: { productId: this.quote.service },
      });
    },
    orderLater() {
      this.$router.push({ name: "grower-portal-quotes-all" });
    },

    rejectQuote() {
      this.$store.dispatch("updateGrowerQuote", {
        id: this.quote.id,
        status: "buyer_rejected",
      });
      this.toast.success("Quote Rejected", {
        timeout: 1500,
        hideProgressBar: true,
      });
      this.$router.push({ name: "grower-portal-quotes-all" });
    },
    closeQuote() {
      this.$store.dispatch("updateGrowerQuote", {
        id: this.quote.id,
        status: "buyer_closed",
      });
      this.toast.success("Quote Inquiry Closed", {
        timeout: 1500,
        hideProgressBar: true,
      });
      this.$router.push({ name: "grower-portal-quotes-all" });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
    quoteId: {
      // handler(value) or handler(newValue, oldValue)
      async handler() {
        if (this.quoteId === undefined) return;
        let quote = this.quote;
        if (!quote)
          quote = await this.$store.getters.getGrowerQuoteById(this.quoteId);
        // console.log("quote", quote, this.quoteId);
        let data = await client.grower.services.readOne(quote.service);
        this.currentProduct = data.data;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
