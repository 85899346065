import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    // console.log("setting state");
    return {
      fields: [],
      bounds: [],
    };
  },
  mutations: {
    SET_SHARED_FIELDS(state, fieldData) {
      state.fields = fieldData;
    },
    SET_SHARED_BOUNDARY_BOX(state, boundsData) {
      if (boundsData) {
        state.bounds = [
          [boundsData[1], boundsData[0]],
          [boundsData[3], boundsData[2]],
        ];
      } else {
        state.bounds = [];
      }
    },
    ADD_SHARED_FIELD(state, fieldObject) {
      state.fields.push(fieldObject);
    },
    UPDATE_SHARED_FIELD(state, fieldObject) {
      state.fields = state.fields.filter(function (obj) {
        return obj.id !== fieldObject.id;
      });
      state.fields.push(fieldObject);
    },
    DELETE_SHARED_FIELD(state, fieldObject) {
      state.fields = state.fields.filter(function (obj) {
        return obj.id !== fieldObject.id;
      });
    },
    RESET_SHARED_FIELDS(state) {
      state.fields = [];
      state.bounds = [];
    },
  },
  actions: {
    async getSharedFields({ commit, rootState }) {
      let fieldsList = [];
      for (let customer of rootState.customers.customers) {
        if (
          customer.seller_confirmed === true &&
          customer.buyer_confirmed === true
        ) {
          const { data, errors } = await client.fields.read(null, {
            org: customer.id,
          });

          if (errors) {
            console.log(errors);
          } else {
            // add each set of fields to the collection.
            fieldsList = fieldsList.concat(data.fields);
          }
        }
      }
      commit("SET_SHARED_FIELDS", fieldsList);
    },
  },
  getters: {
    getSharedFieldById: (state) => (fieldId) => {
      return state.fields.find((field) => field.id === parseInt(fieldId));
    },
    getSharedFieldByCropId: (state) => (cropId) => {
      return state.fields.find(
        (field) => field.properties.current_crop?.id === parseInt(cropId),
      );
    },
    getSharedFieldByName: (state) => (fieldName) => {
      return state.fields.find((field) => field.properties.name === fieldName);
    },
    getDerivedSharedFields:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = [], // ["updated", "id"] - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedFields = state.fields.map((item) => ({
          ...item,
          ...item.properties,
          ...item.properties.details,
        }));
        let filteredFields = _.isEmpty(filter)
          ? expandedFields
          : _.filter(expandedFields, filter);
        let matchedFields = _.isEmpty(match)
          ? filteredFields
          : _.filter(filteredFields, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedFields = _.isEmpty(sort)
          ? matchedFields
          : _.orderBy(matchedFields, sortColumns, sortOrders);
        let paginatedFields =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedFields,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedFields;
        return paginatedFields;
      },
  },
};
