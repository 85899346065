<template>
  <button
    @click="submitForm"
    :disabled="disabled"
    type="submit"
    :form="formId"
    class="inline-flex items-center rounded border border-transparent px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="conditionalButtonStyling"
    :data-testid="dataTestid"
  >
    {{ buttonText }}
  </button>
  <notification-popup :noticeArray="errorArray" />
  <!--
  <div v-if="errorArray.length > 0">
    <p class="mt-2 text-sm text-red-600">Please fix the following errors:</p>
    <ol>
      <li
        class="mt-2 ml-4 text-sm text-red-600"
        v-for="error in errorArray"
        :key="error.id"
      >
        &bull; {{ error.errorDescription }}
      </li>
    </ol>
  </div>
  -->
</template>

<script>
import NotificationPopup from "../notifications/NotificationPopup.vue";
export default {
  props: {
    isCorrect: { type: Boolean },
    buttonText: { type: String },
    errorArray: { type: Array, default: new Array(0) },
    disabled: { type: Boolean, default: false },
    redButton: { type: Boolean, default: false },
    marginLeft: { type: Boolean, default: true },
    formId: { type: String, required: true, default: "" },
    dataTestid: { type: String, default: null },
  },
  emits: ["submitButtonClick"],
  components: {
    NotificationPopup,
  },
  data() {
    return {
      test: null,
      activeButton: this.isActive,
    };
  },
  computed: {
    conditionalButtonStyling() {
      let styles = {
        "bg-twilight-700": !this.redButton && !this.disabled,
        "hover:bg-twilight-800": !this.redButton && !this.disabled,
        "focus:ring-twilight-500": !this.redButton && !this.disabled,
        "bg-red-600": this.redButton && !this.disabled,
        "hover:bg-red-700": this.redButton && !this.disabled,
        "focus:ring-red-500": this.redButton && !this.disabled,
        "ml-1": this.marginLeft,
        "bg-gray-200": this.disabled,
        "hover:bg-gray-200": this.disabled,
      };
      return styles;
    },
    showErrorArray() {
      console.log("error length", this.errorArray, this.errorArray.length);
      if (this.errorArray.length > 0) return true;
      return false;
    },
  },
  methods: {
    submitForm() {
      this.$emit("submitButtonClick");
    },
  },
  watch: {
    errorArray() {
      console.log("errorArray changed", this.errorArray);
      if (this.errorArray.length > 0) {
        this.showErrorArray = true;
      }
    },
  },
};
</script>

<style></style>
