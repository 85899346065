import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      buyerAllocations: [],
    };
  },
  mutations: {
    SET_BUYER_CROP_ALLOCATIONS(state, allocationData) {
      state.buyerAllocations = allocationData;
    },
    RESET_BUYER_CROP_ALLOCATIONS(state) {
      state.buyerAllocations = [];
    },
  },
  actions: {
    async getBuyerAllocations({ commit }) {
      const { data, errors } = await client.buyer.cropAllocations.read();
      if (errors) {
        console.log(errors);
      } else {
        // let sharedAllocations = data.allocations?.filter(
        //   (allocation) =>
        //     getters.getVendorById(
        //       getters.getBuyerOrderById(allocation.crop_order).seller_details.id
        //     )?.seller_confirmed
        // );
        // commit("SET_BUYER_CROP_ALLOCATIONS", sharedAllocations);
        // console.log("buyer allocations", data.allocations);
        commit("SET_BUYER_CROP_ALLOCATIONS", data.allocations);
      }
    },
  },
  getters: {
    getAllBuyerAllocations: (state) => {
      return state.buyerAllocations;
    },
    getBuyerAllocationById: (state) => (allocationId) => {
      return state.buyerAllocations.find(
        (allocation) => allocation.id === parseInt(allocationId),
      );
    },
    getBuyerAllocationsByOrder: (state) => (orderId) => {
      return state.buyerAllocations.filter(
        (allocation) => allocation.crop_order === parseInt(orderId),
      );
    },
    getBuyerAllocationsByField: (state) => (fieldId) => {
      return state.buyerAllocations.filter(
        (allocation) => allocation.field === parseInt(fieldId),
      );
    },
    getBuyerAllocationsBySeller: (state, getters) => (sellerId) => {
      return state.buyerAllocations.filter((allocation) => {
        return (
          getters.getBuyerOrderById(allocation.crop_order).seller_details.id ===
          parseInt(sellerId)
        );
      });
    },
    getBuyerAllocationFieldsBySeller: (state, getters) => (sellerId) => {
      return state.buyerAllocations
        .filter((allocation) => {
          return (
            getters.getBuyerOrderById(allocation.crop_order).seller_details
              .id === parseInt(sellerId)
          );
        })
        .map((allocation) => ({
          type: "Feature",
          id: allocation.field,
          field_allocated_acres: allocation.shared_data.field_allocated_acres,
          field_details: allocation.shared_data.field_details,
          crop_details: allocation.shared_data.crop_details,
          record_details: allocation.shared_data.record_details,
        }));
    },
    getBuyerSumAllocatedAcresByFieldId: (state) => (fieldId) => {
      return _.sumBy(
        state.buyerAllocations.filter(
          (allocation) =>
            allocation.shared_data.field_details.id === parseInt(fieldId),
        ),
        "acres",
      );
    },
    getBuyerAllocationsByFieldId: (state) => (fieldId) => {
      return state.buyerAllocations.filter(
        (allocation) =>
          allocation.shared_data.field_details.id === parseInt(fieldId),
      );
    },
    getBuyerAllocationFieldByFieldId: (state) => (fieldId) => {
      // aggregate the allocated acres from all the allocations for the field
      // return a single field object containing the aggregated acres in addition to field details
      let fieldAllocations = state.buyerAllocations.filter(
        (allocation) =>
          allocation.shared_data.field_details.id === parseInt(fieldId),
      );
      let field_allocated_acres =
        fieldAllocations[0].shared_data.field_allocated_acres;
      let fieldDetails = fieldAllocations[0].shared_data.field_details;
      let cropDetails = fieldAllocations[0].shared_data.crop_details;
      let recordDetails = fieldAllocations[0].shared_data.record_details;
      let totalAllocatedAcres = _.sumBy(fieldAllocations, "acres");
      return {
        type: "Feature",
        id: fieldId,
        field_details: fieldDetails,
        crop_details: cropDetails,
        record_details: recordDetails,
        field_allocated_acres: field_allocated_acres,
        total_allocated_acres: totalAllocatedAcres,
      };
    },
    getDerivedBuyerAllocations:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedBuyerAllocations = state.buyerAllocations;
        let filteredBuyerAllocations = _.isEmpty(filter)
          ? expandedBuyerAllocations
          : _.filter(expandedBuyerAllocations, filter);
        let matchedBuyerAllocations = _.isEmpty(match)
          ? filteredBuyerAllocations
          : _.filter(filteredBuyerAllocations, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortAllocations = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortAllocations);
        let sortedBuyerAllocations = _.isEmpty(sort)
          ? matchedBuyerAllocations
          : _.allocationBy(
              matchedBuyerAllocations,
              sortColumns,
              sortAllocations,
            );
        let paginatedBuyerAllocations =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedBuyerAllocations,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedBuyerAllocations;
        return paginatedBuyerAllocations;
      },
  },
};
