import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      vendors: [],
    };
  },
  mutations: {
    SET_VENDORS(state, vendorData) {
      state.vendors = vendorData;
    },
    ADD_VENDOR(state, vendorObject) {
      const result = state.vendors.find(
        (vendor) => vendor.id === parseInt(vendorObject.id),
      );
      if (!result) state.vendors.push(vendorObject);
    },
    UPDATE_VENDOR(state, vendorObject) {
      state.vendors = state.vendors.filter(function (obj) {
        return obj.id !== vendorObject.id;
      });
      state.vendors.push(vendorObject);
    },
    DELETE_VENDOR(state, vendorId) {
      state.vendors = state.vendors.filter(function (obj) {
        return obj.id !== parseInt(vendorId);
      });
    },
    RESET_VENDORS(state) {
      state.vendors = [];
    },
  },
  actions: {
    async getVendors({ commit }) {
      const { data, errors } = await client.vendors.read();
      if (errors) {
        console.log(errors);
      } else {
        // console.log("vendors", data.vendors);
        commit("SET_VENDORS", data.vendors);
      }
    },
    async getOneVendor({ commit }, id) {
      console.log("FETCHING ONE VENDOR");

      const { data, errors } = await client.vendors.readOne(id);
      if (errors) {
        console.log(errors);
      } else {
        commit("ADD_VENDOR", data);
      }
    },
    async createVendor({ commit, dispatch }, vendor) {
      const { data, errors } = await client.vendors.create(null, vendor);
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("ADD_VENDOR", data);
        this.dispatch("getVendors");
      }
    },
    async updateVendor({ commit, dispatch }, vendor) {
      const { data, errors } = await client.vendors.update(vendor.id, vendor);
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_VENDOR", data);
        this.dispatch("getVendors");
      }
    },
    async deleteVendor({ commit, dispatch }, vendorId) {
      const { data, errors } = await client.vendors.delete(vendorId);
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("DELETE_VENDOR", vendorId);
        this.dispatch("getVendors");
      }
    },
  },
  getters: {
    getVendorById: (state) => (vendorId) => {
      return state.vendors.find((vendor) => vendor.id === parseInt(vendorId));
    },
    getDerivedVendors:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedVendors = state.vendors;
        let filteredVendors = _.isEmpty(filter)
          ? expandedVendors
          : _.filter(expandedVendors, filter);
        let matchedVendors = _.isEmpty(match)
          ? filteredVendors
          : _.filter(filteredVendors, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedVendors = _.isEmpty(sort)
          ? matchedVendors
          : _.sortBy(matchedVendors, sort);
        let paginatedVendors =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedVendors,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedVendors;
        return paginatedVendors;
      },
  },
};
