<!-- Input ORDER details -->
<template>
  <div class="col-span-6">
    <hr class="mt-2 w-full" />
    <div class="flex">
      <div class="mt-4 font-bold">
        Input Products
        <ToolTip helpText="Add input products to the order." helpTitle="" />
      </div>
      <base-button
        v-if="!readOnly"
        @buttonClick="addInput()"
        buttonText="+ Add"
      />
    </div>
    <div
      class="mb-4 mt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
    >
      <div
        v-for="(l, index) in inputsList"
        :key="l"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <!-- "`Set ${index + 1} Options`" -->
        <!-- Replace the below with a set of inputs that will set each farmingInput object's properties. -->
        <!-- <text-area
          :inputTitle="`Question ${index + 1}`"
          :wideItem="true"
          :model-value="l.question"
          @update:model-value="
            (newVal) => {
              inputsList[index].question = newVal;
            }
          "
        /> -->
        <select-search
          v-if="!readOnly"
          inputTitle="Product"
          :wideItem="true"
          :model-value="l.product_id"
          @update:model-value="
            (newVal) => {
              inputsList[index].product_id = newVal;
              updateProduct(newVal, index);
            }
          "
          :selectOptions="productListings"
        />
        <text-input
          v-else
          inputTitle="Product"
          :wideItem="true"
          :display-only="readOnly"
          :display-only-value="l.product"
          :model-value="l.product"
        />
        <text-area
          inputTitle="Instructions"
          :wideItem="true"
          :display-only="readOnly"
          :display-only-value="l.instructions"
          :model-value="l.instructions"
          @update:model-value="
            (newVal) => {
              inputsList[index].instructions = newVal;
            }
          "
        />
        <div class="col-span-6 -my-6 flex">
          <number-input
            inputTitle="Rate"
            :wideItem="false"
            :display-only="readOnly"
            :display-only-value="l.rate"
            :model-value="l.rate"
            @update:model-value="
              (newVal) => {
                inputsList[index].rate = newVal;
              }
            "
          />
          <div class="mx-2"></div>
          <select-search
            v-if="!readOnly"
            inputTitle="Unit"
            :wideItem="false"
            :narrow-item="true"
            :display-only="readOnly"
            :display-only-value="l.rate_unit"
            :model-value="l.rate_unit"
            :placeholder="unitsPlaceholder"
            :select-options="[
              'gallon',
              'quart',
              'pint',
              'ounce',
              'pound',
              'ton',
              'package',
              'teaspoon',
              'tablespoon',
            ]"
            :no-sort="true"
            @update:model-value="
              (newVal) => {
                inputsList[index].rate_unit = newVal;
              }
            "
          />
          <text-input
            v-else
            inputTitle="Unit"
            :wideItem="false"
            :display-only="readOnly"
            :display-only-value="l.rate_unit"
            :model-value="l.rate_unit"
          />
          <div class="mx-4 mt-8 text-sm">per</div>
          <select-search
            v-if="!readOnly"
            inputTitle="Area"
            :wideItem="false"
            :narrow-item="true"
            :display-only="readOnly"
            :display-only-value="l.rate_area"
            :model-value="l.rate_area"
            :placeholder="unitsPlaceholder"
            :select-options="['acre', '100 gallons']"
            :no-sort="true"
            @update:model-value="
              (newVal) => {
                inputsList[index].rate_area = newVal;
              }
            "
          />
          <text-input
            v-else
            inputTitle="Area"
            :wideItem="false"
            :display-only="readOnly"
            :display-only-value="l.rate_area"
            :model-value="l.rate_area"
          />
          <div
            class="col-span-2 mt-6 text-xs text-blue-500"
            v-if="l.rate_unit && selectedAcres > 0 && l.rate_area == 'acre'"
          >
            &nbsp; Application will require
            {{ (selectedAcres * l.rate).toFixed(2) }} {{ l.rate_unit }}(s) total
            for the selected fields
          </div>
        </div>
        <number-input
          inputTitle="Quantity"
          :wideItem="true"
          :display-only="readOnly"
          :display-only-value="l.quantity"
          :model-value="l.quantity"
          :step="1"
          @update:model-value="
            (newVal) => {
              inputsList[index].quantity = newVal;
            }
          "
        />
        <select-search
          v-if="!readOnly"
          inputTitle="SKU"
          :wideItem="true"
          :display-only="readOnly"
          :display-only-value="l.units.toString()"
          :model-value="l.sku_id"
          :placeholder="unitsPlaceholder"
          :select-options="
            inputUnits
              .filter((unit) => unit.input_listing == l.product_id)
              .map((unit) => {
                return {
                  label: unit.title,
                  value: unit.id,
                };
              })
          "
          @update:model-value="
            (newVal) => {
              inputsList[index].sku_id = newVal;
              inputsList[index].units = inputUnits.find(
                (unit) => unit.id == newVal,
              ).title;
            }
          "
        />
        <text-input
          v-else
          inputTitle="Units"
          :wideItem="true"
          :display-only="readOnly"
          :display-only-value="l.units"
          :model-value="l.units"
        />
        <!-- <number-input
          inputTitle="Unit Price"
          :wideItem="false"
          :display-only="readOnly"
          :display-only-value="l.unit_price"
          :model-value="l.unit_price"
          @update:model-value="
            (newVal) => {
              inputsList[index].unit_price = newVal;
            }
          "
        /> -->
        <!-- <date-time-input
          inputTitle="Deadline"
          :wideItem="false"
          :step="1"
          :display-only="readOnly"
          :display-only-value="l.deadline"
          :model-value="l.deadline"
          @update:model-value="
            (newVal) => {
              inputsList[index].deadline = newVal;
            }
          "
        /> -->
        <button v-if="!readOnly" @click="removeInput(index)" class="-mt-3 h-10">
          <trash-icon class="h-6 w-6" />
        </button>
        <hr class="col-span-6 h-px border-0 bg-gray-200 dark:bg-gray-700" />
      </div>
    </div>
  </div>
</template>

<script>
import SelectSearch from "@/components/form/SelectSearch.vue";
// import DateInput from "@/components/form/DateInput.vue";
// import TimeInput from "@/components/form/TimeInput.vue";
import ToolTip from "@/components/modals/ToolTip.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

// import DateTimeInput from "@/components/form/DateTimeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import { TrashIcon } from "@heroicons/vue/outline";

// import ComboBox from "@/components/form/ComboBox.vue";

import { getUTCString } from "@/components/composables/dateUtils.js";

export default {
  props: {
    orderDetails: {
      type: Object,
      //   Default is only relevant on VendorProductForm for displaying an example InputOrderDetails portion of the order creation form.
      default: () => ({
        inputs: [
          {
            product: "",
            //rate: "",
            quantity: 0,
            units: "",
            //unit_price: 0,
            instructions: "",
            //deadline: "",
          },
        ],
      }),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["updateOrderDetails"],
  components: {
    // VariableStringTypeInput,
    SelectSearch,
    // DateInput,
    // TimeInput,
    ToolTip,
    TrashIcon,
    //DateTimeInput,
    NumberInput,
    // ComboBox,
    TextInput,
    TextArea,
    BaseButton,
  },
  data() {
    return {
      numberOfInputs: this.setInitialNumberOfInputs(),
      inputsList: this.orderDetails.inputs,
      // cropSkus: [],
      unitsPlaceholder: "Select Units...",
    };
  },
  computed: {
    // cropSkus() {
    //   let skuOptions = [];
    //   let skus = [];
    //   if (this.vendorPerspective == true) {
    //     skus = this.$store.state.vendorInputListings.units;
    //   } else {
    //     skus = this.$store.state.growerInputListings.units;
    //   }
    //   skuOptions = skus.map((sku) => {
    //     return {
    //       label: sku.title,
    //       value: sku.id,
    //     };
    //   });

    //   return skuOptions;
    // },
    vendorPerspective() {
      if (this.$route.path.includes("vendor")) {
        return true;
      } else {
        return false;
      }
    },
    detailsToReturn() {
      return {
        inputs: this.inputsList,
      };
    },
    cropInputs() {
      // Note: this will only return this vendor's listings. If we want to allow the vendor to recommend other
      // listings, we will need to change this.
      if (this.vendorPerspective == true) {
        return this.$store.state.vendorInputListings.listings;
      } else {
        return this.$store.state.growerInputListings.listings;
      }
    },
    inputUnits() {
      if (this.vendorPerspective == true) {
        return this.$store.state.vendorInputListings.units;
      } else {
        return this.$store.state.growerInputListings.units;
      }
    },

    productListings() {
      return this.cropInputs.map((input) => {
        return {
          label: input.title,
          value: input.id,
        };
      });
    },
    selectedAcres() {
      let acres = 0;
      if (this.fields?.length > 0) {
        acres = this.fields.reduce((acc, field) => {
          return acc + field?.properties?.acres;
        }, 0);
      }
      return acres;
    },
  },
  methods: {
    setInitialNumberOfInputs() {
      let numInputs = 0;
      if (this.orderDetails.length >= 1) {
        numInputs = this.orderDetails.length;
      }
      return numInputs;
    },
    generateDeadline() {
      if (this.taskDate && this.taskTime) {
        return getUTCString(new Date(this.taskDate + " " + this.taskTime));
      } else {
        return null;
      }
    },
    updateProduct(product_id, index) {
      let product = this.cropInputs.find((crop) => crop.id == product_id);
      console.log("product_id", product_id, index, product);
      this.inputsList[index].product = product?.title;
    },
    addInput() {
      console.log("order details", this.orderDetails);
      console.log("inputs list", this.inputsList);
      if (this.readOnly) {
        return;
      } else
        this.inputsList.push({
          product: "",
          // rate: "",
          quantity: 0,
          units: "",
          // unit_price: 0,
          instructions: "",
          // deadline: "",
        });
    },
    removeInput(index) {
      if (this.readOnly) {
        return;
      }
      this.inputsList.splice(index, 1);
    },
  },
  watch: {
    orderDetails: {
      handler: function (value) {
        console.log("input order details", value);
        if (Object.keys(value).length === 0) {
          console.log("no order details");
          this.inputsList = [
            {
              product: "",
              // rate: "",
              quantity: 0,
              units: "",
              // unit_price: 0,
              instructions: "",
              // deadline: "",
            },
          ];
        }
      },
      deep: true,
      immediate: true,
    },
    detailsToReturn: {
      handler: function (value) {
        // for item in the
        // console.log("input details to return", value);
        this.$emit("updateOrderDetails", value);
      },
      deep: true,
    },
    numberOfInputs() {
      if (this.readOnly) {
        return;
      }
      if (this.inputsList.length < this.numberOfInputs) {
        while (this.inputsList.length < this.numberOfInputs) {
          this.addInput();
        }
      } else if (this.inputsList.length > this.numberOfInputs) {
        while (this.inputsList.length > this.numberOfInputs) {
          this.inputsList.pop();
        }
      }
    },
  },
};
</script>
