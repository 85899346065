import _ from "lodash";
import client from "@/api/parcel/api.js";
import { prettyQuoteStatusVendor } from "@/components/composables/formatStringUtils.js";

export default {
  state() {
    return {
      vendorQuotes: [],
    };
  },
  mutations: {
    SET_VENDOR_QUOTES(state, vendorQuoteData) {
      state.vendorQuotes = vendorQuoteData;
    },
    UPDATE_VENDOR_QUOTE(state, vendorQuoteObject) {
      state.vendorQuotes = state.vendorQuotes.filter(function (obj) {
        return obj.id !== vendorQuoteObject.id;
      });
      state.vendorQuotes.push(vendorQuoteObject);
    },
    RESET_VENDOR_QUOTES(state) {
      state.vendorQuotes = [];
    },
  },
  actions: {
    async getVendorQuotes({ commit }) {
      const { data, errors } = await client.vendor.serviceAgreements.read();
      if (errors) {
        console.log(errors);
      } else {
        // console.log("vendorQuotes", data);
        commit("SET_VENDOR_QUOTES", data.agreements);
      }
    },
    async updateVendorQuote({ commit, dispatch }, vendorQuote) {
      const { data, errors } = await client.vendor.serviceAgreements.update(
        vendorQuote.id,
        vendorQuote,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_VENDOR_QUOTE", data);
        this.dispatch("getVendorQuotes");
      }
    },
    async uploadVendorQuoteAttachment({ dispatch }, quoteIdAndAttachment) {
      const { data, errors } =
        await client.vendor.serviceAgreements.createAttachment(
          quoteIdAndAttachment.quoteId,
          quoteIdAndAttachment.file,
        );
      console.log(data, errors);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        return data;
        // await this.dispatch("getVendorQuotes");
      }
    },
  },
  getters: {
    getAllVendorQuotes: (state) => {
      return state.vendorQuotes;
    },
    getVendorQuoteById: (state) => (vendorQuoteId) => {
      return state.vendorQuotes.find(
        (vendorQuote) => vendorQuote.id === parseInt(vendorQuoteId),
      );
    },
    getDerivedVendorQuotes:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedVendorQuotes = state.vendorQuotes.map((quote) => ({
          ...quote,
          prettyStatus: prettyQuoteStatusVendor(quote.status),
          buyer_name: quote.buyer_details.name,
        }));
        let functionFilterVendorQuotes = filterFunction
          ? _.filter(expandedVendorQuotes, filterFunction)
          : expandedVendorQuotes;
        let filteredVendorQuotes = _.isEmpty(filter)
          ? functionFilterVendorQuotes
          : _.filter(expandedVendorQuotes, filter);
        let matchedVendorQuotes = _.isEmpty(match)
          ? filteredVendorQuotes
          : _.filter(filteredVendorQuotes, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedVendorQuotes = _.isEmpty(sort)
          ? matchedVendorQuotes
          : _.sortBy(matchedVendorQuotes, sort);
        let paginatedVendorQuotes =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedVendorQuotes,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedVendorQuotes;
        return paginatedVendorQuotes;
      },
    // Always prefer getDerivedQuotes for component data. These are helpers to expose data for specific pages that only needs some data
    getNeedsActionVendorQuotes: (state) => {
      return state.vendorQuotes.filter(
        (vendorQuote) =>
          vendorQuote.status === "buyer_submitted" ||
          vendorQuote.status === "buyer_rejected",
      );
    },
    getAwaitingResponseVendorQuotes: (state) => {
      return state.vendorQuotes.filter(
        (vendorQuote) => vendorQuote.status === "seller_responded",
      );
    },
    getClosedVendorQuotes: (state) => {
      return state.vendorQuotes.filter(
        (vendorQuote) =>
          vendorQuote.status === "buyer_closed" ||
          vendorQuote.status === "seller_closed" ||
          vendorQuote.status === "seller_canceled",
      );
    },
    getConfirmedVendorQuotes: (state) => {
      return state.vendorQuotes.filter(
        (vendorQuote) => vendorQuote.status === "buyer_confirmed",
      );
    },
    getCompletedVendorQuotes: (state) => {
      return state.vendorQuotes.filter(
        (vendorQuote) => vendorQuote.status === "seller_completed",
      );
    },
  },
};
