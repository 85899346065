import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";
import { formatStatus } from "@/components/composables/formatStringUtils.js";

export default {
  state() {
    return {
      growerRecommendations: [],
    };
  },
  mutations: {
    SET_GROWER_RECOMMENDATIONS(state, growerRecommendationData) {
      state.growerRecommendations = growerRecommendationData;
    },
    UPDATE_GROWER_RECOMMENDATION(state, growerRecommendationObject) {
      state.growerRecommendations = state.growerRecommendations.filter(
        function (obj) {
          return obj.id !== growerRecommendationObject.id;
        },
      );
      state.growerRecommendations.push(growerRecommendationObject);
    },
    RESET_GROWER_RECOMMENDATIONS(state) {
      state.growerRecommendations = [];
    },
  },
  actions: {
    async getGrowerRecommendations({ commit }) {
      const { data, errors } =
        await client.grower.serviceRecommendations.read();
      if (errors) {
        console.log(errors);
      } else {
        // console.log("growerRecommendations", data);
        commit("SET_GROWER_RECOMMENDATIONS", data.recommendations);
      }
    },
    async getOneGrowerRecommendation(_, recommendationId) {
      const { data, errors } =
        await client.grower.serviceRecommendations.readOne(recommendationId);
      if (errors) {
        console.log(errors);
      }
      return data;
    },
    async updateGrowerRecommendation(
      { commit, dispatch },
      growerRecommendation,
    ) {
      const { data, errors } =
        await client.grower.serviceRecommendations.update(
          growerRecommendation.id,
          growerRecommendation,
        );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        commit("UPDATE_GROWER_RECOMMENDATION", data);
        this.dispatch("getGrowerRecommendations");
      }
    },
  },
  getters: {
    // UNIVERSAL GETTER
    getGrowerRecommendations:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let growerRecommendations = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.growerRecommendations,
        );
        return growerRecommendations;
      },
    getEnrichedGrowerRecommendations:
      (state, rootGetters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let agreements = rootGetters.getAllGrowerQuotes;
        // console.log(agreements)
        let initalRecs = state.growerRecommendations;
        initalRecs.forEach((rec) => {
          let agreement = agreements.find(
            (agreement) => agreement.id === parseInt(rec.agreement),
          );
          rec.agreement_object = agreement;
        });
        let enrichedRecs = initalRecs.map((rec) => ({
          ...rec,
          type: rec.type,
          status: formatStatus(rec.status),
          service_title: rec.agreement_object?.service_title,
          seller_name: rec.agreement_object?.seller_details.name,
        }));
        // for rec in enrichedRecs delete rec.agreement_object
        let readyRecs = enrichedRecs.map((rec) => {
          delete rec.agreement_object;
          return rec;
        });
        let enrichedGrowerRecommendations = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          readyRecs,
        );
        return enrichedGrowerRecommendations;
      },
  },
};
