<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'result', label: 'Result', filtered: false },
          { name: 'asset_name', label: 'Asset' },
          { name: 'user', label: 'By' },
          { name: 'completed_at', label: 'Completed', filtered: false },
          { name: 'category', label: 'Type' },
          { name: 'actions', label: '', filtered: false, sorted: false },
          { name: 'actions', label: '', filtered: false, sorted: false },
        ]"
        :collection="extendedRecords"
        @update:derived-collection="derivedRecords = $event"
        selectionMode="Multiple"
        @update:selectedCollection="selectedRecords = $event"
      >
        <template #toolbarButtons>
          <div class="hidden sm:flex">
            <checkbox-input
              inputTitle="include Historical Crops"
              :wideItem="true"
              v-model="includeHistoricalCrops"
              class="mr-4"
            />

            <table-toolbar-button
              buttonText="Delete Selected"
              :usePlusIcon="false"
              :disabled="selectedRecords.length === 0"
              @buttonClick="deleteSelectedRecords()"
            />
            <table-toolbar-button
              buttonText="Add Record"
              :usePlusIcon="true"
              @buttonClick="navigateToNewRecords"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="record in derivedRecords" :key="record.rowid">
        <table-data td-label="Selected">
          <input
            type="checkbox"
            v-model="record.selected"
            class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
            @change="
              selectedRecords = extendedRecords.filter(
                (record) => record.selected,
              )
            "
          />
        </table-data>
        <table-data td-label="Result">
          {{ record.result }}
        </table-data>
        <table-data td-label="Asset">
          {{ record.asset_name }}
        </table-data>
        <table-data td-label="By">
          {{ record.user }}
        </table-data>
        <table-data td-label="Completed At">
          {{
            record.completed_at
              ? new Date(record.completed_at).toISOString().substring(0, 10)
              : ""
          }}
        </table-data>
        <table-data td-label="Type">
          {{ record.category }}
        </table-data>
        <table-data class="text-center">
          <button @click="navigateToSingleRecord(record)" :disabled="false">
            View
          </button>
        </table-data>
        <table-data class="text-center">
          <button @click="deleteRecord(record)" :disabled="false">
            Delete
          </button>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
    CheckboxInput,
  },
  data() {
    return {
      derivedRecords: [],
      selectedRecords: [],
      includeHistoricalCrops: false,
    };
  },
  computed: {
    records() {
      let recs = structuredClone(this.$store.state.records.records);

      // iterate through the records and add a asset_name property to each record
      // asset name should either pull from field or equipment based on which is not null

      recs.forEach((record) => {
        if (record.field) {
          record.asset_name = this.$store.getters.getFieldById(
            record.field,
          )?.properties.name;
        } else if (record.equipment) {
          record.asset_name = this.$store.getters.getEquipmentById(
            record.equipment,
          )?.alias;
        } else if (record.crop) {
          let crop = this.$store.getters.getAnyCropById(record.crop);
          if (crop && !crop.active && !this.includeHistoricalCrops) {
            // console.log("removing record with inactive crop", crop);
            record.id = null;
          }
          if (crop?.field) {
            record.asset_name = this.$store.getters.getFieldById(
              crop.field,
            )?.properties.name;
          } else {
            record.asset_name = "N/A";
          }
        } else {
          record.asset_name = "N/A";
        }
      });
      recs = recs.filter((record) => record.id);
      return recs;
    },
    extendedRecords() {
      return this.records.map((record) => {
        return {
          ...record,
          user: this.$store.getters.getUserById(record.completed_by).name,
        };
      });
    },
  },
  methods: {
    navigateToNewRecords() {
      this.$router.push({ name: "new-record" });
    },
    navigateToSingleRecord(record) {
      this.$router.push({
        name: "single-record",
        params: { recordId: record.id },
      });
    },
    deleteRecord(record) {
      this.$store.dispatch("deleteRecord", record);
    },
    async deleteSelectedRecords() {
      this.selectedRecords.forEach((record) => {
        this.$store.dispatch("deleteRecord", record, true);
      });
      await this.$store.dispatch("getRecords");
    },
  },
};
</script>
