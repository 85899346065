<template>
  <page-card v-if="userIsAdmin" headingTitle="KubotaNow Integration">
    <template #buttonArea>
      <base-button
        buttonText="Save"
        :marginLeft="false"
        @buttonClick="submitIntegration"
      />
      <base-button
        buttonText="Delete"
        :redButton="true"
        @buttonClick="deleteIntegration"
      />
    </template>
    <template #cardDetails>
      <variable-string-type-input
        :wideItem="true"
        :inputHtmlType="hideUsername ? 'password' : 'text'"
        inputTitle="KubotaNow Username"
        v-model="storedUsername"
        @focus="focusUsername"
        @blur="blurUsername"
      />
      <variable-string-type-input
        :wideItem="true"
        :inputHtmlType="hidePassword ? 'password' : 'text'"
        inputTitle="KubotaNow Password"
        v-model="storedPassword"
        @focus="focusPassword"
        @blur="blurPassword"
      />
      <variable-string-type-input
        :displayOnly="true"
        :wideItem="true"
        inputTitle="Latest Status"
        :displayOnlyValue="currentIntegration?.status"
      />
      <date-time-input
        :displayOnly="true"
        :wideItem="true"
        v-if="currentIntegration"
        inputTitle="Last Sync"
        :displayOnlyValue="currentIntegration.last_sync?.substring(0, 16)"
        :step="60"
      />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";

export default {
  //     let kubota = store.getters.getIntegrationByType("kubota");
  // let currentIntegration = null;
  // if (kubota) {
  //   currentIntegration = reactive(kubota);
  // }

  // let credentialsHaveChanged = ref(false);
  // let hideUsername = ref(false);
  // let hidePassword = ref(true);
  // let storedUsername;
  // let storedPassword;

  // if (currentIntegration) {
  //   hideUsername = true;
  //   storedUsername = ref("placeholder");
  //   storedPassword = ref("placeholder");
  // } else {
  //   storedUsername = ref("");
  //   storedPassword = ref("");
  // }

  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DateTimeInput,
  },
  data() {
    return {
      currentIntegration: this.$store.getters.getIntegrationByType("kubota"),
      credentialsHaveChanged: false,
      hideUsername: false,
      hidePassword: true,
      storedUsername: null,
      storedPassword: null,
    };
  },
  computed: {
    kubotaIntegration() {
      if (this.$store.getters.getIntegrationByType("kubota")) {
        return this.$store.getters.getIntegrationByType("kubota");
      } else {
        return {
          type: null, // string
          credentials: {
            username: null, // string
            password: null, //string
          },
        };
      }
    },
    userIsAdmin() {
      return this.$store.getters.getUserAdminStatus;
    },
  },
  methods: {
    // Integration Form Methods
    focusUsername() {
      if (this.storedUsername == "placeholder") {
        this.storedUsername = "";
      }
      this.hideUsername = false;
    },
    blurUsername() {
      if (this.storedUsername == "") {
        this.storedUsername = "placeholder";
        this.hideUsername = true;
      } else {
        this.credentialsHaveChanged = true;
      }
    },
    focusPassword() {
      if (this.storedPassword == "placeholder") {
        this.storedPassword = "";
      }
      this.hidePassword = false;
    },
    blurPassword() {
      console.log("test", this.currentIntegration);
      this.hidePassword = true;
      if (this.storedPassword == "") {
        this.storedPassword = "placeholder";
      } else {
        this.credentialsHaveChanged = true;
      }
    },
    submitIntegration() {
      if (this.credentialsHaveChanged) {
        if (this.currentIntegration == null) {
          //   console.log("creating integration");
          //   console.log(
          //     "uname",
          //     this.storedUsername,
          //     " pass",
          //     this.storedPassword
          //   );
          //   console.log(this.credentialsHaveChanged);

          this.$store.dispatch("createIntegration", {
            type: "kubota",
            credentials: {
              username: this.storedUsername,
              password: this.storedPassword,
            },
          });
        } else {
          //   console.log("updating integration");

          this.$store.dispatch("updateIntegration", {
            id: this.currentIntegration.id,
            credentials: {
              username: this.storedUsername,
              password: this.storedPassword,
            },
          });
        }
      }
    },
    deleteIntegration() {
      this.$store.dispatch("deleteIntegration", this.currentIntegration.id);
      this.currentIntegration = null;
      this.storedUsername = "";
      this.storedPassword = "";
    },
  },
};
</script>
