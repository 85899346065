import client from "@/api/parcel/api.js";
// import { universalGetter } from "@/components/composables/universalGetter";
import _ from "lodash";

export default {
  state() {
    return {
      controllerMarketAffiliates: [],
      filterObject: {},
    };
  },
  mutations: {
    SET_CONTROLLER_MARKET_AFFILIATES(state, controllerMarketAffiliateData) {
      state.controllerMarketAffiliates = controllerMarketAffiliateData;
    },
    ADD_CONTROLLER_MARKET_AFFILIATE(state, controllerMarketAffiliateObject) {
      state.controllerMarketAffiliates.push(controllerMarketAffiliateObject);
    },
    DELETE_CONTROLLER_MARKET_AFFILIATE(state, controllerMarketAffiliateObject) {
      state.controllerMarketAffiliates =
        state.controllerMarketAffiliates.filter(function (obj) {
          return obj.id !== controllerMarketAffiliateObject.id;
        });
    },
    RESET_CONTROLLER_MARKET_AFFILIATES(state) {
      state.controllerMarketAffiliates = [];
      state.filterObject = {};
    },
    SET_FILTER(state, filterObject) {
      state.filterObject = filterObject;
    },
  },
  actions: {
    async getControllerMarketAffiliates({ commit }) {
      const { data, errors } = await client.controller.marketAffiliates.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_CONTROLLER_MARKET_AFFILIATES", data.affiliates);
      }
    },
    async createControllerMarketAffiliate(
      { commit, dispatch },
      controllerMarketAffiliate,
    ) {
      const { data, errors } = await client.controller.marketAffiliates.create(
        null,
        controllerMarketAffiliate,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("ADD_CONTROLLER_MARKET_AFFILIATE", data);
        // update controllerMarketAffiliates list and crops list to ensure we have the latest elsewhere (like order allocations)
        this.dispatch("getControllerMarketAffiliates");
      }
    },
    async deleteControllerMarketAffiliate(
      { commit, dispatch },
      controllerMarketAffiliate,
    ) {
      const { data, errors } = await client.controller.marketAffiliates.delete(
        controllerMarketAffiliate.id,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        console.log(data);
        commit("DELETE_CONTROLLER_MARKET_AFFILIATE", controllerMarketAffiliate);
        this.dispatch("getControllerMarketAffiliates");
      }
    },
  },
  getters: {
    getControllerMarketAffiliateById:
      (state) => (controllerMarketAffiliateId) => {
        return state.controllerMarketAffiliates.find(
          (controllerMarketAffiliate) =>
            controllerMarketAffiliate.id ===
            parseInt(controllerMarketAffiliateId),
        );
      },
    getControllerMarketAffiliates: (state) => {
      return state.controllerMarketAffiliates;
    },
    getAcceptedControllerMarketAffiliates: (state) => () => {
      // return state.controllerMarketAffiliates where accepted is true
      return state.controllerMarketAffiliates.filter(
        (controllerMarketAffiliate) =>
          controllerMarketAffiliate.accepted === true,
      );
    },
    getControllerMarketAffiliateNameById: (state) => (id) => {
      let marketAffiliate = state.controllerMarketAffiliates.find(
        (marketAffiliate) => marketAffiliate.affiliate_details.id === id,
      );
      return marketAffiliate
        ? marketAffiliate.affiliate_details.name
        : "No affiliate name found";
    },
    // UNIVERSAL GETTER
    // getControllerMarketAffiliates:
    //   (state) =>
    //   ({
    //     filterFunction = null,
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let derivedControllerMarketAffiliates = universalGetter(
    //       {
    //         filterFunction: filterFunction,
    //         filter: filter,
    //         match: match,
    //         sort: sort,
    //         pageSize: pageSize,
    //         pageNum: pageNum,
    //       },
    //       state.controllerMarketAffiliates
    //     );
    //     return derivedControllerMarketAffiliates;
    //   },
    getDerivedControllerMarketAffiliates:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = {}, // {"acres":'asc',"id":'desc'} - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedControllerMarketAffiliates =
          state.controllerMarketAffiliates.map((item) => ({
            ...item,
          }));
        let filteredControllerMarketAffiliates = _.isEmpty(filter)
          ? expandedControllerMarketAffiliates
          : _.filter(expandedControllerMarketAffiliates, filter);
        let matchedControllerMarketAffiliates = _.isEmpty(match)
          ? filteredControllerMarketAffiliates
          : _.filter(filteredControllerMarketAffiliates, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedControllerMarketAffiliates = _.isEmpty(sort)
          ? matchedControllerMarketAffiliates
          : _.orderBy(
              matchedControllerMarketAffiliates,
              sortColumns,
              sortOrders,
            );
        let paginatedControllerMarketAffiliates =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedControllerMarketAffiliates,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedControllerMarketAffiliates;
        return paginatedControllerMarketAffiliates;
      },
    getControllerMarketAffiliatesFilter(state) {
      if (state.filterObject == null) {
        return {};
      }
      return state.filterObject;
    },
  },
};
