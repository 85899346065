<template>
  <slot-modal
    :open="showRequestModal"
    modalTitle="Request Product"
    modalDescription="Submit your request to verified product vendors. If the product is available, you will get a quote for the desired quantity/packaging."
    @closeModal="closeRequestModal"
  >
    <template #icon
      ><PaperAirplaneIcon class="text-black-600 h-6 w-6" aria-hidden="true"
    /></template>
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <text-input
          :wideItem="true"
          inputTitle="Package Size"
          inputHtmlType="text"
          v-model="modalPackage"
        />
        <number-input
          :wideItem="true"
          inputTitle="Quantity"
          inputHtmlType="text"
          v-model="modalQty"
          :step="1"
        />
      </div>
    </template>
    <template #buttons
      ><button
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
        @click="addRequestToCart"
      >
        Submit
      </button></template
    >
  </slot-modal>
  <div class="bg-gray-50">
    <div>
      <search-container>
        <template #navigation>
          <search-navigation current-navigation="inputs" />
        </template>
        <template v-slot:categories>
          <div class="flex">
            <filter-menu
              :filters="filterOptions"
              @update:filters="updateFilters"
            />
          </div>
        </template>
        <template v-slot:input>
          <search-input v-model="search" @handleChange="handleSearchValue" />
        </template>
      </search-container>
      <!-- <div
        class="mt-4 pl-4 text-lg font-bold text-blue-700"
        v-if="activeProducts?.length > 0"
      >
        Inputs
      </div> -->
      <search-grid>
        <template #gridItems>
          <product-search-grid-card
            v-for="product in activeProducts"
            :key="product.id"
            :product="product"
            @gridItemClicked="navigateToProduct"
          />

          <request-search-grid-card
            v-for="product in inputRequestSearchResults"
            :key="product.id"
            :product="product"
            @requestButtonClicked="openRequestModal"
          />
        </template>
      </search-grid>
    </div>
  </div>
</template>

<script>
// import Fuse from "fuse.js";
import { FilterProducts } from "@/layouts/grower-portal/discover/search/FilterComposable.js";
import SearchContainer from "@/layouts/grower-portal/discover/search/SearchContainer.vue";
import SearchInput from "@/layouts/grower-portal/discover/search/SearchInput.vue";
import SearchGrid from "@/layouts/grower-portal/discover/search/SearchGrid.vue";
import SearchNavigation from "@/layouts/grower-portal/discover/search/SearchNavigation.vue";
import ProductSearchGridCard from "@/layouts/grower-portal/discover/search/ProductSearchGridCard.vue";
import RequestSearchGridCard from "@/layouts/grower-portal/discover/search/RequestSearchGridCard.vue";
import FilterMenu from "@/components/dropdowns/FilterMenu.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import { PaperAirplaneIcon } from "@heroicons/vue/outline";
import { useToast } from "vue-toastification";
import api from "@/api/parcel/api";
import { debounce } from "@/components/composables/scriptUtils.js";

export default {
  components: {
    SearchContainer,
    SearchInput,
    SearchGrid,
    SearchNavigation,
    ProductSearchGridCard,
    RequestSearchGridCard,
    FilterMenu,
    SlotModal,
    TextInput,
    NumberInput,
    PaperAirplaneIcon,
  },
  data() {
    // Declare filters here, then fill them in beforeMount
    let filterOptions = {};
    filterOptions.type = {
      id: "type",
      name: "Type",
      filterColumn: "type",
      active: true,
      options: {},
    };

    filterOptions.active_ingredients = {
      id: "active_ingredients",
      name: "Active Ingredient",
      filterColumn: "active_ingredients",
      active: true,
      options: {},
    };
    filterOptions.manufacturer = {
      id: "manufacturer",
      name: "Manufacturer",
      filterColumn: "manufacturer",
      active: true,
      options: {},
    };

    return {
      search: this.$route.query.search ? this.$route.query.search : "",
      filterOptions: filterOptions,
      showCompanies: true,
      inputRequestSearchResults: [],
      toast: useToast(),
      modalQty: 0,
      modalPackage: "",
      showRequestModal: false,
      focusedProduct: null,
    };
  },
  beforeMount() {
    // computed properties are available now, so we can fill in the filters
    this.addActiveIngredients();
    this.addInputTypes();
    this.addManufacturers();
    this.setFilterFromUrl();
  },
  computed: {
    products() {
      let input_products = this.$store.state.growerInputListings["listings"];
      for (let input_product of input_products) {
        input_product["searchString"] =
          input_product.active_ingredients +
          " " +
          input_product.pests +
          " " +
          input_product.states +
          " " +
          input_product.crops +
          " " +
          input_product.epa_number +
          " " +
          input_product.type;
        " " + input_product.manufacturer;
      }
      let allProducts = input_products.sort((a, b) => {
        return a.title.localeCompare(b.title); //TODO: Sort by relevance or number of orders
      });
      return allProducts;
    },
    activeProducts() {
      let activeProducts = FilterProducts(
        this.products,
        this.search,
        this.filterOptions,
      );
      return activeProducts;
    },
  },
  methods: {
    openRequestModal(product) {
      this.showRequestModal = true;
      this.focusedProduct = product;
    },
    closeRequestModal() {
      this.showRequestModal = false;
      this.focusedProduct = null;
    },
    async handleSearchValue(value) {
      console.log("VALUE", value);
      const { data } = await api.grower.input_requests.searchRequests(value);
      console.log("search results", data);
      if (data) this.inputRequestSearchResults = data.input_requests;
    },
    async addRequestToCart() {
      const product = this.focusedProduct;
      console.log("Requested product", product);
      await this.$store.dispatch("addCartItem", {
        product: product.id,
        sku: { id: product.id },
        qty: this.modalQty,
        package: this.modalPackage,
        order: null,
        productType: "request",
        productDetails: product,
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Added to Cart!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.closeRequestModal();
    },
    navigateToProduct(product) {
      this.$router.push({
        name: "grower-portal-discover-single-input",
        params: { productId: product.id },
      });
    },
    addActiveIngredients() {
      let activeIngredients = {};
      for (let product of this.products) {
        if (product.active_ingredients) {
          let ingredients = product.active_ingredients.split(";");
          for (let ingredient of ingredients) {
            if (!activeIngredients[ingredient]) {
              activeIngredients[ingredient] = {
                value: ingredient,
                label: ingredient,
                checked: false,
              };
            }
          }
        }
      }
      this.filterOptions.active_ingredients.options = activeIngredients;
    },
    addInputTypes() {
      let inputTypes = {};
      for (let product of this.products) {
        if (product.type) {
          let types = product.type.split(";");
          for (let type of types) {
            if (!inputTypes[type]) {
              inputTypes[type] = {
                value: type,
                label: type,
                checked: false,
              };
            }
          }
        }
      }
      this.filterOptions.type.options = inputTypes;
    },
    addManufacturers() {
      let manufacturers = {};
      for (let product of this.products) {
        if (product.manufacturer) {
          let manufacturer = product.manufacturer;
          if (!manufacturers[manufacturer]) {
            manufacturers[manufacturer] = {
              value: manufacturer,
              label: manufacturer,
              checked: false,
            };
          }
        }
      }
      this.filterOptions.manufacturer.options = manufacturers;
    },
    setFilterFromUrl() {
      // Now check to see if any filters were defined in the URL
      let filters = this.$route.query.filter ? this.$route.query.filter : null;
      if (filters) {
        if (typeof filters === "string") {
          // single filter is a string
          filters = [filters];
        }
        // format is filter.option.checked
        for (let filter of filters) {
          filter = filter.split(":");
          let checked = filter[1] === "true" ? true : false;
          let filterString = filter[0].split(".");
          let filterType = filterString[0];
          let option = filterString[1];
          if (
            filterType &&
            option &&
            this.filterOptions[filterType]?.options[option]
          ) {
            this.filterOptions[filterType].active = true;
            this.filterOptions[filterType].options[option].checked = checked;
          }
        }
      }
    },
    updateFilters(filters) {
      this.filterOptions = filters;
      // enable any filters that are avaialble by other filters
      // if (
      //   this.filterOptions.category.options?.input?.checked &&
      //   this.filterOptions.type.active !== true
      // ) {
      //   console.log("add crop protection filters");
      //   this.filterOptions.type.active = true;
      //   this.addActiveIngredients();
      //   this.filterOptions.ingredient.active = true;
      // }
      // // disable any filters if the category filter becomes unchecked
      // if (
      //   this.filterOptions.category.options?.input?.checked != true &&
      //   this.filterOptions.type.active == true
      // ) {
      //   console.log("disable crop protection filters");
      //   this.filterOptions.type.active = false;
      //   this.filterOptions.ingredient.active = false;
      // }
    },
  },
  created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      console.log("value changed: ", newValue, oldValue);
      // call fetch API to get results
      const { data } = await api.grower.input_requests.searchRequests(newValue);
      console.log("search results", data);
      if (data) this.inputRequestSearchResults = data.input_requests;
    }, 500);
  },
  watch: {
    search(...args) {
      this.debouncedFetch(...args);
    },
  },
};
</script>
