<template>
  <form
    @submit.prevent="console.log('task object form attempted to submit')"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <select-dropdown
      :wideItem="true"
      inputTitle="Type"
      v-model="currentRecommendation.type"
      :selectOptions="recommendationTypes"
      :noSort="true"
      :displayOnly="readOnly"
      :displayOnlyValue="currentRecommendation.type"
    />
    <select-dropdown
      :wideItem="true"
      inputTitle="Category"
      v-model="currentRecommendation.category"
      :selectOptions="taskTypes"
      :noSort="true"
      :displayOnly="readOnly"
      :displayOnlyValue="currentRecommendation.category"
    />
    <!-- Multiple assets on order -->
    <div class="col-span-7 grid grid-cols-1 md:grid-cols-7" v-if="!readOnly">
      <!-- These two selects should only be shown on the order form when a grower is filling it out. The order page / layout will use Shared Assets to display info when readonly. -->
      <!-- Add a map viewer when selecting fields / equipment to ensure the grower is selecting the correct ones. -->
      <list-select-2
        class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
        inputTitle="Select Fields"
        :selectOptions="fields"
        select-options-label="properties.name"
        :rows="5"
        v-if="
          currentRecommendation.type === 'field' &&
          !readOnly &&
          fields.length > 0
        "
        @update-selections="currentRecommendation.shared_assets.fields = $event"
      />
      <list-select-2
        class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
        inputTitle="Select Equipment"
        :selectOptions="equipment"
        select-options-label="alias"
        :rows="5"
        v-if="
          currentRecommendation.type === 'equipment' &&
          !readOnly &&
          equipment.length > 0
        "
        @update-selections="
          currentRecommendation.shared_assets.equipment = $event
        "
      />
    </div>
    <div class="sm:col-span-6">
      <text-area
        inputTitle="Instructions"
        v-model="currentRecommendation.instructions"
        :displayOnly="readOnly"
        :displayOnlyValue="currentRecommendation.instructions"
        dataTestid="instructions"
      />
    </div>
    <div class="sm:col-span-3">
      <!-- <date-input
        class="col-span-2 ml-1 md:col-start-7"
        inputTitle="Deadline"
        v-model="recDate"
        :displayOnly="readOnly"
        :displayOnlyValue="currentRecommendation.deadline"
      /> -->
      <date-time-input
        inputTitle="Deadline"
        v-model="currentRecommendation.deadline"
        :displayOnly="readOnly"
        :displayOnlyValue="currentRecommendation.deadline?.substring(0, 16)"
      />
    </div>
  </form>
</template>

<script>
import TextArea from "@/components/form/TextArea.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import ListSelect2 from "@/components/form/ListSelect2.vue";

import { useToast } from "vue-toastification";

export default {
  components: {
    TextArea,
    SelectDropdown,
    DateTimeInput,
    ListSelect2,
  },
  emits: ["updateRecommendation"],
  props: {
    activeAgreementId: {
      type: Number,
      default: null,
    },
    // recommendation is if you have a recommendation to load in
    recommendation: {
      type: Object,
      default: null,
    },
    // fields is an array of fields that you will be allowed to select if asset_sharing fields is on
    fields: {
      type: Array,
      default: null,
    },
    // equipment is an array of equipment that you will be allowed to select if asset_sharing equipment is on
    equipment: {
      type: Array,
      default: null,
    },
    locations: {
      type: Array,
      default: null,
    },
    // readOnly - determines if all the fields should accept input or not
    readOnly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const toast = useToast();
    let taskTypes = [
      "Planting",
      "Tilling",
      "Irrigation",
      "Spraying",
      "Manual / Hand labor",
      "Hauling",
      "Scouting",
      "Observation",
      "Other field task",
      "Harvesting",
      // equipment tasks
      "Maintenance",
      "Repair",
      "Other equipment task",
    ];
    return {
      currentRecommendation: this.recommendation
        ? this.recommendation
        : {
            agreement: this.activeAgreementId,
            batch_id: null,
            shared_assets: {
              fields: [],
              equipment: [],
              locations: [],
            },
            type: "field",
            category: "", // String
            instructions: "", // String
            deadline: null, // date-time
            details: {}, // Object
          },
      recDate: null,
      toast,
      taskTypes,
    };
  },
  computed: {
    recommendationTypes() {
      if (this.fields && this.equipment) {
        return [
          { label: "Field", value: "field" },
          { label: "Equipment", value: "equipment" },
        ];
      } else if (this.fields) {
        return [{ label: "Field", value: "field" }];
      } else if (this.equipment) {
        return [{ label: "Equipment", value: "equipment" }];
      } else {
        return [];
      }
    },
  },
  methods: {
    updateRecommendationDetails(e) {
      this.currentRecommendation.details = e;
    },
    resetRecObject() {
      this.currentRecommendation = {
        agreement: this.activeAgreementId,
        batch_id: null,
        shared_assets: {},
        type: "field",
        category: "", // String
        instructions: "", // String
        deadline: null, // date-time
        details: {}, // Object
      };
    },
  },
  watch: {
    activeAgreementId() {
      this.resetRecObject();
    },
    currentRecommendation: {
      handler(value) {
        this.$emit("updateRecommendation", value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
