import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      records: [],
    };
  },
  mutations: {
    SET_SHARED_RECORDS(state, recordData) {
      state.records = recordData;
    },
    ADD_SHARED_RECORD(state, recordObject) {
      state.records.push(recordObject);
    },
    UPDATE_SHARED_RECORD(state, recordObject) {
      state.records = state.records.filter(function (obj) {
        return obj.id !== recordObject.id;
      });
      state.records.push(recordObject);
    },
    DELETE_SHARED_RECORD(state, recordObject) {
      state.records = state.records.filter(function (obj) {
        return obj.id !== recordObject.id;
      });
    },
    RESET_SHARED_RECORDS(state) {
      state.records = [];
    },
  },
  actions: {
    async getSharedRecords({ commit, rootState }) {
      let recordsList = [];
      for (let customer of rootState.customers.customers) {
        if (
          customer.seller_confirmed === true &&
          customer.buyer_confirmed === true
        ) {
          const { data, errors } = await client.records.read(null, {
            org: customer.id,
          });
          if (errors) {
            console.log(errors);
          } else {
            // add each set of records to the collection.
            recordsList = recordsList.concat(data.records);
          }
        }
      }
      commit("SET_SHARED_RECORDS", recordsList);
    },
    async createSharedRecord({ commit, dispatch }, record) {
      const { data, errors } = await client.records.create(null, record);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("ADD_SHARED_RECORD", data);
        this.dispatch("getSharedRecords");
      }
    },
    // Object payload
    async updateSharedRecord({ commit, dispatch }, { recordId, record }) {
      // console.log("RECORD IN STORE", recordId, record);
      const { data, errors } = await client.records.update(recordId, record);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_SHARED_RECORD", data);
        this.dispatch("getSharedRecords");
      }
    },
    async deleteSharedRecord({ commit, dispatch }, record) {
      const { data, errors } = await client.records.delete(record.id);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        // console.log(data);
        commit("DELETE_SHARED_RECORD", record);
        this.dispatch("getSharedRecords");
      }
    },
  },
  getters: {
    getSharedRecordById: (state) => (recordId) => {
      return state.records.find((record) => record.id === parseInt(recordId));
    },
    getSharedRecordsByField: (state) => (fieldId) => {
      // console.log("getSharedRecordsByField", fieldId, state);
      return state.records.filter((record) => record.field === fieldId);
    },
    getSharedRecordsByCrop: (state) => (cropId) => {
      return state.records.filter((record) => record.crop === cropId);
    },
    getSharedRecordsByTask: (state) => (taskId) => {
      return state.records.filter((record) => record.task === taskId);
    },
    getSharedRecordsByEquipment: (state) => (equipmentId) => {
      return state.records.filter((record) => record.equipment === equipmentId);
    },
    getDerivedSharedRecords:
      (state, getters) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        // add the user and source user objects to the record so we can search / filter on them.
        let expandedSharedRecords = state.records.map((record) => {
          let user = getters.getUserById(record.completed_by) || {};
          let field = getters.getFieldById(record.field) || {};
          let equipment = getters.getEquipmentById(record.equipment) || {};

          let converted_user = Object.entries(user).reduce(
            (obj, [key, val]) => {
              obj[`user.${key}`] = val;
              return obj;
            },
            {},
          );

          let converted_field = Object.entries(field?.properties || {}).reduce(
            (obj, [key, val]) => {
              obj[`field.${key}`] = val;
              return obj;
            },
            {},
          );

          let converted_equipment = Object.entries(equipment).reduce(
            (obj, [key, val]) => {
              obj[`equipment.${key}`] = val;
              return obj;
            },
            {},
          );

          return {
            fieldOrEquipment: field?.properties?.name || equipment?.alias,
            ...record,
            user,
            ...converted_user,
            field,
            ...converted_field,
            equipment,
            ...converted_equipment,
          };
        });

        // This section checks if a filter or set of filters exists. If it does, it loops through and checks if any have nested objects to filter,
        // like "user.name". If it does, it substitutes the "user.name:value" key for an actual object {user{name:value}}
        // NOTE: only currently used in records, if it becomes generally needed, we can move to TableHeaderCellWithFilterButton to make it take effect
        // globally.
        if (
          filter &&
          Object.keys(filter).length > 0 &&
          Object.getPrototypeOf(filter) === Object.prototype
        ) {
          //console.log("break up", filter);
          let newFilter = {};
          // loop through each key in the filter to see if we need to parse it.
          for (let key in filter) {
            //console.log("filterClause", `${key}:${filter[key]}`);
            if (key && key.split(".").length > 1) {
              var schema = newFilter; // a moving reference to internal objects within obj
              var pList = key.split(".");
              var len = pList.length;

              for (var i = 0; i < len - 1; i++) {
                var elem = pList[i];
                if (!schema[elem]) schema[elem] = {};
                schema = schema[elem];
              }
              schema[pList[len - 1]] = filter[key];
              //console.log("cleaned",newFilter);
            } else {
              // Not one we need to break up, just copy it to our new object.
              newFilter[key] = filter[key];
            }
          }
          filter = newFilter;
        }
        //console.log("filter", filter);

        let filteredSharedRecords = _.isEmpty(filter)
          ? expandedSharedRecords
          : _.filter(expandedSharedRecords, filter);
        let matchedSharedRecords = _.isEmpty(match)
          ? filteredSharedRecords
          : _.filter(filteredSharedRecords, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedSharedRecords = _.isEmpty(sort)
          ? matchedSharedRecords
          : _.orderBy(matchedSharedRecords, sortColumns, sortOrders);
        let paginatedSharedRecords =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedSharedRecords,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedSharedRecords;
        return paginatedSharedRecords;
      },
  },
};
