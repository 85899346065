<template>
  <styled-input
    type="tel"
    :inputTitle="inputTitle"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :placeholder="placeholder"
    v-model="inputValue"
    @blur="checkValidInput"
    :isValid="isValid"
    :classProp="classProp"
    :helpText="helpText"
    :helpTitle="helpTitle"
  />
</template>

<script>
import {
  // isPossiblePhoneNumber,
  parsePhoneNumber,
  isValidPhoneNumber,
  // validatePhoneNumberLength
} from "libphonenumber-js";

import StyledInput from "./StyledInput.vue";
export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    required: { type: Boolean, default: false },
    modelValue: { type: String },
    wideItem: { type: Boolean, default: false },
    displayOnly: { type: Boolean, default: false },
    classProp: { type: String, default: null },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["update:modelValue", "valid"],
  data() {
    let formattedPhone = null;

    if (this.modelValue && isValidPhoneNumber(this.modelValue, "US")) {
      this.isValid = true;
      let phoneNumber = parsePhoneNumber(this.modelValue, "US");
      formattedPhone = phoneNumber.formatNational();
      //console.log("phone type",phoneNumber.getType());
    }

    return {
      inputValue: formattedPhone,
      isValid: true,
    };
  },
  methods: {
    checkValidInput() {
      // console.log("phone check");
      if (isValidPhoneNumber(this.inputValue, "US")) {
        this.isValid = true;
        let phoneNumber = parsePhoneNumber(this.inputValue, "US");
        this.inputValue = phoneNumber.formatNational();
        console.log("phone", phoneNumber, this.inputValue);
        //console.log("phone type",phoneNumber.getType());
      } else if (this.inputValue.length == 0) this.isValid = true;
      // empty is valid
      else this.isValid = false;
    },
  },
  watch: {
    inputValue(value) {
      if (value?.length == 0) {
        this.$emit("update:modelValue", "");
      } else if (isValidPhoneNumber(value, "US")) {
        let phoneNumber = parsePhoneNumber(value, "US");
        this.$emit("update:modelValue", phoneNumber.number);
      }
    },
    modelValue(value) {
      this.isValid = false;
      if (!value) {
        // if value is null, make sure its an empty string so we don't barf on the null
        value = "";
        this.inputValue = "";
        this.isValid = true;
      }
      if (isValidPhoneNumber(value, "US")) {
        this.isValid = true;
        let phoneNumber = parsePhoneNumber(value, "US");
        this.inputValue = phoneNumber.formatNational();
      }
    },
    displayOnly() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
    isValid() {
      this.$emit("valid", this.isValid);
    },
  },
};
</script>
