import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      growerListings: [],
    };
  },
  mutations: {
    SET_GROWER_CROP_LISTINGS(state, listingObjects) {
      state.growerListings = listingObjects;
    },
    ADD_GROWER_CROP_LISTING(state, listingObject) {
      state.growerListings.push(listingObject);
    },
    UPDATE_GROWER_CROP_LISTING(state, listingObject) {
      state.growerListings = state.growerListings.filter(function (obj) {
        return obj.id !== listingObject.id;
      });
      state.growerListings.push(listingObject);
    },
    DELETE_GROWER_CROP_LISTING(state, listingObject) {
      state.growerListings = state.growerListings.filter(function (obj) {
        return obj.id !== listingObject.id;
      });
    },
    RESET_GROWER_CROP_LISTINGS(state) {
      state.growerListings = [];
    },
  },
  actions: {
    async getGrowerListings({ commit }) {
      const { data, errors } = await client.grower.cropListings.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_GROWER_CROP_LISTINGS", data.listings);
      }
    },
    async createGrowerListing({ commit, dispatch }, listing) {
      const { data, errors } = await client.grower.cropListings.create(
        null,
        listing,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
        return null;
      } else {
        // console.log(data);
        commit("ADD_GROWER_CROP_LISTING", data);
        this.dispatch("getGrowerListings");
        return data.id;
      }
    },
    async updateGrowerListing({ commit, dispatch }, listing) {
      const { data, errors } = await client.grower.cropListings.update(
        listing.id,
        listing,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_GROWER_CROP_LISTING", data);
        this.dispatch("getGrowerListings");
      }
    },
    async deleteGrowerListing({ commit, dispatch }, listing) {
      const { data, errors } = await client.grower.cropListings.delete(
        listing.id,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        //console.log(data);
        commit("DELETE_GROWER_CROP_LISTING", listing);
        this.dispatch("getGrowerListings");
      }
    },
  },
  getters: {
    getGrowerListingById: (state) => (listingId) => {
      return state.growerListings.find((l) => l.id === parseInt(listingId));
    },

    getDerivedGrowerListings:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedGrowerListings = state.growerListings;
        let filteredGrowerListings = _.isEmpty(filter)
          ? expandedGrowerListings
          : _.filter(expandedGrowerListings, filter);
        let matchedGrowerListings = _.isEmpty(match)
          ? filteredGrowerListings
          : _.filter(filteredGrowerListings, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedGrowerListings = _.isEmpty(sort)
          ? matchedGrowerListings
          : _.orderBy(matchedGrowerListings, sortColumns, sortOrders);
        let paginatedGrowerListings =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerListings,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerListings;
        return paginatedGrowerListings;
      },
  },
};
