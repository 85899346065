import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";

export default {
  state() {
    return {
      sharedScenarios: [],
      summary: {},
      page_size: 500,
      page_number: 0,
    };
  },
  mutations: {
    SET_SHARED_SCENARIOS(state, scenarioData) {
      state.sharedScenarios = scenarioData.scenarios;
      state.summary = scenarioData.summary;
      state.page_size = scenarioData.page_size;
      state.page_number = scenarioData.page_number;
    },
    ADD_SHARED_SCENARIO(state, scenarioObject) {
      state.sharedScenarios.push(scenarioObject);
    },
    // UPDATE_SHARED_SCENARIO(state, scenarioObject) {
    //   state.sharedScenarios = state.sharedScenarios.filter(function (obj) {
    //     return obj.id !== scenarioObject.id;
    //   });
    //   state.sharedScenarios.push(scenarioObject);
    // },
    // DELETE_SHARED_SCENARIO(state, scenarioObject) {
    //   state.sharedScenarios = state.sharedScenarios.filter(function (obj) {
    //     return obj.id !== scenarioObject.id;
    //   });
    // },
    RESET_SHARED_SCENARIOS(state) {
      state.sharedScenarios = [];
    },
  },
  actions: {
    async getSharedScenarios({ commit }) {
      const { data, errors } = await client.shared.scenarios.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_SHARED_SCENARIOS", data);
      }
    },
    async createSharedScenario({ commit, dispatch }, scenario) {
      const { data, errors } = await client.shared.scenarios.create(
        null,
        scenario,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        console.log(data);
        commit("ADD_SHARED_SCENARIO", data);
        this.dispatch("getSharedScenarios");
        return data;
      }
    },
    // async updateSharedScenario({ commit, dispatch }, scenario) {
    //   const { data, errors } = await client.shared.scenarios.update(
    //     scenario.id,
    //     scenario
    //   );
    //   if (errors) {
    //     console.log(errors);
    //     dispatch("catchParcelApiError", errors);
    //   } else {
    //     // console.log(data);
    //     commit("UPDATE_SHARED_SCENARIO", data);
    //     this.dispatch("getSharedScenarios");
    //   }
    // },
    // async deleteSharedScenario({ commit, dispatch }, scenario) {
    //   const { data, errors } = await client.shared.scenarios.delete(
    //     scenario.id
    //   );
    //   if (errors) {
    //     console.log(errors);
    //     dispatch("catchParcelApiError", errors, data);
    //   } else {
    //     // console.log(data);
    //     commit("DELETE_SHARED_SCENARIO", scenario);
    //     this.dispatch("getSharedScenarios");
    //   }
    // },
  },
  getters: {
    getSharedScenarioById: (state) => (scenarioId) => {
      return state.sharedScenarios.find(
        (scenario) => scenario.id === parseInt(scenarioId),
      );
    },
    getSharedScenarios:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let sharedScenarios = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.sharedScenarios,
        );
        return sharedScenarios;
      },
    getEnrichedVendorSharedScenarios:
      (state, rootGetters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        // console.log(agreements)
        let agreements = rootGetters.getAllVendorQuotes;
        let initalScenarios = state.sharedScenarios;
        initalScenarios.forEach((scenario) => {
          // for each org in shared_orgs, add the shared_orgs_names array
          scenario.shared_org_name = "";
          let agreement = agreements.find(
            (agreement) =>
              agreement.buyer_details.id === parseInt(scenario.org),
          );
          scenario.shared_org_name = agreement?.buyer_details.name;
        });
        let enrichedScenarios = initalScenarios.map((scenario) => ({
          ...scenario,
          // add additional enriched attributes here
        }));
        // for scenario in enrichedScenarios delete scenario.agreement_object
        let readyScenarios = enrichedScenarios.map((scenario) => {
          // remove unneeded attributes here
          return scenario;
        });
        let finalEnrichedSharedScenarios = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          readyScenarios,
        );
        return finalEnrichedSharedScenarios;
      },
  },
};
