import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      crops: [],
    };
  },
  mutations: {
    SET_SHARED_CROPS(state, cropData) {
      state.crops = cropData;
    },
    // ADD_SHARED_CROP(state, cropObject) {
    //   state.crops.push(cropObject);
    // },
    UPDATE_SHARED_CROP(state, cropObject) {
      state.crops = state.crops.filter(function (obj) {
        return obj.id !== cropObject.id;
      });
      state.crops.push(cropObject);
    },
    DELETE_SHARED_CROP(state, cropObject) {
      state.crops = state.crops.filter(function (obj) {
        return obj.id !== cropObject.id;
      });
    },
    RESET_SHARED_CROPS(state) {
      state.crops = [];
    },
  },
  actions: {
    async getSharedCrops({ commit, rootState }) {
      let cropsList = [];
      for (let customer of rootState.customers.customers) {
        if (
          customer.seller_confirmed === true &&
          customer.buyer_confirmed === true
        ) {
          const { data, errors } = await client.crops.read(null, {
            org: customer.id,
          });
          if (errors) {
            console.log(errors);
          } else {
            // add each set of equipment to the collection.
            cropsList = cropsList.concat(data.equipment);
          }
        }
      }
      commit("SET_SHARED_CROPS", cropsList);

      // const { data, errors } = await client.crops.read();
      // if (errors) {
      //   console.log(errors);
      // } else {
      //   commit("SET_SHARED_CROPS", data.crops);
      // }
    },
    // async createSharedCrop({ commit, dispatch, rootState }, crop) {
    //   await dispatch("refreshUserToken");
    //   const client = API(() => rootState.magicAuth.refreshToken);
    //   const { data, errors } = await client.crops.create(null, crop);
    //   if (errors) {
    //     console.log(errors);
    //     dispatch("catchParcelApiError", errors)
    //   } else {
    //     console.log(data);
    //     commit("ADD_SHARED_CROP", data);
    //     this.dispatch("getSharedCrops");
    //   }
    // },
    async updateSharedCrop({ commit, dispatch }, crop) {
      const { data, errors } = await client.crops.update(crop.id, crop);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_SHARED_CROP", data);
        this.dispatch("getSharedCrops");
      }
    },
    async deleteSharedCrop({ commit, dispatch }, crop) {
      const { data, errors } = await client.crops.delete(crop.id);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        // console.log(data);
        commit("DELETE_SHARED_CROP", crop);
        this.dispatch("getSharedCrops");
      }
    },
  },
  getters: {
    getSharedCropById: (state) => (cropId) => {
      return state.crops.find((crop) => crop.id === parseInt(cropId));
    },
    getSharedCropByType: (state) => (cropType) => {
      return state.crops.find((crop) => crop.crop_type === cropType);
    },
    getNonAllocatedSharedCropAcres: (state) => (cropId) => {
      // get sum of all allocation acres attached to this cropID
      // sumtract the sum from this crop's acres
      // return the value
      return state.growerAllocations.filter(
        (allocation) => allocation.org === parseInt(cropId),
      );
    },
    getDerivedSharedCrops:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = [], // ["updated", "id"] - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedSharedCrops = state.crops.map((item) => ({
          ...item,
          ...item.properties,
          ...item.properties.details,
        }));
        let filteredSharedCrops = _.isEmpty(filter)
          ? expandedSharedCrops
          : _.filter(expandedSharedCrops, filter);
        let matchedSharedCrops = _.isEmpty(match)
          ? filteredSharedCrops
          : _.filter(filteredSharedCrops, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedSharedCrops = _.isEmpty(sort)
          ? matchedSharedCrops
          : _.orderBy(matchedSharedCrops, sortColumns, sortOrders);
        let paginatedSharedCrops =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedSharedCrops,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedSharedCrops;
        return paginatedSharedCrops;
      },
  },
};
