import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      vendorLocations: [],
    };
  },
  mutations: {
    SET_BOUNDARY_BOX(state, boundsData) {
      if (boundsData) {
        state.bounds = [
          [boundsData[1], boundsData[0]],
          [boundsData[3], boundsData[2]],
        ];
      } else {
        state.bounds = [];
      }
    },
    ADD_VENDOR_SERVICE_LOCATIONS(state, vendorLocationsArray) {
      vendorLocationsArray.forEach((partnerLocationObject) => {
        state.vendorLocations.push(partnerLocationObject);
      });
    },
    RESET_VENDOR_SERVICE_LOCATIONS(state) {
      state.vendorLocations = [];
      state.bounds = [];
    },
  },
  actions: {
    async getVendorLocationsByField({ commit }, id) {
      const { data, errors } = await client.vendor.serviceLocations.read(id);
      if (errors) {
        if (errors.response.status != 404)
          console.log(
            "get location error",
            errors,
            errors.response,
            errors.response.status,
          ); // skip 404 errors, that's just no locations on a field
      } else {
        commit("ADD_VENDOR_SERVICE_LOCATIONS", data.locations);
      }
    },
  },
  getters: {
    getVendorLocationsByField: (state) => (partnerLocationId) => {
      return state.vendorLocations.find(
        (partnerLocation) => partnerLocation.id === parseInt(partnerLocationId),
      );
    },
    getVendorLocationsByFieldId: (state) => (fieldId) => {
      return state.vendorLocations.filter(
        (partnerLocation) => partnerLocation.field === parseInt(fieldId),
      );
    },
    getVendorLocationByName: (state) => (partnerLocationName) => {
      return state.vendorLocations.find(
        (partnerLocation) =>
          partnerLocation.properties.name === partnerLocationName,
      );
    },
  },
};
